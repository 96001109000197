import { Box, Card, Typography, Grid, IconButton, TextField } from "@mui/material";
import React from "react";
import { availabilityModalStyles as styles } from "./availability.styles";
import { AutoSizer, MultiGrid, ScrollSync, Size } from "react-virtualized";
import { AVAILABILITY } from "@/shared/constantes/Availability";
import { UseStateAvailabilityModal } from "./state/useStateAvailabilityModal";
import availabilityHeader from "@/shared/data/availability_header.json";
import { CloseModalIcon } from "@/shared/icons/Icons";

export interface IAvailabilityModalProps {
    item: any;
    onClose: (value: boolean) => void;
}

const AvailabilityModal = ({ item, onClose } : IAvailabilityModalProps) => {
    const {
        filteredAvailability,
        adjustedRowCount,
        searchAvailability,
        setSearchAvailability,
        getRowData
    } = UseStateAvailabilityModal();

    const cellRenderer = ({ columnIndex, key, rowIndex, style, rowData }: { columnIndex: number; key: string; rowIndex: number; style: React.CSSProperties; rowData: any }) => {

        const column = availabilityHeader[columnIndex];
        const content = filteredAvailability[rowIndex - 1][column.field];

        return (
            <div
                key={key}
                style={{
                    ...style,
                    border: '1px solid #ddd',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '12px',
                    fontFamily: 'Roboto, sans-serif',
                    backgroundColor: rowData.suggested === 1 ? '#F4F2EF' : '',
                }}
            >
                {content}
            </div>
        );
    };

    const headerRender = (columnIndex: number, key: string, style: React.CSSProperties) => {
        return (
            <div key={key} style={style}>
                <Box sx={styles.headerStyle}>
                    {availabilityHeader[columnIndex].headerName}
                </Box>
            </div>
        )
    }

    return (
        <Card sx={styles.card}>
            <Box sx={styles.box}>
                <Box sx={{ ...styles.text, margin: '0px 0px' }}>
                    {AVAILABILITY.AVAILABILITY}
                </Box>
            </Box>
            <Box sx={{ padding: '15px' }}>
                <Box >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={styles.text}>
                                {AVAILABILITY.NEXT_FARM_DATES}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {item.farmDate}
                        </Grid>
                    </Grid>
                    <Box sx={styles.text}>
                        <Typography> {AVAILABILITY.FIND_DIFFERENT_SOLUTION}</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={styles.text}>
                                {AVAILABILITY.SEARCH}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField variant="standard" value={searchAvailability} onChange={(event) => setSearchAvailability(event.target.value)} />
                        </Grid>
                    </Grid>
                    <Box style={{ height: '45vh' }}>
                        <AutoSizer>
                            {({ height, width }: Size) => {
                                return (
                                    <ScrollSync>
                                        {({ onScroll, scrollLeft }) => (
                                            <div style={{ height, width, position: 'relative' }}>
                                                <MultiGrid
                                                    fixedRowCount={1}
                                                    rowCount={adjustedRowCount + 1}
                                                    cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                                                        if (rowIndex === 0) {
                                                            return headerRender(columnIndex, key, style)
                                                        }
                                                        const rowData = getRowData(rowIndex - 1);
                                                        return cellRenderer({ columnIndex, key, rowIndex, style, rowData })
                                                    }}
                                                    columnWidth={({ index }: { index: number }) => availabilityHeader[index].width || 50}
                                                    columnCount={5}
                                                    height={height}
                                                    rowHeight={40}
                                                    scrollLeft={scrollLeft}
                                                    width={width}
                                                    onScroll={onScroll}
                                                />
                                            </div>

                                        )}
                                    </ScrollSync>
                                )
                            }}

                        </AutoSizer>
                    </Box>

                </Box>
                <Box sx={{ marginTop: '20px' }}>
                    <IconButton onClick={() => onClose(false)}><CloseModalIcon /></IconButton>
                </Box>
            </Box>
        </Card>
    );
};

export default AvailabilityModal;