import { createNamedStyles } from '@/shared/interfaces/create_name_styles';
import { theme } from '@/theme';

export const SubstitutionMatrixStyles = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  backBox: {
    display: 'flex',
    alignItems: 'right',
    width: '100%',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: '110px',
    right: '5px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  text: {
    padding: '15px 20px',
    fontSize: '13px',
    fontFamily: 'Roboto, sans-serif',
  },
  imgContainer: {
    height: '150px',
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: theme.custom.strongBorder,
    cursor: 'pointer',
    marginLeft: '37px',
  },
  image: {
    color: '#927C4E',
    fontSize: 50,
  },
  icon: {
    color: '#927C4E',
    fontSize: 20,
    cursor: 'pointer',
  },
  varietiesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    rowGap: '5px',
    width: '280px',
  },
  varietiesTitle: {
    width: 'calc(100% - 50px)',
    backgroundColor: '#F0F0F0',
    padding: '10px 0px 10px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: theme.custom.border,
    textAlign: 'center',
    marginLeft: 'auto',
  },
  varietiesLabel: {
    width: 'calc(100% - 38px)',
    marginLeft: 'auto',
    [theme.breakpoints.down('xl')]: {
      minHeight: '50px',
    },
  },
  varietiesList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    rowGap: '5px',
    width: '100%',
  },
  varietyWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    columnGap: '10px',
  },
  varietyChip: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    columnGap: '10px',
    width: '100%',
    border: theme.custom.border,
    padding: '10px 0px',
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  colorDot: {
    marginLeft: '10px',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
  },
  matrixContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start-start',
    rowGap: '5px',
    width: 'calc(100% - 280px)',
    height: '200px',
  },
  matrixTable: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: `${theme.shape.borderRadius}px`,
    minHeight: '150px',
  },
  tableLabel: {
    [theme.breakpoints.down('xl')]: {
      minHeight: '50px',
    },
  },
  headerRow: {
    display: 'flex',
    width: '100%',
    background: '#F5F5F5',
    padding: '1px 0px',
    borderTopRightRadius: `${theme.shape.borderRadius}px`,
    borderTopLeftRadius: `${theme.shape.borderRadius}px`,
    border: theme.custom.border,
    borderBottom: 'none',
  },
  headerCell: {
    flex: 1,
    padding: '10px',
    textAlign: 'center',
    borderBottom: theme.custom.border,
  },
  dataRow: {
    display: 'flex',
    width: '100%',
    borderLeft: theme.custom.border,
    borderRight: theme.custom.border,
  },
  dataCell: {
    flex: 1,
    textAlign: 'center',
    height: '16px',
  },
  butonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    paddingTop: '50px',
    paddingBottom: '20px',
  },
});
