import { salesOrdersSlice } from '../../reducers/salesOrdersAllocation.slice';
export const {
  setStartDate,
  setEndDate,
  setLoading,
  setFilterGapsByVariety,
  setFilterHGaps,
  setFiltersToSearch,
  setSort,
} = salesOrdersSlice.actions;
