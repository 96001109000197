import React from 'react';
import Layout from '../Layout/Layout';
import AdminSettingsDashboard from '@/components/AdminSettings/AdminSettings';
import { ADMIN_SETTINGS } from '@/shared/constantes/AdmingSettings';

const AdminSettings = () => {
    return (
        <Layout title={ADMIN_SETTINGS.TITLE}>
            <AdminSettingsDashboard />
        </Layout>
    );
}

export default AdminSettings;