import { useState } from 'react';
import { transformFiltersAging } from '../../../store/utils/storeUtils';
import { UseIAgingByVariety } from './UseSidebarAgingByVariety.interfaces';

export const UseSidebarAgingByVarietyState = (): UseIAgingByVariety => {
  const [selectedValues, setSelectedValues] = useState([]);

  return {
    filters: transformFiltersAging([]),
    selectedValues,
    setSelectedValues,
  };
};
