export const API_ROUTES = {
  AUTHORITY_URL: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
  GRAPHMEENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  API: `${process.env.REACT_APP_API}`,
  IMAGES_REPOSITORY: `${process.env.REACT_APP_IMG_REPOSITORY}`,
};

export const ENVIRONMENT = {
  DISABLED_ICON: `${process.env.REACT_APP_DISABLED}`,
  DISABLED_ICON_ACCUMULATED: `${process.env.REACT_APP_ACCUMULATE_IS_DISABLE}`,
};
