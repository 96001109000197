import TableHeader from "@/components/Table/TableHeader";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React from "react";
import { UseStateSubstitutionMatrixTable } from "./state/useStateSubstitutionTable";
import { substitutionMatrixStyles as styles } from "./substitutionMatrix.styles";
import SearchBar from "@/components/SearchBar/SearchBar";
import TooltipIcon from "@/components/Tooltip/TooltipIcon";
import { SUBSTITUTION_MATRIX } from "@/shared/constantes/SubstitutionMatrix";
import { SearchBarIcon } from "@/shared/icons/Icons";
import FilterChip from "@/components/FilterChip/FilterChip";
import { IProductData } from "@/shared/interfaces/substitutions.interfaces";

interface SubstitutionMatrixTableProps {
    setProductData: (data: IProductData) => void;
}

const SubstitutionMatrixTable: React.FC<SubstitutionMatrixTableProps> = ({ setProductData }) => {
    const {
        headerColumns,
        showSearchBar,
        agingData,
        handleSort,
        sortBy,
        setShowSearchBar,
        handleSearchValue,
        handleSearchSubmit
    } = UseStateSubstitutionMatrixTable()

    return (
        <>
            <Box sx={{
                position: "absolute",
                top: { xs: '160px', md: '120px' },
                left: '18%',
                zIndex: 1000,
                display: 'flex',
                alignItems: 'center',
            }}>
                <FilterChip />
            </Box>
            <Box sx={styles.box}>
                <Box sx={styles.text}>
                    <Box>{`${agingData.length} results`} </Box>
                </Box>
                <Box sx={styles.boxIcons}>
                    {showSearchBar ? (
                        <Box sx={styles.search}>
                            <SearchBar handleSearchValeu={handleSearchValue} handleSubmit={handleSearchSubmit} />
                        </Box>
                    ) : (
                        <TooltipIcon
                            data-testid="search-icon"
                            title={SUBSTITUTION_MATRIX.BUTTON_SEARCH}
                            handleClick={() => setShowSearchBar(true)}
                            icon={<SearchBarIcon />}
                        />
                    )}
                </Box>
            </Box>
            <Box>
                <Typography sx={styles.subTitle}>
                    {SUBSTITUTION_MATRIX.SUBTITLE}
                </Typography>
            </Box>
            <TableContainer component={Paper} style={{ maxHeight: '70vh' }}>
                <Table>
                    <TableHeader
                        headCell={headerColumns}
                        handleSort={handleSort}
                        sortBy={sortBy}
                        onChangeCheckBox={() => { }}
                    >
                    </TableHeader>
                    <TableBody>
                        {agingData && agingData.length > 0 && agingData.map((row: any, index: number) => {
                            return (
                                <TableRow key={index} sx={{ border: '1px solid #E0E0E0 ' }}>
                                    <TableCell sx={styles.cellStyle}>
                                        {row.category}
                                    </TableCell>
                                    <TableCell sx={styles.cellStyle}>
                                        {row.short_color}
                                    </TableCell>
                                    <TableCell onClick={() => setProductData({
                                        category: row.category,
                                        variety: row.variety,
                                        short_color: row.short_color
                                    })} sx={styles.cellHover}>
                                        {row.variety}
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
export default SubstitutionMatrixTable;