import { createSlice } from '@reduxjs/toolkit';

export const initialState: any = {
  loading: false,
  isFilterGapsVariety: false,
  isFilterHGaps: false,
  filtersToSearch: {
    inventoryType: [],
  },
  sortBy: null,
  sortDirection: 'asc',
  filters: [],
};

export const commonState = createSlice({
  name: 'commonStateSlice',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFilterGapsByVariety: (state, action) => {
      state.isFilterGapsVariety = action.payload;
    },
    setFilterHolidayGaps: (state, action) => {
      state.isFilterHGaps = action.payload;
    },
    setFiltersToSearch: (state, action) => {
      state.filtersToSearch = action.payload;
    },
    setSort: (state, action) => {
      state.sortBy = action.payload.column;
      state.sortDirection = action.payload.newDirection;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export default commonState.reducer;
