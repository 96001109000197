import React from 'react';
import { Navigate } from 'react-router-dom';
import { IProtectedRouteProps } from './ProtectedRoute.interfaces';
import Cookies from 'js-cookie'


const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ element, ...rest }) => {
  const loggedIn = Cookies.get('session');

  return loggedIn ? element : <Navigate to="/" />;
};
export default ProtectedRoute;
