export const salesOrdersAllocationColumns = [
  'accountName',
  'market',
  'guidance',
  'accountOwner',
  'locationCode',
  'weekNumber',
  'farmDate',
  'weekDay',
  'truckDate',
  'orderType',
  'prebookId',
  'purchaseOrderNumber',
  'category',
  'color',
  'variety',
  'grade',
  'apsLfd',
  'unitPrice',
  'boxComposition',
  'boxType',
  'totalBoxes',
  'markCode',
  'lineItemStatus',
  'totalStems',
  'allocatedStems',
  'missingStems',
];
