export const formatDate = (date: Date): string => {
  return date.toISOString().split('T', 1)[0];
};

export const handleFarmDay = (date: string): string => {
  const currenDay = new Date(date);
  const dayIndex = currenDay.getDay();
  const dayOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  return dayOfWeek[dayIndex];
};

export const handleWeekNumber = (date: string): number => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 4 - (newDate.getDay() || 1));
  const yearStart = new Date(newDate.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(
    ((newDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );
  return weekNumber;
};
