import React from "react";
import { Chip } from "@mui/material";

interface FilterChipItemProps {
    label: string;
    handleDelete: () => void;
}

const FilterChipItem: React.FC<FilterChipItemProps> = React.memo(({ label, handleDelete }) => (
    <Chip
        label={label}
        variant="outlined"
        onDelete={handleDelete}
        color="primary"
        style={{ margin: "5px", color: "black" }}
    />
));

FilterChipItem.displayName = 'FilterChipItem';

export default FilterChipItem;