import { createNamedStyles } from '@/shared/interfaces/create_name_styles';
import { theme } from '@/theme';

export const substitutionMatrixStyles = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    marginBottom: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    fontWeight: '600',
    margin: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subTitle: {
    margin: '20px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
  },
  boxIcons: {
    display: 'flex',
    width: '50%',
    justifyContent: 'end',
  },
  search: {
    width: '50%',
    marginBottom: '5px',
    marginTop: '5px',
  },
  cellHover: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  cellStyle: {
    border: '1px solid #E0E0E0 ',
  },
});
