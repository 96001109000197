import { createNamedStyles } from '@/shared/interfaces/create_name_styles';

export const cardStyles = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    marginBottom: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: {
      xs: '11px',
      sm: '14px',
    },
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > :not(style)': {
      m: 1,
      width: 300,
      height: 80,
    },
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  paper: {
    padding: '16px',
    textAlign: 'left',
    cursor: 'pointer',
    border: '1px solid #927C4E',
    borderRadius: '0px',
  },
});
