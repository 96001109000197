import { Box } from '@mui/material';
import React from 'react';

interface TimerProps {
    time: string;
}

const Timer: React.FC<TimerProps> = ({ time }) => {

    return (
        <Box sx={{fontSize:"11px", display: "flex", alignItems:"center", justifyContent:"end"}}>Last allocation update: {time} </Box>
    )
}
export default Timer;