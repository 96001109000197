import DragAndDrop from "@/components/DragAndDrop/DragAndDrop";
import { IDENTIFY_GAPS } from "@/shared/constantes/AllocationPriorities";
import { Box, Switch, Typography } from "@mui/material";
import React from "react";
import UseStateIdentifyGaps from "./state/useStateIdentifyGaps";
import Footer from "../Footer/Footer";
import { ISubstitutionRules } from "../../AllocationPriorities/state/useAllocationPriorities.interfaces";

const IdentifyGaps: React.FC<ISubstitutionRules> = ({ card, handleContinuesClick, handleBackClick, setOpenAlert }) => {
    const {
        groupBy,
        setGroupBy,
        handleSaveSettings,
        saveAndContinues
    } = UseStateIdentifyGaps({ handleContinuesClick, setOpenAlert });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <Typography sx={{ flex: 1 }}><span style={{ fontWeight: 600 }}>{IDENTIFY_GAPS.APPLY_BUSINESS_RULES}</span>{IDENTIFY_GAPS.BUSINESS_RULES}</Typography>
            <Box sx={{ margin: "50px 10px" }} >
                <DragAndDrop title={IDENTIFY_GAPS.GROUP_BY} items={groupBy} setItems={setGroupBy} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '75%',
                    marginBottom: "20px"
                }}
            >
                <Typography sx={{ flex: 1, }} >{IDENTIFY_GAPS.PRIORIZE_SUBSTITUTION}</Typography>
                <Switch disabled defaultChecked />
            </Box>
            <Typography sx={{ flex: 1 }}>{IDENTIFY_GAPS.NOTE}<span style={{ fontWeight: 600 }}>{IDENTIFY_GAPS.RECOMMENDED}</span>{IDENTIFY_GAPS.NOTE2}</Typography>
            <Footer
                card={card}
                handleBackClick={handleBackClick}
                handleContinuesClick={saveAndContinues}
                handleSaveSettings={handleSaveSettings}
            />

        </Box>
    );
};

export default IdentifyGaps;