import {
  TableCell,
  TableHead,
  TableRow,
  Box,
  Tooltip,
  Checkbox,
} from '@mui/material';
import React, { CSSProperties, FC } from 'react';
import { TableHeaderStyles as styles } from './TableHeader.styles';
import { OrderAZ, OrderZA } from '@/shared/icons/Icons';
import { useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/store';
import { IheadCell, ITableHeaderProps } from './TableHeader.interfaces';
import { handleWeekNumber } from '@/shared/utils/dateUtils';

const getStickyStyle = (headerName: string): CSSProperties | undefined => {
  return headerName === 'ACC'
    ? { position: 'sticky', left: 0, zIndex: 1000 }
    : undefined;
};

const TableHeader: FC<ITableHeaderProps> = ({
  headCell,
  handleSort,
  sortBy,
  visibleColumns,
  aditionalColumns,
  isCheckbox,
  checkboxState,
  onChangeCheckBox
}: ITableHeaderProps) => {
  const sortDirection = useAppSelector(
    (store: RootState) => store.commonState.sortDirection
  );

  const handleOrderIcon = (field: string) => {
    if (sortBy === field) {
      return (
        <Box>
          {sortDirection === 'asc' ? <OrderAZ /> : <OrderZA />}
        </Box>
      );
    }
    return null;
  };
  const HandleTableCell = (headData: IheadCell, index: number, isDate?: boolean) => {
    const columns = headCell;
    const existColumn = columns.slice(0, -1).find((item) => item.field === headData.field)

    return (
      <TableCell
        sx={styles.tableCell}
        key={index}
        style={getStickyStyle(headData.headerName)}
        onClick={() => {
          handleSort && (headData.allowSort ? handleSort(headData.field) : (existColumn && handleSort(headData.field)));
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Tooltip
            title={
              isDate && headData.field !== 'total'
                ? `Week ${handleWeekNumber(headData.field)}`
                : headData.hoverName
            }
            placement="top-start"
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#927C4E',
                  fontSize: '12px',
                },
              },
            }}
          >
            <span>{headData.headerName}</span>
          </Tooltip>
          {headData.allowSort ? handleOrderIcon(headData.field) : (existColumn && handleOrderIcon(headData.field))}
        </Box>
      </TableCell>
    )
  };

  return (
    <TableHead>
      <TableRow>
        {isCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={checkboxState}
              onChange={onChangeCheckBox}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {headCell.map(
          (headData, index) =>
             visibleColumns? (visibleColumns[headData.field] && HandleTableCell(headData, index)) : HandleTableCell(headData, index)
        )}
        {aditionalColumns &&
          aditionalColumns
            .slice(headCell.length)
            .map((headData, index) => HandleTableCell(headData, index, true))}
      </TableRow>
    </TableHead>
  );
};
export default TableHeader;
