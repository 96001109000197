import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/store';
import { useAvailableToPromiseInterface } from './useAvailableToPromiseState.interfaces';
import data from '@/shared/data/available_to_promise_header.json';
import { IheadCell } from '@/components/Table/TableHeader.interfaces';
import {
  downloadCsv,
  getATP,
} from '@/store/thunks/AvailableToPromise/availableToPromise.thunks';
import { formatDateandTime, handleFarmDay } from '@/shared/utils/dateUtils';
import { setSort } from '@/store/actions/commonState/commonState.actions';
import { columnsDate } from '@/shared/utils/numbersUtils';
import inventoryTypes from '@/shared/data/inventory_type.json';
import inventryTypesAccumulated from '@/shared/data/inventory_type_accumulated.json';
import { setCurrentPageATP, setIsAccumulate } from '@/store/actions/availableToPromise/availableToPromise.actions';

export const UseAvailableToPromiseState =
  (): useAvailableToPromiseInterface => {
    const columns: IheadCell[] = data.data;
    const dispatch = useAppDispatch();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [headerDataTable, setheaderData] = useState<IheadCell[]>(columns);
    const {
      startDate,
      endDate,
      atpData,
      filtersInventoryTypes,
      isAccumulate,
      isFilterGlobalData,
      groupResultValue,
      isGapsByVarietyATP,
      filtersToSearchATP,
      currentPageATP
    } = useAppSelector((store: RootState) => store.availableToPromise);
    const {
      sortBy,
      sortDirection,
      loading,
    } = useAppSelector((store: RootState) => store.commonState);

    const headerIds = useRef<string[]>([]);
    const { atp, totalAtp, totalPages, lastAllocationDate } = atpData;
    let searchValueATP = useRef('');
    const [visibleInventoryTypes, setVisibleInventoryTypes] = useState<useAvailableToPromiseInterface['visibleInventoryTypes']>({});
    const [visibleColumns, setVisibleColumns] = useState<{ [key: string]: boolean }>(() => {
      const initialVisibility: { [key: string]: boolean } = columns.reduce((acc, column) => {
        acc[column.field] = true;
        return acc;
      }, {} as { [key: string]: boolean });

      return initialVisibility;
    });

    const handleToggleColumn = (columnId: string) => {
      setVisibleColumns((prev) => ({
        ...prev,
        [columnId]: !prev[columnId],
      }));
    };

    useEffect(() => {
      dispatch(setIsAccumulate(false))
    }, []);

    useEffect(() => {
      headerIds.current = columnsDate(startDate, endDate);
      if (!isFilterGlobalData) {
        dispatch(setSort({ column: 'total', newDirection: 'asc' })) //Default sorting 
      }
    }, [isFilterGlobalData]);

    useEffect(() => {
      const formattedDates = columnsDate(startDate, endDate);
      const dateObject = formattedDates.map((date) => {
        const dateObject = date.split('-');
        return {
          field: date,
          headerName: `${handleFarmDay(date)} ${dateObject[1]}/${dateObject[2]}`,
          hoverName: `${dateObject[1]}/${dateObject[2]}`,
          allowSort: true
        };
      });
      const total = {
        field: 'total',
        headerName: 'TOT',
        hoverName: 'Total',
        allowSort: true
      };
      const newHederData = [...data.data, ...dateObject, total];
      setheaderData(newHederData);
      headerIds.current = formattedDates;
    }, [atp]);

    const handleDownloadATPCSV = () => {
      let newFilters = { ...filtersToSearchATP };
      if (filtersInventoryTypes.inventoryType.length > 0) {
        newFilters = {
          ...newFilters,
          inventoryType: filtersInventoryTypes.inventoryType,
        };
      }
      dispatch(
        downloadCsv({
          startDate,
          endDate,
          query: searchValueATP.current,
          data: newFilters,
          missingStems: isGapsByVarietyATP,
          runGlobalData: isFilterGlobalData,
          groupResult: groupResultValue,
          accumulate: isAccumulate,
          sortBy: sortBy,
          sortDirection: sortDirection,
        })
      );
    };

    const handleSort = (column: string) => {
      dispatch(setCurrentPageATP(1));
      let newDirection = 'asc';
      if (column === sortBy) {
        newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
      }

      dispatch(setSort({ column, newDirection }));
      dispatch(getATP(
        {
          startDate,
          endDate,
          dispatch,
          query: searchValueATP.current,
          missingStems: isGapsByVarietyATP,
          data: filtersToSearchATP,
          currentPage: 1,
          sortBy: column,
          sortDirection: newDirection,
          groupResult: groupResultValue,
        }
      ));
    };

    const handleSearchValue = (data: string) => (searchValueATP.current = data);

    const handleSubmit: useAvailableToPromiseInterface['handleSubmit'] = (event) => {
      event.preventDefault();
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          query: searchValueATP.current,
          missingStems: isGapsByVarietyATP,
          data: filtersToSearchATP,
          currentPage: 1,
          runGlobalData: isFilterGlobalData,
        })
      );
    };

    useEffect(() => {
      let newVisibility: { [key: string]: boolean } = {};
      if (
        (filtersInventoryTypes.inventoryType &&
          filtersInventoryTypes.inventoryType.length === 0) ||
        filtersInventoryTypes.inventoryType === undefined
      ) {
        if (isAccumulate) {
          inventryTypesAccumulated.forEach(
            (item) => (newVisibility[item.inventorytype] = true)
          );
        } else {
          inventoryTypes.forEach(
            (item) => (newVisibility[item.inventorytype] = true)
          );
        }
      } else {
        filtersInventoryTypes.inventoryType &&
          filtersInventoryTypes.inventoryType.forEach(
            (item) => (newVisibility[item] = true)
          );
      }
      setVisibleInventoryTypes(newVisibility);
    }, [filtersInventoryTypes, isAccumulate]);

    const handleChangeAccumulate = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => dispatch(setIsAccumulate(event.target.checked));

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      dispatch(setCurrentPageATP(newPage));
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          query: searchValueATP.current,
          missingStems: isGapsByVarietyATP,
          data: filtersToSearchATP,
          currentPage: newPage,
          sortBy,
          sortDirection,
          groupResult: groupResultValue,
        })
      );
    }

    return {
      inventorytype: filtersInventoryTypes.inventoryType,
      loading,
      showSearchBar,
      setShowSearchBar,
      headerDataTable,
      atp,
      headerIds: headerIds.current,
      sortBy,
      sortDirection,
      columns,
      inventoryColumns: isAccumulate
        ? inventryTypesAccumulated
        : inventoryTypes,
      isAccumulate,
      visibleInventoryTypes,
      totalAtp,
      totalPages,
      handleDownloadATPCSV,
      page: currentPageATP,
      handleSort,
      handleSearchValue,
      handleSubmit,
      handleChangeAccumulate,
      handleChangePage,
      date: lastAllocationDate && formatDateandTime(lastAllocationDate),
      visibleColumns,
      handleToggleColumn,
    };
  };
