import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import { msalInstance } from './authConfig.js';
import { EventType } from '@azure/msal-browser';

!msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0 &&
  msalInstance.setActiveAccount(msalInstance.getActiveAccount());

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

root.render(<App instance={msalInstance} />);
