import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import AvailableToPromise from './containers/revenueManagement/AvailableToPromise/AvailableToPromise';
import AtpSourceData from './containers/revenueManagement/AtpSourceData/AtpSourceData';
import Header from './components/header/Header';
import Home from './components/Home/Home';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { Provider } from 'react-redux';
import { store } from './store/store';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { ROUTES } from './shared/routes';
import ActionPlan from './containers/revenueManagement/ActionPlan/ActionPlan';
import AdminSettings from './containers/adminSettings/AdminSettings';
import AgingByVariety from './containers/adminSettings/AgingByVariety/AgingByVariety';
import AllocationPriorities from './containers/adminSettings/AllocationPriorities/AllocationPriorities';
import SubstitutionMatrix from './containers/adminSettings/SubstitutionMatrix/SubstitutionMatrix';

const App = ({ instance }: any) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={instance}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route key="home" path="/" element={<Home />} />
            <Route key="revenuemanagement" path={ROUTES.REVENUE_MANAGEMENT}>
              <Route
                key="availabletopromise"
                path={ROUTES.AVAILABLE_TO_PROMISE}
                element={<ProtectedRoute element={<AvailableToPromise />} />}
              />
              <Route
                key="atpsourcedata"
                path={ROUTES.ATP_SOURCE_DATA}
                element={<ProtectedRoute element={<AtpSourceData />} />}
              />
              <Route
                key="actionplan"
                path={ROUTES.ACTION_PLAN}
                element={<ProtectedRoute element={<ActionPlan />} />}
              />
            </Route>
            {/* Settings */}
            <Route
              key="adminsettings"
              path={ROUTES.ADMIN_SETTINGS}
              element={<ProtectedRoute element={<AdminSettings />} />}>
            </Route>
            <Route key="adminsettings" path={ROUTES.ADMIN_SETTINGS}>
              <Route
                key="agingbyvariety"
                path={ROUTES.AGING_BY_VARIETY}
                element={<ProtectedRoute element={<AgingByVariety />} />}
              />
              <Route
                key="allocationpriorities"
                path={ROUTES.ALLOCATION_PRIORITIES}
                element={<ProtectedRoute element={<AllocationPriorities />} />}
              />
              <Route
                key="substitutionmatrix"
                path={ROUTES.SUBSTITUTION_MATRIX}
                element={<ProtectedRoute element={<SubstitutionMatrix />} />}
              />
            </Route>
            <Route key="salesmanagement" path="/">
              <Route key="sales" path="/" element={<Home />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </MsalProvider>
    </ThemeProvider>
  </Provider>
);

export default App;