import React from 'react';
import ActionPlanTable from '@/components/revenueManagement/ActionPlan/ActionPlanTable/ActionPlanTable';
import RevenueModal from '@/components/revenueManagement/ActionPlan/RevenueModal/RevenueModal';
import 'react-datepicker/dist/react-datepicker.css';
import { ACTION_PLAN } from "@/shared/constantes/ActionPlan"
import { UseActionPlanState } from './state/useActionPlanState';
import SidebarActionPlan from "@/components/revenueManagement/ActionPlan/SidebarActionPlan/SidebarActionPlan"
import Layout from '../../Layout/Layout';

const ActionPlan = () => {
  const { openModal, handleCloseModal, handleOpenModal } = UseActionPlanState();
  return (
    <>
      <Layout
        title={ACTION_PLAN.TITLE}
        sidebar={SidebarActionPlan}
      >
        <ActionPlanTable handleOpenModal={handleOpenModal} />
      </Layout>
      <RevenueModal open={openModal} onClose={handleCloseModal} />
    </>
  );
};
export default ActionPlan;
