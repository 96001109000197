import { createNamedStyles } from '../../shared/interfaces/create_name_styles';
import { theme } from '../../theme';

export const revenueModalStyles = createNamedStyles({
  text: {
    color: '#52525C',
    '&:hover': {
      color: '#2766C0',
    },
  },
  textPrice: {
    color: '#001E49',
    fontSize: '24px',
    lineHeight: '24px',
  },
  button: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    backgroundColor: '#E0E0E0',
    borderRadius: '8px',
    fontSize: '12px',
    border: `1px solid ${theme.palette.primary.main} `,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
  boxModal: {
    position: 'fixed',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '1px solid #8F8385',
    background: '#FFF',
    boxShadow: 24,
    height: '90%',
  },
  headModal: {
    backgroundColor: '#EBE8E3',
    padding: '20px',
    fontSize: '11px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  message: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: '400',
    textAlign: 'center',
    paddingLeft: '45px',
  },
  line: {
    height: '1px',
    backgroundColor: '#EBE8E3',
    border: 'none',
    margin: '20px 0',
  },
});
