import { useEffect, useState } from 'react'
import data from '@/shared/data/substitution_matrix.json'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook'
import { setSelectedFiltersStore } from '@/store/actions/substitutionMatrix/substitutionMatrix'
import { getAging } from '@/store/thunks/AgingByVariety/agingByVariety.thunks'
import { setSort } from '@/store/actions/commonState/commonState.actions'
import { getFilterData, sortData } from '@/shared/utils/numbersUtils'
import { ISubstitutionMatrixTable } from './useStateSubstitutionTable.interfaces'
import { ISelectedFiltersStore } from '@/store/interfaces/substitutionMatrix.interfaces'

export const UseStateSubstitutionMatrixTable = () : ISubstitutionMatrixTable => {
    const headerColumns = data.data
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const { loading, sortBy, sortDirection, filters } = useAppSelector(
        (store: RootState) => store.commonState
    );
    
    const dispatch = useAppDispatch()

    const {
        selectedFiltersStore,
    } = useAppSelector((store: RootState) => store.substitutionMatrix)
    const {
        aging
    } = useAppSelector((store: RootState) => store.agingByVariety)
    
    useEffect(() => {
        dispatch(getAging())
    }, [])

    const handleDeleteChip = (item: any, key: keyof ISelectedFiltersStore) => {
        const newSelectedChips = selectedFiltersStore[key].filter((chip: any) => chip !== item)
        dispatch(setSelectedFiltersStore({ ...selectedFiltersStore, [key]: newSelectedChips }))
    }

    const handleFilterData = (aging: any) => {
        return aging.filter((item: any) => (
            (!selectedFiltersStore.category || selectedFiltersStore.category.length === 0 || selectedFiltersStore.category.includes(item.category)) &&
            (!selectedFiltersStore.variety || selectedFiltersStore.variety.length === 0 || selectedFiltersStore.variety.includes(item.variety)) &&
            (!selectedFiltersStore.color || selectedFiltersStore.color.length === 0 || selectedFiltersStore.color.includes(item.short_color))
        ));
    }

    const handleSort = (column: string) => {
        const newDirection =
            sortBy === column && sortDirection === 'asc' ? 'desc' : 'asc';
        dispatch(setSort({ column, newDirection }));
    };

    const applyFiltersAndSearch = (data: any[]) => {
        const filtered = data.filter((item: any) => (
          (!selectedFiltersStore.category || selectedFiltersStore.category.length === 0 || selectedFiltersStore.category.includes(item.category)) &&
          (!selectedFiltersStore.variety || selectedFiltersStore.variety.length === 0 || selectedFiltersStore.variety.includes(item.variety)) &&
          (!selectedFiltersStore.color || selectedFiltersStore.color.length === 0 || selectedFiltersStore.color.includes(item.short_color))
        ));
      
        if (searchValue) {
          return filtered.filter((item: any) => matchesSearch(item, searchValue));
        }
      
        return filtered;
    };

    const matchesSearch = (item: any, value: string) => {
        const search = value.toLowerCase().split(/\s+/);
      
        return search.every(word =>
          item.variety?.toLowerCase().includes(word) ||
          item.category?.toLowerCase().includes(word) ||
          item.short_color?.toLowerCase().includes(word)
        );
    };

    const handleSearchValue = (value: string) => {
        setSearchValue(value);
    };
    
    const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    return {
        headerColumns,
        showSearchBar,
        handleDeleteChip,
        selectedFiltersStore,
        agingData: sortData(applyFiltersAndSearch(aging), sortBy, sortDirection),
        handleSort,
        sortBy,
        setShowSearchBar,
        handleSearchValue,
        handleSearchSubmit,
        searchValue
    };
}
