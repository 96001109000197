import { Box, Typography, Modal, Button, Grid } from '@mui/material';
import React from 'react';
import { revenueModalStyles as styles } from './RevenueModal.styles';
import { SALES_ORDERS_ALLOCATION } from '../../shared/constantes/SalesOrdersAllocation';
import { useRevenueModalState } from './state/useRevenueModalState';
import { CloseModalIcon } from '../../shared/icons/Icons';
import { convertNumber } from '../../shared/utils/numbersUtils';

const RevenueModal = ({ open, onClose }) => {
  const { allocation, startDate, endDate, revenues, handleActivateAllocation } =
    useRevenueModalState();
  const convertStringToNumber = (dato: string) => parseFloat(dato);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.boxModal}>
        <Box sx={styles.headModal}>
          <Typography>
            Revenue comparison from {startDate} to {endDate}{' '}
          </Typography>
        </Box>
        <Box sx={{ margin: '25px', fontSize: '12px' }}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={styles.textPrice}
                  style={{ textShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                >
                  $
                  {convertNumber(
                    convertStringToNumber(allocation.totalEarnings)
                  )}
                </Typography>
                <Typography
                  style={{ textShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                >
                  Guidance-based Allocation
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ textAlign: 'end' }}>
                <Typography
                  sx={styles.textPrice}
                  style={{ textShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                >
                  $
                  {convertNumber(
                    convertStringToNumber(allocation.totalMaxEarnings)
                  )}
                </Typography>
                <Typography
                  style={{ textShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                >
                  100% Allocation
                </Typography>
              </Grid>
            </Grid>
            <Box sx={styles.line}></Box>
            {revenues.map((item, index) => (
              <Grid container sx={{ padding: '10px 0px' }}>
                <Grid item xs={12} md={6}>
                  <Typography sx={styles.textPrice} style={{ color: '#ccc' }}>
                    ${item.total}
                  </Typography>
                  <Typography style={{ color: '#ccc' }}>
                    {item.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: 'end' }}>
                  {
                    <Button
                      disabled
                      onClick={() => handleActivateAllocation(item.id)}
                      sx={styles.button}
                    >
                      {SALES_ORDERS_ALLOCATION.BUTTON_ALLOCATION}
                    </Button>
                  }
                </Grid>
              </Grid>
            ))}
          </Box>
          <Box sx={{ textAlign: 'start' }}>
            <Button
              data-testid="close"
              onClick={onClose}
              sx={{
                justifyContent: 'start',
                position: 'absolute' as 'absolute',
                top: '95%',
                right: '85%',
              }}
            >
              <CloseModalIcon />
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default RevenueModal;
