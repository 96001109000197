import FilterComponent from "@/components/Filters/FilterComponent";
import React from "react";
import { UseStateSidebarSubstitutionMatrix } from "./state/useStateSidebarSubstitution";
import { IProductData } from "@/shared/interfaces/substitutions.interfaces";


interface SidebarSubstitutionMatrixProps {
    productData: IProductData | null;
}

const SidebarSubstitutionMatrix: React.FC<SidebarSubstitutionMatrixProps> = ({productData}) => {
    const {
        filters,
        selectedValues,
        setSelectedValues,
    } = UseStateSidebarSubstitutionMatrix(productData);

    return (
        <>
            <FilterComponent
                data={filters}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                showHeader={productData === null}
            />
        </>
    )
}
export default SidebarSubstitutionMatrix;