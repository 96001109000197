import { UseIAgingByVarietyTable } from './UseAgingByVarietyTable.interfaces';
import data from '@/shared/data/aging_by_variety_header.json';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/store';
import { useEffect, useMemo, useState } from 'react';
import { getAging, updateAging } from '@/store/thunks/AgingByVariety/agingByVariety.thunks';
import { setSort } from '@/store/actions/commonState/commonState.actions';
import { getFilterData, sortData } from '@/shared/utils/numbersUtils';
import { AgingByVariety } from '@/store/interfaces/aging.interfaces';
import { AGING_BY_VARIETY } from '@/shared/constantes/AgingByVariety';
import { ISelectedFiltersStore } from '@/store/interfaces/agingByVariety.interfaces'
import { setSelectedFiltersStore } from '@/store/actions/agingByVariety/agingByVariety.actions';

export const UseAgingByVarietyTableState = (): UseIAgingByVarietyTable => {
  const columns = data.data;
  const [showSearchBar, setShowSearchBar] = useState(false);
  const { loading, sortBy, sortDirection } = useAppSelector(
    (store: RootState) => store.commonState
  );
  const { aging, selectedFiltersStore } = useAppSelector((store: RootState) => store.agingByVariety);
  const dispatch = useAppDispatch();
  const [editIndex, setEditIndex] = useState<number>(0);
  const [editColumn, setEditColumn] = useState<string>('');
  const [formData, setFormData] = useState<UseIAgingByVarietyTable['formData']>({ rotationDays: 0 });
  const [filteredAging, setFilteredAging] = useState<any>([]);

  // Edit options state
  const [selectedRows, setSelectedRows] = useState<AgingByVariety[]>([]);
  const [undoSelectedRows, setUndoSelectedRows] = useState<AgingByVariety[]>([]);
  const [type, setType] = useState('Increase');

  //Push notification state
  const [pushNotificationMessage, setPushNotificationMessage] = useState<string>('');
  const [openPushNotification, setOpenPushNotification] = useState<boolean>(false);
  const [autoHideDuration, setAutoHideDuration] = useState<number | undefined>(undefined);
  const [showUndoAction, setShowUndoAction] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [closePopover, setClosePopover] = useState(false);

  const handleDeleteChip = (item: any, key: keyof ISelectedFiltersStore) => {
    const newSelectedChips = selectedFiltersStore[key].filter((chip: any) => chip !== item)
    dispatch(setSelectedFiltersStore({ ...selectedFiltersStore, [key]: newSelectedChips }))
  }

  useEffect(() => {
    dispatch(getAging());
  }, []);

  const matchesSearch = (item: AgingByVariety, value: string) => {
    const search = value.toLowerCase().split(/\s+/);
  
    return search.every(word =>
      item.variety?.toLowerCase().includes(word) ||
      item.category?.toLowerCase().includes(word) ||
      item.short_color?.toLowerCase().includes(word) ||
      item.product_rotation_day?.toString().includes(word)
    );
  };

  const handleSearchValue = (value: string) => {
    setSearchValue(value);

    const filtered = getFilterData(aging, selectedFiltersStore).filter(item =>
      matchesSearch(item, value)
    );

    setFilteredAging(sortData(filtered, sortBy, sortDirection));
  };

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const filtered = getFilterData(aging, selectedFiltersStore).filter(item =>
      matchesSearch(item, searchValue)
    );

    setFilteredAging(sortData(filtered, sortBy, sortDirection));
  };

  const handleChange: UseIAgingByVarietyTable['handleChange'] = (e, columnName) => {
    setFormData({
      ...formData,
      [columnName]: e.target.value,
    });
  };

  const handleSave = () => {
    // Aquí  agregar la lógica para guardar los datos
    setEditIndex(-1);
    setEditColumn('');
  };

  const handleDoubleClick: UseIAgingByVarietyTable['handleDoubleClick'] = (rowIndex, columnName, row) => {
    setFormData(row);
    setEditIndex(rowIndex);
    setEditColumn(columnName);
  };

  const handleSort: UseIAgingByVarietyTable['handleSort'] = (column) => {
    const newDirection =
      sortBy === column && sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, newDirection }));
  };

  const handleUpdateAging = async (newAging: any, undo: boolean) => {

    setAutoHideDuration(6000)

    if (newAging.length === 0) {
      setAutoHideDuration(3000)
      setPushNotificationMessage(AGING_BY_VARIETY.ROTATION_NOVARIETIES_SELECTED);
    }
    else if (newAging.every((row: any) => row.newRotationDays > 0)) {
      setClosePopover(true);
      const status = await dispatch(updateAging({ body: { varieties: newAging } })).unwrap();
      if (status) {
        setUndoSelectedRows(selectedRows);
        setSelectedRows([]);
        setPushNotificationMessage(undo ? AGING_BY_VARIETY.ROTATION_RESTORED_SUCCESS : AGING_BY_VARIETY.ROTATION_UPDATED_SUCCESS);
        setShowUndoAction(!undo);
      } else {
        setAutoHideDuration(undefined)
        setPushNotificationMessage(undo ? AGING_BY_VARIETY.ROTATION_RESTORED_ERROR : AGING_BY_VARIETY.ROTATION_UPDATED_ERROR);
      }
    } else {
      setPushNotificationMessage(AGING_BY_VARIETY.ROTATION_CANNOT_BE_ZERO);
    }
    setOpenPushNotification(true);
  }

  useMemo(() => {
    setFilteredAging(sortData(getFilterData(aging, selectedFiltersStore), sortBy, sortDirection));
  }, [aging, sortBy, sortDirection]);

  useEffect(() => {
    const filtered = getFilterData(aging, selectedFiltersStore).filter(item =>
      matchesSearch(item, searchValue)
    );

    setFilteredAging(sortData(filtered, sortBy, sortDirection));
  }, [aging, selectedFiltersStore, sortBy, sortDirection, searchValue]);

  useEffect(() => {
    if (!openPushNotification) {
      setUndoSelectedRows([]);
    }
  }, [openPushNotification]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAlertVisible(false);
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  return {
    columns,
    loading,
    aging,
    filteredAging,
    editIndex,
    editColumn,
    formData,
    showSearchBar,
    openPushNotification,
    pushNotificationMessage,
    autoHideDuration,
    showUndoAction,
    selectedRows,
    undoSelectedRows,
    type,
    closePopover,
    sortBy,
    isAlertVisible,
    selectedFiltersStore,
    setOpenPushNotification,
    setSelectedRows,
    setClosePopover,
    setType,
    handleUpdateAging,
    setShowSearchBar,
    handleDoubleClick,
    handleSave,
    handleChange,
    handleSort,
    handleSearchValue,
    handleSearchSubmit,
  };
};
