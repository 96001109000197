import { createNamedStyles } from '@/shared/interfaces/create_name_styles';

export const MenuCardStyles = createNamedStyles({
  link: {
    margin: 3,
    color: 'white',
    fontSize: '1rem',
    textDecorationColor: '${(props) => props.theme.palette.background}',
    '@media (max-width: 1240px)': {
      fontSize: '15px',
    },
    textAlign: 'justify',
  },
});
