import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import { IDialogPopup } from './DialogPopup.interface';


const DialogPopup: React.FC<IDialogPopup> = ({ title, content, labelClose, labelAgree, onClose, onAgree }) => {


    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title || "Attention"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {onClose && <Button onClick={onClose} >{labelClose || "Close"}</Button>}
                {onAgree && <Button onClick={onAgree} >{labelAgree || "Agree"}</Button>}
            </DialogActions>
        </Dialog>

    )
};

export default DialogPopup;