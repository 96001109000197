import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import SalesOrderAllocation from './containers/revenueManagement/SaLesOrdersAllocation/SalesOrderAllocation';
import AvailableToPromise from './containers/revenueManagement/AvailableToPromise/AvailableToPromise';
import Header from './components/header/Header';
import Home from './components/Home/Home';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { Provider } from 'react-redux';
import { store } from './store/store';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { ROUTES } from './shared/routes';
import AgingByVariety from './containers/revenueManagement/AgingByVariety/AgingByVariety';
import ActionPlan from './containers/revenueManagement/ActionPlan/ActionPlan';

const App = ({ instance }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={instance}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route key="home" path="/" element={<Home />} />
            <Route key="revenuemanagement" path={ROUTES.REVENUE_MANAGEMENT}>
              <Route
                key="salesorderallocation"
                path={ROUTES.SALES_ORDERS_ALLOCATION}
                element={<ProtectedRoute element={<SalesOrderAllocation />} />}
              />
              <Route
                key="availabletopromise"
                path={ROUTES.AVAILABLE_TO_PROMISE}
                element={<ProtectedRoute element={<AvailableToPromise />} />}
              />
              <Route
                key="agingbyvariety"
                path={ROUTES.AGING_BY_VARIETY}
                element={<ProtectedRoute element={<AgingByVariety />} />}
              />
              <Route
                key="actionplan"
                path={ROUTES.ACTION_PLAN}
                element={<ProtectedRoute element={<ActionPlan />} />}
              />
            </Route>
            <Route key="salesmanagement" path="/">
              <Route key="sales" path="/" element={<Home />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </MsalProvider>
    </ThemeProvider>
  </Provider>
);

export default App;
