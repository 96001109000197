import { Button, Grid } from "@mui/material";
import React from "react";
import { rulesSettings } from '../../SubstitutionRules/SubstitutionRuleComponents';

interface IFooter {
    handleContinuesClick: () => void;
    handleBackClick: () => void;
    handleSaveSettings : () => void;
    card: number;
}

const Footer: React.FC<IFooter> = ({ card, handleContinuesClick, handleBackClick, handleSaveSettings }) => {
    return (
        <Grid container sx={{ marginTop: "100px", marginBottom: "20px" }}>
            <Grid item xs={12} md={4}>
                <Button sx={{ marginTop: 2, textDecoration: "underline" }} disabled={card === 0} onClick={handleBackClick}>Back</Button>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" color="primary" sx={{ marginTop: 2 }} disabled={card === rulesSettings.length - 1} onClick={handleContinuesClick}>Continue</Button>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button sx={{ marginTop: 2, textDecoration: "underline" }} onClick={handleSaveSettings}>Save Settings</Button>
            </Grid>
        </Grid>
    );
}

export default Footer;