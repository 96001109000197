import { API_ROUTES } from '@/shared/api_routes';
import axios from '../../../shared/axios-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CONFIGURATION_REFERENCE } from '@/shared/constantes/AdmingSettings';
import Cookies from 'js-cookie';

const saveSettingsConfiguration = createAsyncThunk<
  string,
  {
    configurationId: string;
    configuration: {};
  }
>(
  'allocationPriorities/saveSettingsConfiguration',
  async ({ configurationId, configuration }) => {
    try {
      const data = {
        configuration_id: configurationId,
        configuration_value: configuration,
        configuration_description: 'Allocation Priorities',
        configuration_reference: CONFIGURATION_REFERENCE.ALLOCATION,
        configuration_user: Cookies.get('session'),
      };
      const response = await axios.patch(
        `${API_ROUTES.API}/settings/configuration`,
        data
      );
      return response.data;
    } catch (error) {
      console.error('Error saving business rules', error);
      throw error;
    }
  }
);

const getConfiguration = createAsyncThunk<
  any,
  {
    id: string;
  }
>('allocationPriorities/getConfiguration', async ({ id }) => {
  try {
    const response = await axios.get(
      `${API_ROUTES.API}/settings/get-settings?configuration_id=${id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching business rules', error);
    throw error;
  }
});

const getAppVersion = createAsyncThunk<{ version: string }>(
  'settings/getAppVersion',
  async () => {
    try {
      const response = await axios.get(
        `${API_ROUTES.API}/settings/getAppVersion`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching app version', error);
      throw error;
    }
  }
);

export { saveSettingsConfiguration, getConfiguration, getAppVersion };
