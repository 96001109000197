import { createNamedStyles } from '@/shared/interfaces/create_name_styles';

export const editOptionsStyle = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    fontSize: '13px',
    padding: '15px',
    fontWeight: 'bold',
  },
  option: {
    fontSize: '13px',
    padding: '15px 7px',
  },
  divider: {
    margin: '0px 10px',
  },
});
