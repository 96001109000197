import React, { useState } from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Checkbox from '@mui/material/Checkbox';
import { filterStyle as style } from './Filter.styles';

const FilterComponent = ({
  data,
  selectedValues,
  setSelectedValues,
  showHeader,
}) => {
  const [filterValues, setFilterValues] = useState({});
  const [filterInput, setFilterInputs] = useState({});

  const handleSelecteAll = (filter) => {
    const newSelectedValue = { ...selectedValues };
    newSelectedValue[filter.id] = filter.valueOptions.slice();
    setSelectedValues(newSelectedValue);
  };

  const handleClear = (filter) => {
    const newSelectedValue = { ...selectedValues };
    newSelectedValue[filter.id] = [];
    setSelectedValues(newSelectedValue);
  };
  const handleClearAll = () => {
    setSelectedValues([]);
    setFilterInputs({});
    setFilterValues({});
  };

  const handleChange = (type, event, filter) => {
    const inputElement = event.currentTarget.closest('input'); // Find the input element
    const newSelectedValue = { ...selectedValues };

    if (event.target.checked) {
      if (type == 'RadioButton') {
        newSelectedValue[filter.id] = [];
        newSelectedValue[filter.id].push(inputElement.value);
      } else {
        if (!newSelectedValue[filter.id]) {
          newSelectedValue[filter.id] = [];
        }
        newSelectedValue[filter.id] = [
          ...newSelectedValue[filter.id],
          inputElement.value,
        ];
      }
    } else {
      newSelectedValue[filter.id] = newSelectedValue[filter.id].filter(
        (val) => val !== inputElement.value
      );
    }
    setSelectedValues(newSelectedValue);
  };

  const handleFilter = (event, index) => {
    const value = event.target.value;
    setFilterInputs((prev) => ({ ...prev, [index]: value }));
    const newValueOptions = data[index]['valueOptions'];
    const newFilterValues = newValueOptions.filter((item) =>
      item.toLowerCase().includes(value.toLowerCase())
    );
    setFilterValues((prev) => ({
      ...prev,
      [index]: newFilterValues,
    }));
  };

  return (
    <>
      {showHeader && (
        <Box
          sx={{ margin: '5px', backgroundColor: 'white', padding: '2px' }}
        ></Box>
      )}
      {showHeader && (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button sx={style.textButton} onClick={handleClearAll}>
            Clear Filters
          </Button>
        </Box>
      )}
      <div style={{ maxHeight: '59vh', overflowY: 'auto' }}>
        {data.map((filter, index) => (
          <Accordion key={`filter.id_${index}`}>
            <AccordionSummary
              sx={style.filterSummary}
              expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}
              aria-controls="panel1-content"
              id={filter.id}
            >
              <Typography sx={style.text}>{filter.filtername}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={style.accordionDetails}>
              {(filter.id === 'accountName' || filter.id === 'variety') && (
                <TextField
                  data-testid="searchBar"
                  id={`search-bar-${index}`}
                  variant="outlined"
                  placeholder={`Search by ${filter.filtername}`}
                  size="small"
                  value={filterInput[index] || ''}
                  onChange={(event) => handleFilter(event, index)}
                  sx={style.searchBar}
                />
              )}
              {filter.valueOptions.length > 4 && (
                <Box sx={style.box}>
                  <div onClick={() => handleSelecteAll(filter)}>Select All</div>
                  <div onClick={() => handleClear(filter)}>Clear All</div>
                </Box>
              )}
              <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                <RadioGroup>
                  {(filterValues[index] || filter.valueOptions).map((item) => (
                    <FormControlLabel
                      key={item}
                      value={item}
                      control={
                        filter.componentType == 'RadioButton' ? (
                          <Radio />
                        ) : (
                          <Checkbox />
                        )
                      }
                      label={
                        <Typography sx={{ fontSize: '12px' }}>
                          {item}
                        </Typography>
                      }
                      checked={
                        selectedValues[filter.id]
                          ? selectedValues[filter.id].includes(item)
                          : false
                      }
                      onChange={(event) =>
                        handleChange(filter.componentType, event, filter)
                      }
                    />
                  ))}
                </RadioGroup>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};
export default FilterComponent;
