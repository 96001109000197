import { createNamedStyles } from '@/shared/interfaces/create_name_styles';
import { theme } from '@/theme';

export const allocationPrioritiesStyles = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '8px',
  },
  text: {
    padding: '15px 20px',
    fontSize: '13px',
    fontFamily: 'Roboto, sans-serif',
  },
  drag: {
    padding: '1px 20px 20px 20px',
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > :not(style)': {
      m: 1,
      width: '100%',
      // height: 740,
    },
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 5,
    overflow: 'auto',
    height: '100%',
    borderRadius: 0,
    border: '1px solid #927C4E',
  },
  boxRuleSettings: {
    marginBottom: '60px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.primary.main} `,
  },
  textVersionControl: {
    fontSize: '11px',
    fontFamily: 'Roboto, sans-serif',
  },
  linkReEstablish: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    display: 'contents',
  },
});
