export const SALES_ORDERS_ALLOCATION = {
  TITLE: 'Sales Orders Allocation',
  DOWNLOAD: 'Export CSV',
  TITLE_MODAL: 'Revenue comparison from mm/dd/yyyy to mm/dd/yyyy',
  MESSAJE_MODAL_OVERRIDES: 'Maintain Pre-Existing Overrides',
  BUTTON_ALLOCATION: 'Coming soon',
  GLOBAL_ACTION: 'Run Global Data',
  VARIETY_ACTION: 'See Gaps by Variety',
  GAPS_ACTION: 'See Holiday Gaps',
  DEFAULT_SORT: 'See Default Sort',
  BUTTON_ACTIONS: 'Quick Actions',
  BUTTON_HIDE: 'Hide',
  BUTTON_EXPORT: 'Export',
  BUTTON_REVENUE: 'Revenue',
  BUTTON_SEARCH: 'Search',
};
