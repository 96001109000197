import React from "react";
import { Box, Paper } from "@mui/material";
import LocalFloristSharpIcon from '@mui/icons-material/LocalFloristSharp';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { ROUTES } from "@/shared/routes";
import { adminSettingsStyles as styles } from './AdminSettingItem.styles';
import { Matrix } from "@/shared/icons/Icons";

interface AdminSettingItemProps {
    itemId: number;
    title: string;
    subTitle: string;
    icon: any;
    path?: string;
    onClick: (route: string) => void;
}

const AdminSettingItem: React.FC<AdminSettingItemProps> = ({ itemId, title, subTitle, icon, path, onClick }) => {

    const iconMap = {
        "aging_by_variety": <LocalFloristSharpIcon sx={styles.icon} />,
        "allocation": <FormatListNumberedIcon sx={styles.icon} />,
        "substitution_matrix": <Matrix/>,
        "packing": <IndeterminateCheckBoxOutlinedIcon sx={styles.icon} />,
        "price": <LocalOfferIcon sx={styles.icon} />,
    }

    const routes = {
        "aging_by_variety": ROUTES.AGING_BY_VARIETY,
        "allocation_priorities": ROUTES.ALLOCATION_PRIORITIES,
        "substitution_matrix": ROUTES.SUBSTITUTION_MATRIX,
        "packing": ROUTES.ATP_SOURCE_DATA,
        "price": ROUTES.AVAILABLE_TO_PROMISE,
    }

    const getIcon = (icon: keyof typeof iconMap) => iconMap[icon];

    return (
        <Box
            key={`item-settings${itemId}`}
            data-testid={`item-settings${itemId}`}
            sx={styles.container}
            onClick={() => onClick(routes[path])}
        >
            <Paper
                key={`paper${itemId}`}
                sx={styles.paper} >
                <Box  >
                    {getIcon(icon)}
                </Box>
                <Box sx={styles.title}>
                    {title}
                </Box>
                <Box sx={{
                    fontWeight: "500",
                    margin: "20px 0",
                }}>
                    {subTitle}
                </Box>
            </Paper>
        </Box>
    )
}

export default AdminSettingItem;