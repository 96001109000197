import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, Typography } from "@mui/material";
import React from "react";
import { DragIcon } from "@/shared/icons/Icons";
import { theme } from '@/theme';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';


const DragAndDropItem = ({ index, variety, styles, handleRemoveVariety }: any) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: variety.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Box key={`category-${variety.id}`} ref={setNodeRef} sx={{ ...styles.varietyWrapper, ...style }}>
            <Box sx={{ padding: "0px 10px" }}>
                <Typography>{index + 1}</Typography>
            </Box>
            <Box sx={styles.varietyChip}>
                <Box sx={{
                    ...styles.colorDot, background: (variety.short_color.includes(",")
                        ? `linear-gradient(180deg, ${variety.short_color.split(',')[0]} 31.17%, ${variety.short_color.split(', ')[1]} 100%)`
                        : variety.short_color
                    ),
                    border: theme.custom.border,
                }} >
                </Box>
                <Box>
                    <Typography> {variety.variety}</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", marginLeft: "auto", marginRight: "10px" }}>
                    <DeleteOutlinedIcon sx={styles.icon} onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveVariety(variety.id)
                    }}
                    />
                    <Box {...attributes} {...listeners} sx={{ cursor: "pointer", marginTop: "-2px", color: theme.palette.primary.main }} data-testid="DragIcon">
                        <DragIcon />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default DragAndDropItem;