import React from 'react';
import { Box } from '@mui/material';
import { useHeaderState } from '../header/state/useHeaderState';

const Home = () => {
  const {} = useHeaderState();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
    >
      <img
        src="https://qa-atp-system.s3.amazonaws.com/images/Atlas-Rosaprima-Cayambe-Landscape-Banner.jpg"
        alt="Atlas Rosaprima Cayambe Landscape-Banner"
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      />
    </Box>
  );
};
export default Home;
