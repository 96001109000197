export const ACTION_PLAN = {
  TITLE: 'Action Plan',
  DOWNLOAD: 'Export',
  BUTTON_HIDE: 'Hide',
  BUTTON_SEARCH: 'Search',
  TOTAL: 'TOTAL',
  EDIT_ROTATION_DAYS: 'Rotation',
  WEEK_ACTION: 'See Last Week´s Plan',
  VARIETY_ACTION: 'See Gaps by Variety',
  PACKING_ACTION: 'Send Packing Team Alerts',
  ALERTS_ACTION: 'Send Sales Team Alerts',
  DEFAULT_SORT: 'See Default Sort',
  BUTTON_ACTIONS: 'Quick Actions',
  ACCTION_PLAN_VARIETY: "Action Plan by Variety",
  ACCTION_PLAN_CUSTOMER: "Action Plan by Customer",
  MIX: "MIX",
  TOTAL_AVAILABLE_MIX: "TOTAL AVAILABLE MIX",
  TOTAL_ATP: "TOTAL MIX",
};
