import { createNamedStyles } from '../../shared/interfaces/create_name_styles';
import { theme } from '../../theme';

export const sidebardSalesOrdersAllocationStyles = createNamedStyles({
  sidebarBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    textAlign: 'start',
    margin: '0px 20px 20px',
  },
  textActions: {
    fontWeight: 700,
    fontSize: '14px',
  },
  search: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#DCDCDE',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  box: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  text: {
    color: '#3E3E45',
    fontSize: '13px',
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: '#F4F2EF',
    ':hover': {
      color: theme.palette.primary.main,
    },
  },
});
