import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React, { useState } from 'react';
import DragAndDropItem from '../DragAndDropItem/DragAndDropItem';
import { Box, Typography } from '@mui/material';
import { IVariety } from '../state/useVarietySubstitutions.interfaces';

interface DragAndDropProps {
    varieties: IVariety[];
    rows: any;
    styles: any;
    setVarieties: React.Dispatch<React.SetStateAction<IVariety[]>>;
    setRows: any;
    handleRemoveVariety: (id: number) => void;
    setReadyToLeave: React.Dispatch<React.SetStateAction<boolean>>;
}


const DragAndDrop: React.FC<DragAndDropProps> = ({ rows, varieties, styles, handleRemoveVariety, setVarieties, setRows, setReadyToLeave }) => {

    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const oldIndex = varieties.findIndex((item) => item.id === active.id);
            const newIndex = varieties.findIndex((item) => item.id === over.id);
            setVarieties((varieties) => arrayMove(varieties, oldIndex, newIndex));

            const oldRow = rows[oldIndex];
            const newRow = rows[newIndex];
            setRows((rows: any) => {
                return rows.map((row: any, index: number) => {
                    if (index === oldIndex) {
                        return newRow;
                    } else if (index === newIndex) {
                        return oldRow;
                    }
                    return row;
                })
            });
            setReadyToLeave(false);
        }
    };

    return (
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={varieties} strategy={verticalListSortingStrategy}>
                {varieties.map((item, index) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', width: "100%", ...styles.varietyWrapper }} key={item.id}>
                        <DragAndDropItem index={index} variety={item} styles={styles} handleRemoveVariety={handleRemoveVariety} />
                    </Box>
                ))}
            </SortableContext>
        </DndContext>
    );
};

export default DragAndDrop;