import React, { useState, useRef, useEffect } from 'react';
import useVarietySubstitutions from './state/useVarietySubstitutions';
import { SubstitutionMatrixStyles as styles } from './VarietySubstitutions.styles';
import { Box, Typography, Table, TableBody, TableCell, Button, TableHead, TableRow } from '@mui/material';
import { SUBSTITUTION, MAX_SUBSTITUTIONS_VARIETIES } from '@/shared/constantes/VarietySubstitutions';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { theme } from '@/theme';
import DragAndDrop from './DragAndDrop/DragAndDrop';
import PushNotification from '@/components/PushNotifications/PushNotifications';
import { API_ROUTES } from '@/shared/api_routes';
import AutoCompleteComponent from '@/components/AutoComplete/Autocomplete';
import { IProductData } from '@/shared/interfaces/substitutions.interfaces';
import DialogPopup from '@/components/DialogPopup/DialogPopup';

interface VarietySubstitutions {
    productData: IProductData;
    setProductData: (data: IProductData | null) => void;
}

const VarietySubstitutionsComponent: React.FC<VarietySubstitutions> = ({ productData, setProductData }) => {

    const {
        gradeLimits,
        displayBackup,
        varitiesList,
        baseProductData,
        varieties,
        sizes,
        rows,
        openPushNotification,
        pushNotificationMessage,
        autoHideDuration,
        showUndoAction,
        showSearchNewVariety,
        inputValue,
        currentGrade,
        warning,
        changingGrade,
        handleSubmitSearch,
        setFile,
        setVarieties,
        setRows,
        handleRemoveVariety,
        handleApplyChanges,
        handleClickCell,
        setOpenPushNotification,
        setShowSearchNewVariety,
        setInputValue,
        handleUndoChanges,
        handleBackToMaster,
        setDisplayBackup,
        setWarning,
        handleChangeGrade,
        setReadyToLeave
    } = useVarietySubstitutions(productData, setProductData);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [autocompleteKey, setAutoCompleteKey] = useState<number>(0);

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.[0]) {
            setFile(event.target.files[0]);
        }
    };

    useEffect(() => {
        if (inputValue === '') {
            setAutoCompleteKey((pev) => pev + 1);
        }
    }, [inputValue])

    return (
        <>
            {warning && (
                <DialogPopup
                    content={warning}
                    onClose={() => {
                        if (changingGrade !== null) {
                            handleChangeGrade('stay')
                        }
                        setWarning('')
                    }}
                    onAgree={() => {
                        if (changingGrade === null) {
                            setProductData(null)
                        }else{
                            handleChangeGrade('forward')
                        }
                        setWarning('')
                    }}
                    labelClose={SUBSTITUTION.CLOSE}
                    labelAgree={SUBSTITUTION.AGREE}
                />
            )}
            <Box sx={styles.backBox}>
                <Box>
                    <Box sx={styles.text} onClick={() => {
                        handleBackToMaster()
                    }}>
                        <Typography>{SUBSTITUTION.BACK_TO_MASTER}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.box}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={styles.text}>
                        <Typography>{SUBSTITUTION.SUITABLE_REPLACEMENTS} {varieties.length}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ paddingX: "30px" }}>
                <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", paddingTop: "30px", columnGap: "20px" }}>
                    <Box data-testid='product-image' sx={styles.imgContainer} onClick={handleClick}>
                        {displayBackup ?
                            <ImageSearchIcon sx={styles.image} />
                            :
                            <img
                                src={API_ROUTES.IMAGES_REPOSITORY + "/" + productData.variety.replace(/ /g, '-') + ".png"}
                                alt="Variety name"
                                style={{ width: "110px", height: "auto" }}
                                onError={() => setDisplayBackup(true)}
                            />
                        }
                    </Box>
                    <input
                        type="file"
                        accept="image/png"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                    />
                    <Box>
                        <Typography>{SUBSTITUTION.CATEGORY}: {productData.category}</Typography>
                        <Typography>{SUBSTITUTION.VARIETY}: {productData.variety}</Typography>
                        <Typography>{SUBSTITUTION.COLOR}: {baseProductData.primary_color || productData.short_color}</Typography>
                        <Typography>{SUBSTITUTION.GRADE}: {currentGrade} cm</Typography>
                    </Box>
                </Box>

                <Box sx={{ paddingTop: "30px", display: "flex", flexDirection: "row", justifyContent: "space-between", columnGap: "30px" }}>
                    <Box sx={styles.varietiesContainer}>
                        <Box sx={styles.varietiesLabel}>
                            <Typography>{SUBSTITUTION.PRIORITY_ORDER}</Typography>
                        </Box>
                        <Box sx={styles.varietiesTitle}>
                            <Typography>{SUBSTITUTION.REPLACED_WITH}</Typography>
                        </Box>
                        <Box sx={styles.varietiesList}>
                            <DragAndDrop
                                varieties={varieties}
                                setVarieties={setVarieties}
                                rows={rows}
                                setRows={setRows}
                                styles={styles}
                                handleRemoveVariety={handleRemoveVariety}
                                setReadyToLeave={setReadyToLeave}
                            />
                            {varieties.length < MAX_SUBSTITUTIONS_VARIETIES && (

                                <Box sx={{ ...styles.varietyWrapper }}>
                                    <Box sx={{ padding: "0px 10px" }}>
                                        <Typography></Typography>
                                    </Box>
                                    <Box sx={{ marginLeft: "-25px", display: "flex", flexDirection: "row", columnGap: "10px", width: "100%" }}>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            {!showSearchNewVariety ?
                                                <AddCircleIcon sx={styles.icon} onClick={() => { setShowSearchNewVariety(true) }} />
                                                :
                                                <RemoveCircleIcon sx={styles.icon} onClick={() => {
                                                    setShowSearchNewVariety(false)
                                                    setInputValue('')
                                                }} />
                                            }
                                        </Box>
                                        <Box sx={{ cursor: "pointer", width: "100%", paddingLeft: "5px", display: showSearchNewVariety ? "block" : "none", }}>
                                            <AutoCompleteComponent
                                                key={autocompleteKey}
                                                inputValue={inputValue}
                                                setInputValue={setInputValue}
                                                label={SUBSTITUTION.SEARCH_NEW_VARIETY}
                                                options={varitiesList}
                                                onOptionSelect={(value) => {
                                                    handleSubmitSearch(value)
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            )
                            }
                        </Box>
                    </Box>
                    <Box sx={styles.matrixContainer}>
                        <Box sx={styles.tableLabel}>
                            <Typography>{SUBSTITUTION.MATRIX_LEGEND}</Typography>
                        </Box>
                        <Box sx={styles.matrixTable}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={styles.headerRow}>
                                        {sizes.map((size, index) => (
                                            <TableCell key={index} sx={{
                                                ...styles.headerCell,
                                                borderRight: index !== sizes.length - 1 ? theme.custom.border : "none",
                                                borderTopRightRadius: index === sizes.length - 1 ? `${theme.shape.borderRadius}px` : "0px",
                                                borderTopLeftRadius: index === 0 ? `${theme.shape.borderRadius}px` : "0px"
                                            }}>
                                                <Typography sx={{ fontWeight: "bold" }}>{size}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, rowIndex) => (
                                        <TableRow key={rowIndex} sx={{
                                            ...styles.dataRow, borderBottom: rowIndex === rows.length - 1 ? theme.custom.border : "none",
                                            borderBottomLeftRadius: rowIndex === rows.length - 1 ? `${theme.shape.borderRadius}px` : "0px",
                                            borderBottomRightRadius: rowIndex === rows.length - 1 ? `${theme.shape.borderRadius}px` : "0px"
                                        }}>
                                            {row.rows.map((cell, cellIndex) => (
                                                <TableCell
                                                    key={cellIndex}
                                                    sx={{
                                                        ...styles.dataCell,
                                                        borderBottom: rowIndex !== rows.length - 1 ? theme.custom.border : "none",
                                                        borderRight: cellIndex !== row.rows.length - 1 ? theme.custom.border : "none",
                                                        backgroundColor: gradeLimits && cell.grade && (cell.grade > gradeLimits[0] || cell.grade < gradeLimits[1]) ? theme.custom.disabledCell : theme.palette.background.paper
                                                    }}
                                                    onClick={() => handleClickCell(rowIndex, cellIndex)}
                                                >
                                                    <Typography>{cell.selected ? "X" : ""}</Typography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </Box>

                <Box sx={styles.butonContainer} >
                    <Button variant="contained" color="primary" disabled={!!!baseProductData.product_id || (openPushNotification && autoHideDuration === undefined)} onClick={() => handleApplyChanges()}>{SUBSTITUTION.APPLY_CHANGES}</Button>
                </Box>
            </Box>
            <PushNotification
                open={openPushNotification}
                setOpen={setOpenPushNotification}
                onClose={() => { setOpenPushNotification(false) }}
                message={pushNotificationMessage}
                autoHideDuration={autoHideDuration}
                showUndoAction={showUndoAction}
                undoAction={() => { handleUndoChanges() }}
            />
        </>
    );
}

export default VarietySubstitutionsComponent;