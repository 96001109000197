import { createNamedStyles } from '@/shared/interfaces/create_name_styles';

export const autocompleteStyles = createNamedStyles({
  input: {
    width: '100%',
    '& .MuiInputBase-root': {
      width: '100%',
      fontSize: '12px',
      '& .MuiInputBase-input': {
        width: '100%',
        padding: '2px',
      },
      '& .MuiFormLabel-root': {
        lineHeight: '12.5px',
      },
    },
    '& .MuiInputBase-input': {
      width: '100%',
    },
    '& .MuiFormLabel-root': {
      lineHeight: '12.5px',
    },
  },

  input_action_plan: {
    width: '100%',
    '& .MuiInputBase-root': {
      marginTop: '9px',
      width: '100%',
      fontSize: '13px',
      padding: '2px 4px 8px 0px',
      '& .MuiInputBase-input': {
        width: '100%',
        padding: '2px',
        fontFamily: 'Roboto, sans-serif',
      },
      '& .MuiFormLabel-root': {
        fontSize: '13px',
      },
      '& .MuiAutocomplete-endAdornment': {
        top: 'calc(40%)',
      },
    },
    '& .MuiInputBase-input': {
      width: '100%',
      fontSize: '13px',
      fontFamily: 'Roboto, sans-serif',
      margin: '0px',
    },
    '& .MuiFormLabel-root': {
      fontSize: '13px',
    },
    '& .css-1n9jdrt-MuiFormLabel-root-MuiInputLabel-root': {
      fontSize: '9px',
    },
    '&label+.css-z05uv8-MuiInputBase-root-MuiInput-roo': {
      marginTop: '12px',
    },
  },
});
