import React from 'react';
import Button from '@mui/material/Button';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { IPushNotification } from './PushNotifications.interface';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const PushNotification: React.FC<IPushNotification> = ({ autoHideDuration, onClose, message, open, setOpen, undoAction, showUndoAction }) => {

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose(event, reason);
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            {undoAction && showUndoAction &&
                <Button color="secondary" size="small" onClick={undoAction}>
                    Undo
                </Button>}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            message={message}
            action={action}
        />);
};

export default PushNotification;