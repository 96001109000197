export const GROUP_BY = {
    'farm_ship_date' : "Group by Next Farm Ship Date in Ascending Order",
    'category': "Group by Largest Affected Category",
    'color': "Group by Largest Affected Color Group",
    'variety': "Group by Largest Affected Variety",
    'grade': "Group by Largest Affected Grade in Descending Order",
}

export const COLUMN_STEM = {
    'missing_stems': "Missing Stems",
    'total_stems': "Total Stems",
    'allocated_stems': "Allocated Stems",
    'holiday_order': "Holiday Order"
}