export const ROUTES = {
  //Revenue Management section
  AVAILABLE_TO_PROMISE: '/revenue-management/available-to-promise',
  ACTION_PLAN: '/revenue-management/action-plan',
  REVENUE_MANAGEMENT: 'revenue-management',
  ATP_SOURCE_DATA: '/revenue-management/atp-source-data',

  //Admin Settings section
  ADMIN_SETTINGS: '/admin-settings',
  AGING_BY_VARIETY: '/admin-settings/aging-by-variety',
  ALLOCATION_PRIORITIES: '/admin-settings/allocation-priorities',
  SUBSTITUTION_MATRIX: '/admin-settings/substitution-matrix',
};
