import { useState } from 'react';
import {
  Revenues,
  UseIRenueveModalState,
} from './useRevenueModalState.interfaces';
import { useAppSelector } from '../../../store/hooks/storeHook';
import { RootState } from '../../../store/store';

export const useRevenueModalState = (): UseIRenueveModalState => {
  const { startDate, endDate, allocation } = useAppSelector(
    (store: RootState) => store.salesOrders
  );

  const revenues_initial: Revenues[] = [
    {
      id: '123',
      total: '545,560',
      description: 'Exception-based Allocation',
      check: true,
    },
    {
      id: '124',
      total: '600,236',
      description: 'Guidance-based Allocation',
      check: true,
    },
    {
      id: '125',
      total: '525,779',
      description: 'Order-based Allocation',
      check: true,
    },
  ];
  const [revenues, setRevenues] = useState(revenues_initial);
  const [isPreExistingOverrides, setIsPreExistingOverrides] = useState({});
  const handleChange = (id: string, event: boolean) =>
    setIsPreExistingOverrides({ ...isPreExistingOverrides, [id]: event });
  const handleActivateAllocation = (id: string) => {
    const firstElement = revenues.findIndex((item) => item.id === id);
    const itemToMove = revenues.splice(firstElement, 1)[0];
    setRevenues([itemToMove, ...revenues]);
  };
  return {
    allocation,
    startDate: startDate.toLocaleDateString(),
    endDate: endDate.toLocaleDateString(),
    revenues,
    isPreExistingOverrides,
    handleChange,
    handleActivateAllocation,
  };
};
