import React from 'react';
import Layout from '../../Layout/Layout';
import { AGING_BY_VARIETY } from '@/shared/constantes/AgingByVariety';
import SidebarAgingByVariety from '@/components/AdminSettings/AgingByVariety/SidebarAgingByVariety/SidebarAgingByVariety';
import AgingByVarietyTable from '@/components/AdminSettings/AgingByVariety/AgingByVarietyTable/AgingByVarietyTable';

const AgingByVariety = () => {
  return (
    <Layout 
      title={AGING_BY_VARIETY.TITLE} 
      sidebar={SidebarAgingByVariety}
      >
      <AgingByVarietyTable />
    </Layout>
  );
};

export default AgingByVariety;
