//First level breadcrumb menu
export enum MenuEnum {
  REVENUE_MANAGEMENT = 'Revenue Management',
  ADMIN_SETTINGS = 'Admin Settings',
  FORECAST_MANAGEMENT = 'Forecast Management',
  SALES_MANAGEMENT = 'Sales Management',
  WAREHOUSE_MANAGEMENT = 'Warehouse Management',
}

//Second level breadcrumb menus
export enum REVENUE_MANAGEMENT_MENU_ENUM {
  AVALIBLE_TO_PROMISE = 'Available To Promise',
  ATP_SOURCE_DATA = 'ATP Source Data',
  SALES_ORDERS_ALLOCATION = 'Sales Orders Allocation',
  ALLOCATION_EXCEPTIONS = 'Allocation Exceptions',
  ALLOCATION_PRIORITIES = 'Allocation Priorities',
  ACTION_PLAN = 'Action Plan'
}

export enum ADMIN_SETTINGS_MENU_ENUM {
  AGING_BY_VARIETY = 'Aging By Variety',
  ALLOCATION_PRIORITIES = 'Allocation Priorities',
  SUBSTITUTION_MATRIX = "Substitution Matrix",
  VARIETY_SUBSTITUTIONS = "Variety Substitutions"
}

export const MENU: { [key: string]: string } = {
  /*
  '/revenue-management/sales-order-allocation':
    REVENUE_MANAGEMENT_MENU_ENUM.SALES_ORDERS_ALLOCATION,
  */
  //Revenue Management section
  '/revenue-management': MenuEnum.REVENUE_MANAGEMENT,
  '/revenue-management/available-to-promise': REVENUE_MANAGEMENT_MENU_ENUM.AVALIBLE_TO_PROMISE,
  '/revenue-management/atp-source-data': REVENUE_MANAGEMENT_MENU_ENUM.ATP_SOURCE_DATA,
  '/revenue-management/action-plan': REVENUE_MANAGEMENT_MENU_ENUM.ACTION_PLAN,

  //Admin Settings section  
  '/admin-settings': MenuEnum.ADMIN_SETTINGS,
  '/admin-settings/aging-by-variety': ADMIN_SETTINGS_MENU_ENUM.AGING_BY_VARIETY,
  '/admin-settings/allocation-priorities': ADMIN_SETTINGS_MENU_ENUM.ALLOCATION_PRIORITIES,
  '/admin-settings/substitution-matrix': ADMIN_SETTINGS_MENU_ENUM.SUBSTITUTION_MATRIX,
  '/admin-settings/substitution-matrix/variety': ADMIN_SETTINGS_MENU_ENUM.VARIETY_SUBSTITUTIONS,
};
