import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../authConfig';
import { UseIHeaderState } from './useHeaderState.interfaces';

export const useHeaderState = (): UseIHeaderState => {
  const [open, setOpen] = useState(false);
  const { instance } = useMsal();
  const activeAccount: any = instance.getActiveAccount();

  const handleRedirect = () =>
    instance.loginRedirect({
      ...loginRequest,
      prompt: 'create',
    });

  const handleLogout = () => instance.logout();

  const handleOpenMenu = (value: boolean) => setOpen(value);

  return {
    open,
    activeAccount,
    handleRedirect,
    handleLogout,
    handleOpenMenu,
  };
};
