import { CONFIGURATIONS } from "@/shared/constantes/AdmingSettings"
import { DefaultValuesAllocationPriorities } from "@/shared/constantes/DefaultValuesAllocationPriorities"
import { useAppDispatch, useAppSelector } from "@/store/hooks/storeHook"
import { RootState } from "@/store/store"
import { getConfiguration, saveSettingsConfiguration } from "@/store/thunks/AllocationPriorities/allocationPriorities.thunks"
import { useEffect, useState } from "react"

interface IUseStateAlternativeVarietyProps {
    handleContinuesClick: () => void;
    setOpenAlert: (open: boolean) => void
}

const UseStateAlternativeVariety = ({ handleContinuesClick, setOpenAlert }: IUseStateAlternativeVarietyProps) => {
    const dispatch = useAppDispatch();
    const alternativeVarieties = useAppSelector((store: RootState) => store.allocationPriorities.settingsById[CONFIGURATIONS.ALTERNATIVE_VARIETY]);
    const [step, setStep] = useState<{ id: string; content: string }[]>([]);
    const stepDefault = DefaultValuesAllocationPriorities.alternativeVarietyMatch

    useEffect(() => {
        dispatch(getConfiguration({ id: CONFIGURATIONS.ALTERNATIVE_VARIETY }));
    }, [dispatch])

    useEffect(() => {
        if (alternativeVarieties) {
            setStep(alternativeVarieties.step ?
                alternativeVarieties.step.map((item: any, index: any) => ({ id: index.toString(), content: item })) :
                stepDefault.map((item, index) => ({ id: index.toString(), content: item })));
        }
    }, [alternativeVarieties])

    const handleSubmit = () => {
        dispatch(saveSettingsConfiguration({
            configurationId: CONFIGURATIONS.ALTERNATIVE_VARIETY,
            configuration: {
                step: step.map(item => item.content),
            }
        }));
        setOpenAlert(true);
        dispatch(getConfiguration({ id: CONFIGURATIONS.BUSINESS_RULES }));
    }

    const saveAndContinues = () => {
        handleSubmit();
        handleContinuesClick();
    }

    return {
        step,
        setStep,
        handleSubmit,
        saveAndContinues
    }

}
export default UseStateAlternativeVariety