import React from 'react';
import FilterComponent from '../Filters/FilterComponent';
import { UseSidebarAgingByVarietyState } from './state/UseSidebarAgingByVarietyState';
import { Box, Link, Typography } from '@mui/material';
import { sidebarAgingByVarietyStyle as styles } from './sidebarAgingByVariety.styles';
import { AGING_BY_VARIETY } from '../../shared/constantes/AgingByVariety';

const SidebarAgingByVariety = () => {
  const { filters, selectedValues, setSelectedValues } =
    UseSidebarAgingByVarietyState();
  return (
    <>
      <Box sx={styles.sidebarBox}>
        <Typography sx={styles.textActions}>
          {AGING_BY_VARIETY.BUTTON_ACTIONS}
        </Typography>
        <Box sx={styles.box}>
          <Link onClick={() => {}}>
            <Typography
              style={{
                cursor: 'not-allowed',
                color: '#ccc',
              }}
              sx={styles.text}
            >
              {' '}
              {AGING_BY_VARIETY.DISPOSAL_TRENDS}
            </Typography>
          </Link>
        </Box>
        <Box sx={styles.box}>
          <Link onClick={() => {}}>
            <Typography
              style={{
                cursor: 'not-allowed',
                color: '#ccc',
              }}
              sx={styles.text}
            >
              {AGING_BY_VARIETY.DISEASE_TRENDS}
            </Typography>
          </Link>
        </Box>
        <Box sx={styles.box}>
          <Link onClick={() => {}}>
            <Typography
              sx={styles.text}
              style={{
                cursor: 'not-allowed',
                color: '#ccc',
              }}
            >
              {AGING_BY_VARIETY.LATEST_UPDATES}
            </Typography>
          </Link>
        </Box>
      </Box>
      <FilterComponent
        data={filters}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        showHeader={true}
      ></FilterComponent>
    </>
  );
};
export default SidebarAgingByVariety;
