import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { theme } from '@/theme';
import Headboard from '@/components/Headboard/Headboard';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

const Item = ({ sx, ...other }: any) => (
  <Box sx={{ color: theme.palette.text.primary, ...sx }} {...other} />
);

const Layout = ({ title, sidebar: SidebarComponent, basePath, children }: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const toggleDrawer = () => setSidebarOpen(!sidebarOpen);

  return (
    <>
      <Headboard title={title} basePath={basePath} />
      <Box
        sx={{
          display: 'grid',
          gridAutoColumns: '1fr',
          height: '80vh',
          '@media (max-width: 600px)': { // Ajuste para pantallas pequeñas
            gridTemplateColumns: '1fr', // Para pantallas pequeñas, será de una sola columna
          }
        }}
      >
        {SidebarComponent && sidebarOpen ? (
          <Item
            sx={{
              gridRow: '1',
              gridColumn: 'span 2',
              backgroundColor: theme.palette.background.default,
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Grid item xs={12} md={12} sx={{ textAlign: 'end', margin: '2%' }}>
              <IconButton onClick={toggleDrawer}>
                <MenuOutlinedIcon />
              </IconButton>
            </Grid>
            {typeof SidebarComponent === "function" ? <SidebarComponent /> : SidebarComponent}
          </Item>
        ) : (
          SidebarComponent && (
            <Box sx={{ position: 'absolute', top: 150, left: 10, zIndex: 1000 }}>
              <IconButton onClick={toggleDrawer}>
                <MenuOutlinedIcon />
              </IconButton>
            </Box>
          )
        )}
        <Item sx={{ gridRow: '1', gridColumn: SidebarComponent ? 'span 10' : 'span 12', flexGrow: 1 }}>
          <Box sx={{ margin: SidebarComponent ? sidebarOpen && SidebarComponent ? '0px 20px' : '0px 30px 0px 60px' : '0px 20px' }}>
            {children}
          </Box>
        </Item>
      </Box>
    </>
  );
};

export default Layout;
