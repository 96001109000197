import { Box, Button } from "@mui/material";
import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import FilterChipItem from "../FilterChipItem/FilterChipItem";
import { useAppDispatch, useAppSelector } from "@/store/hooks/storeHook";
import { RootState } from "@/store/store";
import { ISelectedFiltersStore as ISelectedFiltersStoreSubstitution} from "@/store/interfaces/substitutionMatrix.interfaces";
import { ISelectedFiltersStore as ISelectedFiltersStoreAging} from "@/store/interfaces/agingByVariety.interfaces";

import { setSelectedFiltersStore as setSubstitutionFilters} from "@/store/actions/substitutionMatrix/substitutionMatrix";
import { setSelectedFiltersStore as setAgingFilters} from "@/store/actions/agingByVariety/agingByVariety.actions";

import { filterChipStyles as styles } from "./filterChip.styles";

type FilterStore = { [key: string]: string[] };

interface FilterChipProps {
    context?: "substitution" | "aging" | "atp";
    selectedValues?: FilterStore;
    handleDeleteChip?: (item: string, key: string) => void;
}

const FilterChip: React.FC<FilterChipProps> = React.memo(({ 
    context = "substitution",
    selectedValues,
    handleDeleteChip 
}) => {
    const dispatch = useAppDispatch();
    const substitutionFilters = useAppSelector((store: RootState) => store.substitutionMatrix.selectedFiltersStore) as unknown as FilterStore;
    const agingFilters = useAppSelector((state: RootState) => state.agingByVariety.selectedFiltersStore) as unknown as FilterStore;
    
    const scrollRef = useRef<HTMLDivElement>(null);
    const [showRightButton, setShowRightButton] = useState(false);

    const scrollLeft = useCallback(() => {
        scrollRef.current?.scrollBy({ left: -200, behavior: 'smooth' });
    }, []);

    const scrollRight = useCallback(() => {
        scrollRef.current?.scrollBy({ left: 200, behavior: 'smooth' });
    }, []);

    const checkScroll = useCallback(() => {
        if (scrollRef.current) {
            const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
            setShowRightButton(scrollWidth > clientWidth + scrollLeft);
        }
    }, []);

    const handleDelete = useCallback((item: string, key: string) => {
        if (handleDeleteChip) {
            handleDeleteChip(item, key);
            return;
        }

        if (context === "aging" && agingFilters) {
            const newFilters = {
                ...agingFilters,
                [key]: agingFilters[key]?.filter((chip: string) => chip !== item) || []
            };
            dispatch(setAgingFilters(newFilters));
        } else if (context === "substitution" && substitutionFilters) {
            const newFilters = {
                ...substitutionFilters,
                [key]: substitutionFilters[key]?.filter((chip: string) => chip !== item) || []
            };
            dispatch(setSubstitutionFilters(newFilters));
        }
    }, [context, agingFilters, substitutionFilters, handleDeleteChip, dispatch]);

    useEffect(() => {
        checkScroll();
        const currentRef = scrollRef.current;
        
        const debouncedCheckScroll = () => {
            let timeoutId: NodeJS.Timeout;
            return () => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(checkScroll, 100);
            };
        };

        const handleScroll = debouncedCheckScroll();
        currentRef?.addEventListener('scroll', handleScroll);
        
        return () => currentRef?.removeEventListener('scroll', handleScroll);
    }, [checkScroll]);

    const activeFilters = useMemo(() => {
        if (context === "atp" && selectedValues) return selectedValues;
        if (context === "aging") return agingFilters || {};
        if (context === "substitution") return substitutionFilters || {};
        return {};
    }, [context, selectedValues, agingFilters, substitutionFilters]);

    const filterItems = useMemo(() => {
        const items: JSX.Element[] = [];
        Object.entries(activeFilters).forEach(([key, values]) => {
            if (values?.length) {
                values.forEach((filter: string) => {
                    items.push(
                        <FilterChipItem
                            key={`${key}-${filter}`}
                            label={filter}
                            handleDelete={() => handleDelete(filter, key)}
                        />
                    );
                });
            }
        });
        return items;
    }, [activeFilters, handleDelete]);

    if (!filterItems.length) return null;

    return (
        <>
            {showRightButton && <Button sx={styles.button} onClick={scrollLeft}>{"<"}</Button>}
            <Box ref={scrollRef} sx={styles.chip}>
                {filterItems}
            </Box>
            {showRightButton && <Button sx={styles.button} onClick={scrollRight}>{">"}</Button>}
        </>
    );
});

FilterChip.displayName = 'FilterChip';

export default FilterChip;