import { useEffect, useState, useMemo } from 'react';
import { transformFiltersAging } from '@/store/utils/storeUtils';
import { UseIAgingByVariety } from './UseSidebarAgingByVariety.interfaces';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/store';
import { FiltersAging } from '@/store/interfaces/filtersAging.interface';
import { setSelectedFiltersStore } from '@/store/actions/agingByVariety/agingByVariety.actions';
import { ISelectedFiltersStore } from '@/store/interfaces/agingByVariety.interfaces';


export const UseSidebarAgingByVarietyState = (): UseIAgingByVariety => {
  const dispatch = useAppDispatch();
  const { aging } = useAppSelector((store: RootState) => store.agingByVariety);
  const selectedValues = useAppSelector((store: RootState) => store.agingByVariety.selectedFiltersStore);
  const setSelectedValues = (values: ISelectedFiltersStore) => {
          dispatch(setSelectedFiltersStore(values));
  };

  const [filterData, setFilterData] = useState<FiltersAging>({
        category: [],
        variety: [],
        short_color: [],
        product_rotation_day: [],
  });

  const generateFilterData = (aging: any): FiltersAging => {
    const data : FiltersAging = {
      category: Array.from(new Set(aging.map((item: { category: string; }) =>  item.category as string))),
      variety: Array.from(new Set(aging.map((item: { variety: string; }) => item.variety as string))),
      short_color: Array.from(new Set(aging.map((item: { short_color: string; }) => item.short_color as string))),
      product_rotation_day: Array.from(new Set(aging.map((item: { product_rotation_day: string; }) => String(item.product_rotation_day) as string))),
    }
    return data;
  };

  useEffect(() => {
    if(!filterData.variety.length && aging.length) {
      setFilterData(generateFilterData(aging));
    }
  }, [aging]);

  return {
    filters: transformFiltersAging(filterData),
    selectedValues,
    setSelectedValues,
  };
};
