import SizeSubstitution from './SizeSubstitution/SizeSubstitution';
import AlternativeVariety from './AlternativeVariety/AlternativeVariety';
import IdentifyGaps from './IdentifyGaps/IdentifyGaps';
import TruckDate from './TruckDate/TruckDate';
import CancellationRule from './CancellationRule/CancellationRule';

export const rulesSettings = [
  {
    id: 'identify_gaps',
    title: '1. Identify Gaps',
    description:
      'Detect missing varieties and prioritize shortages based on farm availability.',
    component: IdentifyGaps,
  },
  {
    id: 'prioritize_substitution',
    title: '2. Prioritize & Make a Recommended Size Substitution',
    description:
      "Find the same variety in an alternate size to complete a client's order.",
    component: SizeSubstitution,
  },
  {
    id: 'alternative_variety',
    title: '3. Find an Alternative Variety Match',
    description:
      "Find the best alternate variety to complete a client's order.",
    component: AlternativeVariety,
  },
  {
    id: 'postpone_truck_date',
    title: '4. Postpone the Truck Date',
    description: 'Choose whether to delay a product to a future truck date.',
    component: TruckDate,
  },
  {
    id: 'cancel_rule',
    title: '5. Manage Cancellation Rules',
    description:
      'Define when a product should be canceled, if no substitution or postponement is possible.',
    component: CancellationRule,
  },
];
