import { useCallback, useEffect, useRef, useState } from 'react';
import { UseIOrdersAllocationState } from './useActionPlanState.interfaces';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/interfaces/store.interfaces';
import { setCurrentPage, setSort } from '@/store/actions/actionPlan/actionPlan.actions';
import {
  getAllocation,
  downloadCsv,
  getAvailability
} from '@/store/thunks/ActionPlan/ActionPlan.thunks';
import { convertNumber } from '@/shared/utils/numbersUtils';
import { salesOrdersAllocationColumns } from '@/shared/constantes/SalesOrdersAllocationColumns';
import { SalesOrdersAllocation } from '@/store/interfaces/salesOrdersAllocation.interfaces';
import { formatDateandTime } from '@/shared/utils/dateUtils';
import { getConfiguration } from '@/store/thunks/AllocationPriorities/allocationPriorities.thunks';
import { CONFIGURATIONS } from '@/shared/constantes/AdmingSettings';

export const useActionPlanState = (): UseIOrdersAllocationState => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(true);
  const {
    startDate,
    endDate,
    sortBy,
    sortDirection,
    isFilterHGaps,
    filtersToSearch,
    isFilterGapsVariety,
    currentPage,
    allocation,
    totalPages,
    availability
  } = useAppSelector((store: RootState) => store.actionPlan);
  const {
    loading
  } = useAppSelector((store: RootState) => store.commonState);
  const businessRules = useAppSelector((store: RootState) => store.allocationPriorities.settingsById[CONFIGURATIONS.BUSINESS_RULES]);

  const [showSearchBar, setShowSearchBar] = useState(false);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [isExpandedAll, setExpandedAll] = useState(false);
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});
  const [isVisibleAvailabilityModal, setIsVisibleAvailabilityModal] = useState(false);
  const [itemAllocation, setItemAllocation] = useState<any>(null);
  const [inputValue, setInputValues] = useState<{ [key: number]: string }>({});

  const allocations = allocation.allocations
  const allocationPriority = businessRules?.allocationPriority
  const totalAllocations = allocation.totalAllocations
  const lastUpdate = allocation.lastAllocationDate

  let searchValue = useRef('');

  const salesOrdersColumns = salesOrdersAllocationColumns;

  const handleSort = (column: string) => {
    currentPage !== 1 && dispatch(setCurrentPage(1))
    const newDirection =
      sortBy === column && sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, newDirection }));
    dispatch(
      getAllocation({
        startDate,
        endDate,
        query: searchValue.current,
        missingStems: isFilterGapsVariety || isFilterHGaps,
        data: prepareData(),
        currentPage: 1,
        sortBy: column,
        sortDirection: newDirection
      })
    );
  };

  const handleShowSearchBar = () => setShowSearchBar(true);

  const handleShowBreakdowns = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number | null) => {
    if (event.ctrlKey) {
      setExpandedRow(index === expandedRow ? null : index);
      toggleRowExpansion(index, allocations)
    } else {
      setExpandedAll(!isExpandedAll)
      expandAllBreakdowns(index, allocations, isExpandedAll)
      setExpandedRow(null)
    }
  };

  const adjustedRowGetter = useCallback(({ index }: { index: number }) => {
    let offset = 0;
    for (let i = 0; i < allocations.length; i++) {
      const row = allocations[i];
      const expanded = expandedRows[i];
      if (index === offset) {

        return { ...row, isBreakdown: false, rowIndex: i };
      }
      if (expanded && index > offset && index <= offset + row.breakdowns.length) {

        return { ...row.breakdowns[index - offset - 1], isBreakdown: true };
      }
      offset += expanded ? row.breakdowns.length + 1 : 1;
    }
    return null;
  },
    [allocations, expandedRows]
  );


  const toggleRowExpansion = useCallback((index: any, allocations: any) => {
    setExpandedRows((prevExpandedRows = {}) => {
      const prevIndex: number = parseInt(Object.keys(prevExpandedRows)[0]);
      const isPrevRowExpanded = prevExpandedRows[prevIndex];
      if (prevIndex && index > prevIndex && isPrevRowExpanded) {
        const row = allocations[prevIndex];
        const breakdownsNumber = row.breakdowns.length;
        return { [index - breakdownsNumber]: true };
      }
      return { [index]: !prevExpandedRows[index] };
    });
  }, []);

  const adjustedRowCount = (allocations || []).reduce((count: number, row: any, index: any) => {
    const isExpanded = expandedRows[index] && row.breakdowns && row.breakdowns.length > 0;
    return count + (isExpanded ? row.breakdowns.length + 1 : 1);
  }, 0);

  const expandAllBreakdowns = useCallback((index: number | null, allocations: SalesOrdersAllocation[], isExpandedAll: boolean) => {
    setExpandedRows((prevExpandedRows = {}) => {
      if (isExpandedAll) {
        return {}
      } else {
        const allExpandedRows = { ...prevExpandedRows };
        allocations.forEach((row, i) => {
          if (row.boxComposition === "Assorted" && row.breakdowns.length > 0) {
            allExpandedRows[i] = true;
          }
        });
        return allExpandedRows;
      }
    });
  }, []);

  const prepareData = () => {
    let data;
    isFilterHGaps &&
      (data = {
        orderType: ['HO'],
      });
    return data = { ...data, ...filtersToSearch };
  }

  const handleDownloadCsv = () => {
    dispatch(
      downloadCsv({
        startDate,
        endDate,
        query: searchValue.current,
        missingStems: isFilterGapsVariety || isFilterHGaps,
        data: prepareData(),
        sortBy,
        sortDirection
      })
    );
  };

  const handleSearchValue = (data: string) => (searchValue.current = data);

  const getSalesOrderData = (page: number) => {
    dispatch(setCurrentPage(1))
    dispatch(
      getAllocation({
        startDate,
        endDate,
        query: searchValue.current,
        missingStems: isFilterGapsVariety || isFilterHGaps,
        data: prepareData(),
        currentPage: page,
        sortBy,
        sortDirection
      })
    );
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getSalesOrderData(1)
  }

  const handleRoundOut = (dato: any) =>
    convertNumber(dato != undefined ? dato.toFixed(2) : 0);

  const handleChangePage = (_event: any, newPage: number) => {
    getSalesOrderData(newPage)
    dispatch(setCurrentPage(newPage))
  }

  const handleOpenAvailability = (item: any) => {
    setIsVisibleAvailabilityModal(true);
    setItemAllocation(item);
    dispatch(getAvailability({ product: item.boxComposition === "Solid" ? item.breakdowns[0] : item }));
  }


  const handleInputChange = (key: number, event: any, rowData: any) => {
    setInputValues({
      ...inputValue,
      [key]: event
    });
    if (inputValue[key] && inputValue[key].length > 0 && inputValue[key].length === 1) {
      dispatch(getAvailability({ product: rowData.boxComposition === "Solid" ? rowData.breakdowns[0] : rowData }));
    }
  };

  useEffect(() => {
    dispatch(getConfiguration({ id: CONFIGURATIONS.BUSINESS_RULES }));
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const initialValues: { [key: number]: string } = {};
    for (let rowIndex = 0; rowIndex < adjustedRowCount; rowIndex++) {
      const rowData = adjustedRowGetter({ index: rowIndex });
      if (rowData) {
        initialValues[rowIndex] = rowData.productNameSubstitution || "";
      }
    }
    setInputValues((prevValues) => {
      const isEqual = Object.keys(initialValues).every(
        (key) => initialValues[Number(key)] === prevValues[Number(key)]
      );
      if (isEqual) {
        return prevValues;
      }
      return initialValues;
    });
  }, [adjustedRowCount, allocations, adjustedRowGetter]);


  useEffect(() => {
    if (filtersToSearch && Object.keys(filtersToSearch).length > 0) {
      if (isExpandedAll !== false) setExpandedAll(false);
      if (expandedRow !== null) setExpandedRow(null);
      if (Object.keys(expandedRows).length > 0) setExpandedRows({});
    }
  }, [filtersToSearch]);

  return {
    isExpandedAll,
    expandedRow,
    loading,
    sortBy,
    showSearchBar,
    visible,
    salesOrdersColumns,
    currentPage,
    adjustedRowCount,
    sortDirection,
    totalAllocations,
    totalPages,
    allocationPriority,
    date: lastUpdate && formatDateandTime(lastUpdate),
    isVisibleAvailabilityModal,
    itemAllocation,
    inputValue,
    availability,
    setIsVisibleAvailabilityModal,
    handleSort,
    handleShowSearchBar,
    handleShowBreakdowns,
    handleDownloadCsv,
    handleSubmit,
    handleSearchValue,
    handleRoundOut,
    adjustedRowGetter,
    handleChangePage,
    handleOpenAvailability,
    handleInputChange,
  };
};
