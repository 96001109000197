import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { headboardStyle as styles } from './Headboard.styles.ts';
import { IHeadboardProps } from './Headboard.interface.ts';



const Headboard: React.FC<IHeadboardProps> = ({ title, basePath }) => (
  <Box sx={{ margin: {
    xs: '80px 8px 50px 20px',
    md: '80px 8px 10px 20px',
  } }}>
    <Grid container>
      <Grid item xs={12} md={12}>
        <Breadcrumb basePath={basePath} />
        <Typography sx={styles.Title}>{title}</Typography>
      </Grid>
    </Grid>
  </Box>
);
export default Headboard;
