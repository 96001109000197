import { useEffect, useMemo, useRef, useState } from 'react';
import { UseIOrdersAllocationState } from './useSalesOrdersAllocationsState.interfaces';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/storeHook';
import { RootState } from '../../../store/interfaces/store.interfaces';
import { setSort } from '../../../store/actions/salesOrdersAllocation/salesOrdersAllocation.actions';
import {
  getSalesOrdersAllocation,
  downloadCsv,
} from '../../../store/thunks/SalesOrdersAllocation/SalesOrdersAllocation.thunks';
import { convertNumber, sortData } from '../../../shared/utils/numbersUtils';
import { salesOrdersAllocationColumns } from '../../../shared/constantes/SalesOrdersAllocationColumns';

export const UseSalesOrdersAllocationState = (): UseIOrdersAllocationState => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(true);
  const {
    startDate,
    endDate,
    loading,
    sortBy,
    sortDirection,
    isFilterHGaps,
    filtersToSearch,
    isFilterGapsVariety,
  } = useAppSelector((store: RootState) => store.salesOrders);
  const allocations = useAppSelector(
    (state) => state.salesOrders.allocation.allocations
  );
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [isExpandedAll, setExpandedAll] = useState(false);
  let searchValue = useRef('');
  const salesOrdersColumns = salesOrdersAllocationColumns;

  const handleFarmDate = (row: string) => {
    if (row) {
      const newDate = row.split('-');
      return `${newDate[1]}/${newDate[2]}`;
    }
    return '';
  };

  useEffect(() => {
    dispatch(getSalesOrdersAllocation({ startDate, endDate, dispatch }));
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleSort = (column) => {
    const newDirection =
      sortBy === column && sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, newDirection }));
  };

  const sortedAllocation = useMemo(() => {
    return sortData(allocations, sortBy, sortDirection);
  }, [allocations, sortBy, sortDirection]);

  const handleShowSearchBar = () => setShowSearchBar(true);

  const handleShowBreakdowns = (event, index) => {
    if (event.ctrlKey) {
      setExpandedRow(index === expandedRow ? null : index);
    } else {
      setExpandedAll(!isExpandedAll);
      !isExpandedAll && setExpandedRow(null);
    }
  };

  const handleDownloadCsv = () => {
    let data;
    isFilterHGaps &&
      (data = {
        orderType: ['HS'],
      });
    data = { ...data, ...filtersToSearch };
    dispatch(
      downloadCsv({
        startDate,
        endDate,
        query: searchValue.current,
        missingStems: isFilterGapsVariety || isFilterHGaps,
        data: data,
      })
    );
  };

  const handleSearchValue = (data) => (searchValue.current = data);

  const handleSubmit = (event) => {
    let data;
    isFilterHGaps &&
      (data = {
        orderType: ['HS'],
      });
    data = { ...data, ...filtersToSearch };
    event.preventDefault();
    dispatch(
      getSalesOrdersAllocation({
        startDate,
        endDate,
        dispatch,
        query: searchValue.current,
        missingStems: isFilterGapsVariety || isFilterHGaps,
        data: data,
      })
    );
  };

  const handleRoundOut = (dato) =>
    convertNumber(dato != undefined ? dato.toFixed(2) : 0);

  return {
    isExpandedAll,
    expandedRow,
    allocations: sortedAllocation,
    loading,
    sortBy,
    showSearchBar,
    visible,
    salesOrdersColumns,
    handleFarmDate,
    handleSort,
    handleShowSearchBar,
    handleShowBreakdowns,
    handleDownloadCsv,
    handleSubmit,
    handleSearchValue,
    handleRoundOut,
  };
};
