export const formatDate = (date: Date): string => {
  return date.toISOString().split('T', 1)[0];
};

export const handleFarmDay = (date: string): string => {
  const currenDay = new Date(date);
  const dayIndex = currenDay.getDay();
  const dayOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  return dayOfWeek[dayIndex];
};

export const handleWeekNumber = (date: string): number => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 4 - (newDate.getDay() || 1));
  const yearStart = new Date(newDate.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(
    ((newDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );
  return weekNumber;
};

const targetHours = [
  { hour: 4, minute: 15 }, // 4:15 AM
  { hour: 8, minute: 15 }, // 8:15 AM
  { hour: 12, minute: 15 }, // 12:15 PM
  { hour: 16, minute: 15 }, // 4:15 PM
  { hour: 20, minute: 15 }, // 8:15 PM
];

const getNextTargetTime = (): Date => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();
  let nextTarget = targetHours.find(
    ({ hour, minute }) =>
      hour > currentHour || (hour === currentHour && minute > currentMinute)
  );
  if (!nextTarget) {
    nextTarget = targetHours[0];
    currentTime.setDate(currentTime.getDate() + 1);
  }
  const targetTime = new Date(currentTime);
  targetTime.setHours(nextTarget.hour, nextTarget.minute, 0, 0);
  return targetTime;
};

export const handleRemainingTime = (): { hours: number; minutes: number } => {
  const targetTime = getNextTargetTime();
  const currentTime = new Date();
  const difference = targetTime.getTime() - currentTime.getTime();
  const totalMinutes = Math.floor(difference / (1000 * 60));
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
};

export const formatDateandTime = (dateString: string): string => {
  const [date, time] = dateString.split('T');
  const [year, month, day] = date.split('-');
  const formattedDate = `${month}/${day}/${year}`;
  const formattedTime = time ? time.split('.')[0] : '00:00:00';

  return `${formattedDate} - ${formattedTime}`;
};
