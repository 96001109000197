import { createNamedStyles } from '@/shared/interfaces/create_name_styles';

export const adminSettingsStyles = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    marginBottom: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#927C4E',
    margin: '20px 0',
  },
  icon: {
    color: '#927C4E',
    fontSize: 40,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > :not(style)': {
      m: 1,
      width: 298,
      height: 198,
    },
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  paper: {
    padding: '16px',
    textAlign: 'center',
    cursor: 'pointer',
    border: '1px solid #927C4E',
  },
});
