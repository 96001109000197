import { Box, Paper } from "@mui/material";
import React from "react";
import { cardStyles as styles } from "./Card.styles";

interface CardProps {
    title: string;
    description: string;
    isBackground?: boolean;
}

const Card: React.FC<CardProps> = ({ title, description, isBackground }) => {
    return (
        <Box
            key={`rule-${title}`}
            data-testid={`rule ${title}`}
            sx={styles.container}
            onClick={() => { }}
        >
            <Paper
                key={`paper${title}`}
                sx={styles.paper}
                style={{ background: isBackground ? "#EBE8E3" : "white" }}
            >
                <Box sx={styles.title}>
                    {title}
                </Box>
                <Box sx={{
                    margin: "5px 0",
                    fontSize: "12px",
                }}>
                    {description}
                </Box>
            </Paper>
        </Box>
    );
}

export default Card;