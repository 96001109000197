import { Theme, createTheme } from '@mui/material/styles';

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#927C4E',
    },
    secondary: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#5c5c5c',
    },
    background: {
      default: '#F4F2EF',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 12,
  },
});
