import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React from 'react';
import DragAndDropItem from '../DragAndDropItem/DragAndDropItem';
import { Box, Typography } from '@mui/material';

interface DragAndDropProps {
    title: string;
    items: { id: string; content: string }[];
    setItems: React.Dispatch<React.SetStateAction<{ id: string; content: string }[]>>;
}

const DragAndDrop: React.FC<DragAndDropProps> = ({ title, items, setItems }) => {

    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const oldIndex = items.findIndex((item) => item.id === active.id);
            const newIndex = items.findIndex((item) => item.id === over.id);
            setItems((items) => arrayMove(items, oldIndex, newIndex));
        }
    };

    return (
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                <Typography sx={{ fontWeight: "600", marginLeft: "30px", marginBottom: "5px" }}>{title}</Typography>
                {items.map((item, key) => (
                    <>
                        <Box key={item.id} sx={{ display: 'flex', alignItems: 'center' }}>
                            {key + 1}
                            <DragAndDropItem key={item.id} id={item.id} content={item.content} />
                        </Box>
                    </>
                ))}
            </SortableContext>
        </DndContext>
    );
};

export default DragAndDrop;