import { Box, Typography, Grid, Paper, Button } from '@mui/material';
import React, { useState } from 'react';
import { allocationPrioritiesStyles as styles } from './allocationPriorities.styles';
import { ALLOCATION_PRIORITIES } from '@/shared/constantes/AllocationPriorities';
import DragAndDrop from '@/components/DragAndDrop/DragAndDrop';
import UseAllocationPrioritiesState from './state/useAllocationPrioritiesState';
import Card from '@/components/Card/Card';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { formatDateandTime } from '@/shared/utils/dateUtils';
import PushNotification from '@/components/PushNotifications/PushNotifications';

export interface selectedCardInterface {
    id: string,
    title: string,
    description: string,
    component: React.FC<any>
}

const AllocationPrioritiesComponent = () => {

    const {
        customeGuidanceItems,
        setCustomeGuidanceItems,
        priceItems,
        setPriceItems,
        orderTypeItems,
        setOrderTypeItems,
        masterAllocationPriorityItems,
        setMasterAllocationPriorityItems,
        rulesSettings,
        handleCardClick,
        handleBackClick,
        handleContinuesClick,
        selectedCard,
        accCard,
        allocatioPrioritySettings,
        handleReEstablish,
        openAlert,
        setOpenAlert,
        notificationMessage,
        appVersion
    } = UseAllocationPrioritiesState();


    return (
        <>
            <Box sx={styles.box}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={styles.text}>
                        <Typography><span style={{ fontWeight: 700 }}>{ALLOCATION_PRIORITIES.BUSSINES_RULES}</span>{ALLOCATION_PRIORITIES.BUSSINES_RULES_DESCRIPTION}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Grid container sx={{ marginBottom: "80px", marginTop: "10px" }} >
                    <Grid item xs={12}>
                        <Box sx={styles.text}>
                            <Typography>{ALLOCATION_PRIORITIES.BUSSINES_RULES_INFORMATION}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container >
                            <Grid item xs={12} md={3}>
                                <Box sx={styles.drag}>
                                    <DragAndDrop title={ALLOCATION_PRIORITIES.CUSTOMER_GUIDANCE} items={customeGuidanceItems} setItems={setCustomeGuidanceItems} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box sx={styles.drag}>
                                    <DragAndDrop title={ALLOCATION_PRIORITIES.ORDER_TYPE} items={orderTypeItems} setItems={setOrderTypeItems} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box sx={styles.drag}>
                                    <DragAndDrop title={ALLOCATION_PRIORITIES.PRICE} items={priceItems} setItems={setPriceItems} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{
                                borderLeft: theme => ({
                                    xs: 'none',
                                    md: '1px solid #927C4E'
                                }),
                            }}>
                                <Box sx={styles.drag}>
                                    <DragAndDrop title={ALLOCATION_PRIORITIES.MASTER_ALLOCATION_PRIORITY} items={masterAllocationPriorityItems} setItems={setMasterAllocationPriorityItems} />
                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid >
            </Box>
            <Box sx={styles.box}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={styles.text}>
                        <Typography><span style={{ fontWeight: 700 }}>{ALLOCATION_PRIORITIES.SUBSTITUTION_RULES}</span>{ALLOCATION_PRIORITIES.SUBSTITUTION_RULES_DESCRIPTION}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Grid container sx={styles.boxRuleSettings} >
                    <Grid item xs={12}>
                        <Box sx={styles.text}>
                            <Typography>{ALLOCATION_PRIORITIES.SUBSTITUTION_RULES_INFORMATION}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid container >
                            {rulesSettings.map((item, index) => (
                                <Grid item xs={12} key={index} onClick={() => handleCardClick(item)} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                    <Box sx={{ width: { xs: 0, sm: 2 } }}>
                                        {selectedCard === item && (<PlayArrowIcon color='primary' fontSize='large' />)}
                                    </Box>
                                    <Card title={item.title} description={item.description} isBackground={selectedCard === item} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container >
                            {
                                selectedCard && (
                                    <Grid item xs={12} md={12}>
                                        <Box sx={styles.card} style={{ position: 'relative' }}>
                                            <Paper
                                                sx={styles.paper}
                                            >
                                                <Box >
                                                    <Typography><span style={{ fontWeight: 700, fontSize: "18px" }}>{selectedCard.title}</span></Typography>
                                                </Box>
                                                <Box sx={{ marginTop: 2 }}>
                                                    {selectedCard.component && <selectedCard.component card={accCard} handleBackClick={handleBackClick} handleContinuesClick={handleContinuesClick} setOpenAlert={setOpenAlert} />}
                                                </Box>
                                            </Paper>
                                        </Box>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <PushNotification
                            open={openAlert}
                            setOpen={setOpenAlert}
                            onClose={()=> setOpenAlert(false)}
                            message={notificationMessage ? notificationMessage : ALLOCATION_PRIORITIES.SUBSTITUTION_SAVE}
                            autoHideDuration={3000}
                        />
                    </Grid>
                </Grid >
            </Box>
            <Box>
                <Typography sx={styles.textVersionControl}>{ALLOCATION_PRIORITIES.VERSION_CONTROL}</Typography>
                <Box sx={{ marginTop: 2 }}>
                    <Typography sx={styles.textVersionControl}>{ALLOCATION_PRIORITIES.ID} {appVersion} <Button sx={styles.linkReEstablish} onClick={handleReEstablish}>Re-establish</Button></Typography>
                    <Typography sx={styles.textVersionControl}>{ALLOCATION_PRIORITIES.AUTHOR}{allocatioPrioritySettings && allocatioPrioritySettings.configuration_user}</Typography>
                    <Typography sx={styles.textVersionControl}>{ALLOCATION_PRIORITIES.DATE_LAST_MODIFIED}{allocatioPrioritySettings && formatDateandTime(allocatioPrioritySettings.configuration_last_update)}</Typography>
                </Box>
            </Box>
        </>
    );
}

export default AllocationPrioritiesComponent;