export const ALLOCATION_PRIORITIES = {
    TITLE: 'Allocation Priorities',
    BUSSINES_RULES: '1. Business Rule Settings: ',
    BUSSINES_RULES_DESCRIPTION: 'Customize and reorder variables and priorities used for master allocation.',
    BUSSINES_RULES_INFORMATION: 'Drag and drop cards to adjust the order in which inventory gaps are resolved.',
    CUSTOMER_GUIDANCE: 'Customer Guidance',
    ORDER_TYPE: 'Order Type',
    PRICE: 'Price',
    MASTER_ALLOCATION_PRIORITY: 'Allocation Priorities',
    SUBSTITUTION_RULES: '2. Substitution Rule Settings: ',
    SUBSTITUTION_RULES_DESCRIPTION: 'Adjust the IF-THEN statements to control the sequence in which gaps are resolved. Modifying these rules changes the order and logic of  allocation, ensuring the system prioritizes based on your preferences.',
    SUBSTITUTION_RULES_INFORMATION: 'Adjust variables within each module to reorder how ATLAS  solves for inventory gaps, following Rosaprima´s Business Rules.',
    VERSION_CONTROL: 'VERSION CONTROL',
    ID: 'ID: ',
    AUTHOR: 'Author: ',
    CREATED: 'Created On: ',
    DATE_LAST_MODIFIED: 'Date Last Modified: ',
    SUBSTITUTION_SAVE: 'Substitution Rule Settings saved. '
}

export const SUBSTITUTION_SIZE = {
    TITLE: "If there are sales orders with Missing Stems in the Action Plan,",
    SUB_TITLE: "Then ATLAS applies the following steps to find the best size substitution match from the Substitution Matrix:",
    TEXT: "can be substituted up or down by",
    IF: "If a valid match is found, Then add to the Action Plan as a recommended substitution. Else, proceed to ",
    THEN: "Find the Best Alternative Solution.",
    MATCH: " a valid match is found, ",
    ACTION_PLAN: " add to the Action Plan as a ",
    RECOMMENDED_SUBSTITUTION: "Recommended Substitution",
    SAVE: "Save Settings"
}

export const ALTERNATIVE_VARIETY = {
    TITLE: "If no replacement of the same variety is available in a different stem length,",
    DESCRIPTION: "Then ATLAS applies the following steps to find the best alternative match from the Substitution Matrix:",
    PRIMARY_CRITERIA: "Primary Substitution Criteria:",
    SECONDARY_CRITERIA: "Secondary Substitution Criteria:",
    CATEGORY: "Category",
    SIZE: "Size",
    TEXT: "Select the category and size of the alternative variety to be recommended",
    SAVE: "Save Settings",
    STEP: "Step",
    SUB_TEXT: "If a suitable replacement is found by the final step ATLAS will allocate it. Else, no substitution is made and ATLAS will recommend we ",
    POSTPONE: "Postpone the Truck Date.",
}

export const IDENTIFY_GAPS = {
    APPLY_BUSINESS_RULES: "Apply Business Rules",
    BUSINESS_RULES: " to each Priority below before sorting the Action Plan by the next priority in the list:",
    NOTE: "If the Action Plan was sucessfully created, Then ATLAS will proceed to: ",
    NOTE2: "for any sales orders with Missing Stems. ",
    RECOMMENDED: "Prioritize & Make a Recommended Size Substitution ",
    IF: "If",
    IS: "is",
    GROUP_BY: "Group By",
    PRIORIZE_SUBSTITUTION: "Prioritize Substitutions by Box"
}

export const POSTPONE_TRUCK_DATE = {
    TITLE: "If no alternative match is found by this stage, Then ATLAS evaluates future projection levels and the client’s next available Truck Date to find an alternative.",
    SUBTITLE: "If a new Truck Date has not been recommended or automatically applied through the control toggles, ATLAS proceeds to the ",
    SUBTITLE2: "Cancellation Rules module.",
    SAVE: "Save Settings"
}

export const CANCELLATION_RULE = {
    TITLE: "If no substitution or postponement is possible, ATLAS recommends canceling the product while ensuring that customers with higher priority are the last to be affected. Cancellations will be manually confirmed in the Action Plan, and ATLAS will update the Order Status accordingly.",
    SUBTITLE: "If a new Truck Date has not been recommended or automatically applied through the control toggles, ATLAS proceeds to the ",
    PROCESS: "End of process: ",
    SUBTITLE2: "ATLAS has completed the workflow. Please ensure you Apply & Activate your changes below before exiting.",
    SAVE: "Save Settings"
}

export const ENABLE_OPTIONS_SIZE_SUBSTITUTION = [
    {
        id: 1,
        content: "Enable exact variety size substitutions, that maximize sales order profit margin.",
        enable: true,
        type: "Radio"
    },
    {
        id: 1,
        content: "Enable exact variety size substitutions,  from size with the highest available inventory.",
        enable: true,
        type: "Radio"
    },
    {
        id: 1,
        content: "Enable exact variety size substitutions, based on known customer preferences.",
        enable: true,
        type: "Switch"
    },
    {
        id: 1,
        content: "Enable exact variety, match recommendations from future reservations.",
        enable: true,
        type: "Switch"
    },
]

export const VARIETY_SUBSTITUTION_OPTIONS = [{
    id: 1,
    content: "Enable variety substitutions, that maximize sales order profit margin.",
    enable: true,
    type: "Radio"
},
{
    id: 1,
    content: "Enable exact variety size substitutions,  from variey with the highest available inventory.",
    enable: true,
    type: "Radio"
},
{
    id: 1,
    content: "Enable variety substitutions based on known customer preferences.",
    enable: true,
    type: "Switch"
}
]

export const ATLAS_POSTPONEMENT_OPTIONS = [{
    id: 1,
    content: "Enable ATLAS to recommend postponement to the next available Truck Date.",
    enable: true
},
{
    id: 1,
    content: "Enable ATLAS to postpone delivery to the next available Truck Date (without manual confirmation).",
    enable: true
}
]

export const ATLAS_CANCELLATION_OPTIONS = [{
    id: 1,
    content: "Enable ATLAS to recommend automatic sales order cancellations (manual confirmation required).",
    enable: true
},
{
    id: 1,
    content: "Enable ATLAS to perform automatic sales order cancellation (without manual confirmation).",
    enable: true
}
]