import { CONFIGURATIONS } from "@/shared/constantes/AdmingSettings"
import { GROUP_BY, COLUMN_STEM } from "@/shared/constantes/IdentifyGaps"
import { useAppDispatch, useAppSelector } from "@/store/hooks/storeHook"
import { RootState } from "@/store/store"
import { getConfiguration, saveSettingsConfiguration } from "@/store/thunks/AllocationPriorities/allocationPriorities.thunks"
import { useEffect, useState } from "react"
interface IUseStateIdentifyGapsProps {
    handleContinuesClick: () => void,
    setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>
}
const UseStateIdentifyGaps = ({ handleContinuesClick, setOpenAlert }: IUseStateIdentifyGapsProps) => {
    const dispatch = useAppDispatch();
    const idenfyGaps = useAppSelector((store: RootState) => store.allocationPriorities.settingsById[CONFIGURATIONS.IDENTIFY_GAPS])
    const [stemColumn, setStemColumn] = useState("");
    const [conditional, setConditional] = useState<string>("");
    const [value, setValue] = useState<number>(0);
    const [groupBy, setGroupBy] = useState<{ id: string; content: string }[]>([]);

    useEffect(() => {
        dispatch(getConfiguration({ id: CONFIGURATIONS.IDENTIFY_GAPS }));
    }, [])

    useEffect(() => {
        if (idenfyGaps) {
            setStemColumn(idenfyGaps.column);
            setConditional(idenfyGaps.conditional);
            setValue(idenfyGaps.value);
            idenfyGaps.groupBy.forEach((item: keyof typeof GROUP_BY) => {
                setGroupBy(prevState => {
                    if (!prevState.some(group => group.id === item)) {
                        return [...prevState, { id: item, content: GROUP_BY[item] }];
                    }
                    return prevState;
                });
            });
        }
    }, [idenfyGaps])

    const handleStemChange = (event: React.ChangeEvent<HTMLInputElement>) => setStemColumn(event.target.value);
    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => setValue(Number(event.target.value));
    const handleChangeConditional = (event: React.ChangeEvent<HTMLInputElement>) => setConditional(event.target.value);

    const handleSaveSettings = async () => {
        dispatch(saveSettingsConfiguration({
            configurationId: CONFIGURATIONS.IDENTIFY_GAPS,
            configuration: {
                groupBy: groupBy.map(item => item.id)
            }
        }))
        await dispatch(getConfiguration({ id: CONFIGURATIONS.BUSINESS_RULES }));
        setOpenAlert(true);
    }

    const saveAndContinues = () => {
        handleSaveSettings();
        handleContinuesClick();
    }

    return {
        stemColumn,
        conditional,
        value,
        groupBy,
        handleStemChange,
        handleChangeValue,
        setGroupBy,
        handleChangeConditional,
        handleSaveSettings,
        saveAndContinues
    }
}
export default UseStateIdentifyGaps