import React, { forwardRef, useImperativeHandle, useState } from 'react';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { Popover } from '@mui/material';
interface PopoverComponentProps {
  children: React.ReactNode;
  title: string;
  icon: React.ReactNode;
}

const PopoverComponent = forwardRef(
  ({ children, title, icon }: PopoverComponentProps, ref) => {
    const [parentAnchorComponent, setParentAnchorComponent] = useState(null);
    const open = Boolean(parentAnchorComponent);

    const handleClick = (event: any) => {

      setParentAnchorComponent(event.currentTarget);

    };
    const handleClose = () => {
      setParentAnchorComponent(null);
    };

    useImperativeHandle(ref, () => ({
      handleClose,
    }));

    return (
      <>
        <TooltipIcon title={title} icon={icon} handleClick={handleClick} />
        <Popover
          open={open}
          anchorEl={parentAnchorComponent}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {children}
        </Popover>
      </>
    );
  }
);
export default PopoverComponent;
