import { createNamedStyles } from '@/shared/interfaces/create_name_styles';

export const dragAndDropItemStyles = createNamedStyles({
  box: {
    padding: '12px',
    margin: '4px 4px 4px 20px',
    backgroundColor: '#ECEFF1',
    borderRadius: '5px',
    width: '70%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '13px',
  },
});
