/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Allocation } from '../../interfaces/allocation.interfaces';
import { FiltersAllocationData } from '../../interfaces/filtersAllocation.interfaces';
import { API_ROUTES } from '../../../shared/api_routes';
import axios from '../../../shared/axios-utils';
import { setLoading } from '@/store/actions/commonState/commonState.actions';
import { formatDate } from '@/shared/utils/dateUtils';

const getAllocation = createAsyncThunk<
  any,
  {
    startDate: Date;
    endDate: Date;
    query?: string;
    missingStems?: boolean;
    data?: object;
    currentPage: number;
    sortBy?: string | null;
    sortDirection?: string;
  }
>(
  'actionPlan/getAllocation',
  async (
    {
      startDate,
      endDate,
      query,
      missingStems,
      data,
      currentPage,
      sortBy,
      sortDirection,
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      let newData;
      const start_date = startDate.toLocaleDateString('es-ES');
      const end_date = endDate.toLocaleDateString('es-ES');
      let url = `${API_ROUTES.API}/revenue-management/allocation?start_date=${start_date}&end_date=${end_date}&page=${currentPage}`;
      if (query) {
        url += `&search_query=${query}`;
      }
      if (missingStems) {
        url += '&missing_stems=true';
      }
      if (sortBy && sortDirection) {
        url += `&sort_by=${sortBy}&sort_direction=${sortDirection}`;
      }
      if (data) {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        newData = Object.fromEntries(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          Object.entries(data).filter(([_, valor]) => {
            return !(Array.isArray(valor) && valor.length === 0);
          })
        );
        /* eslint-enable @typescript-eslint/no-unused-vars */
      }
      const response = await axios.post<Allocation>(url, data && newData);
      thunkAPI.dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      console.error('Error fetching sales orders allocation:', error);
      thunkAPI.dispatch(setLoading(false));
      throw error;
    }
  }
);

const getFiltersAllocationData = createAsyncThunk<
  FiltersAllocationData,
  { startDate: Date; endDate: Date }
>('actionPlan/getFiltersAllocationData', async ({ startDate, endDate }) => {
  try {
    const start_date = startDate.toLocaleDateString('es-ES');
    const end_date = endDate.toLocaleDateString('es-ES');
    const response = await axios.get<FiltersAllocationData>(
      `${API_ROUTES.API}/revenue-management/allocation/filters?start_date=${start_date}&end_date=${end_date}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching filters');
    throw error;
  }
});

const downloadCsv = createAsyncThunk<
  any,
  {
    startDate: Date;
    endDate: Date;
    query?: string;
    missingStems?: boolean;
    data?: object;
    sortBy?: string | null;
    sortDirection?: string;
  }
>(
  'actionPlan/downloadCsv',
  async ({
    startDate,
    endDate,
    query,
    missingStems,
    data,
    sortBy,
    sortDirection,
  }) => {
    try {
      let newData;
      const start_date = startDate.toLocaleDateString('es-ES');
      const end_date = endDate.toLocaleDateString('es-ES');
      const csv = '&csv=true';
      const date = new Date().toLocaleDateString();
      let url = `${API_ROUTES.API}/revenue-management/allocation?start_date=${start_date}&end_date=${end_date}${csv}`;
      if (query) {
        url += `&search_query=${query}`;
      }
      if (missingStems) {
        url += '&missing_stems=true';
      }
      if (sortBy && sortDirection) {
        url += `&sort_by=${sortBy}&sort_direction=${sortDirection}`;
      }
      /* eslint-disable @typescript-eslint/no-unused-vars */
      if (data) {
        newData = Object.fromEntries(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          Object.entries(data).filter(([_, valor]) => {
            return !(Array.isArray(valor) && valor.length === 0);
          })
        );
      }
      /* eslint-enable @typescript-eslint/no-unused-vars */
      const response = await axios.post<any>(url, data && newData);
      const csvContent = response.data.csvContent;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url2 = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url2;
      a.download = `action_plan_${date}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error download sales orders allocation file:', error);
      throw error;
    }
  }
);

const getAvailability = createAsyncThunk<any, { product: any }>(
  'actionPlan/getAvailability',
  async ({ product }) => {
    try {
      const bodyRequest = {
        filterObject: {
          productId: product.productId,
          date: formatDate(new Date(product.farmDate)),
          stemBunch: product.stemBunch,
        },
      };
      const url = `${API_ROUTES.API}/revenue-management/availability`;
      const response = await axios.post<any>(url, bodyRequest);
      return response.data;
    } catch (error) {
      console.error('Error fetching availability:', error);
      throw error;
    }
  }
);

export {
  getAllocation,
  getFiltersAllocationData,
  downloadCsv,
  getAvailability,
};
