export const CUSTOME_GUIDANCE: Record<string, string> = {
    'SG': "SG - Super Growth",
    "GW": "GW - Growth",
    "MG": "MG - Manage ",
    "PO": "PO - Path of Least Efforts",
    "R3": "R3  - Reduce, Re - use, Replace",
    "EX": "EX - Exit"
}

export const PRICE: Record<string, string> = {
    "apslfd": "APS LFD - Highest to Lowest"
}

export const ALLOCATION_PRIORITIES: Record<string, string> = {
    "so.guidance": "Customer Guidance",
    "sod.atlas_order_type": "Order Type",
    "sod.apslfd": "Price"
}

export const ORDER_TYPE: Record<string, string> = {
    "SO": "SO - Standing Order",
    "HO": "HO - Holiday Order",
    "FS": "FS - Future Sale ",
    "D": "D - Doubles",
    "OM": "OM - Open Market"
}