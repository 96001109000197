import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../../actions/commonState/commonState.actions';
import { AgingByVariety } from '../../interfaces/aging.interfaces';
import { API_ROUTES } from '@/shared/api_routes';
import axios from '@/shared/axios-utils';

const getAging = createAsyncThunk<
  AgingByVariety[]
  >('variety/getAging', async (any, thunkAPI) => {
  try {
    thunkAPI.dispatch(setLoading(true));

    let url = `${API_ROUTES.API}/revenue-management/variety/details`;
    const response = await axios.get<{data: AgingByVariety[]}>(url, { withCredentials: true });

    return response.data.data;
  } catch (error) {
    console.error('Error fetching aging by variety data:', error);
    throw error;
  }
  finally {
    thunkAPI.dispatch(setLoading(false));
  }
});

const updateAging = createAsyncThunk<
  boolean,
  {body: any }
>('variety/aging-by-variety', async ({ body }, thunkAPI) => {
  try {
    thunkAPI.dispatch(setLoading(true));

    let url = `${API_ROUTES.API}/revenue-management/variety/aging-by-variety`;
    const response = await axios.post<{success: boolean}>(url, body, { withCredentials: true });

    if(response.data.success){
      await thunkAPI.dispatch(getAging());
    }
    return response.data.success;
  } catch (error) {
    console.error('Error updating aging by variety:', error);
    throw error;
  }
  finally {
    
    thunkAPI.dispatch(setLoading(false));
  }
});

export { getAging, updateAging };
