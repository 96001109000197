import 'react-datepicker/dist/react-datepicker.css';
import {
  setCurrentPage,
  setEndDate,
  setFilterGapsByVariety,
  setFilterHolidayGaps,
  setFiltersToSearch,
  setSort,
  setStartDate,
} from '@/store/actions/actionPlan/actionPlan.actions';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/interfaces/store.interfaces';
import { UseISalesOrdersAllocationState } from './useSidebarActionPlan.interfaces';
import { useEffect, useRef, useState } from 'react';
import { transformFilters } from '@/store/utils/storeUtils';
import {
  getFiltersAllocationData,
  getAllocation,
} from '@/store/thunks/ActionPlan/ActionPlan.thunks';

export const UseSidebarSalesOrdersAllocationState =
  (): UseISalesOrdersAllocationState => {
    const dispatch = useAppDispatch();
    const isFirstRender = useRef(true);
    const {
      startDate,
      endDate,
      isFilterGapsVariety,
      isFilterHGaps,
      filters,
      currentPage,
      filtersToSearch,
      sortBy,
      sortDirection
    } = useAppSelector((store: RootState) => store.actionPlan);
    const [selectedValues, setSelectedValues] = useState({});

    const handleSetStartDate = (startDate: Date) => dispatch(setStartDate(startDate));

    const handleAllocation = () => {
      let data;
      isFilterHGaps &&
        (data = {
          orderType: ['HO'],
        });
      data = { ...data, ...selectedValues };
      currentPage !== 1 && dispatch(setCurrentPage(1));
      dispatch(
        getAllocation({
          startDate,
          endDate,
          data: data,
          missingStems: isFilterGapsVariety || isFilterHGaps,
          currentPage: 1
        })
      );
      dispatch(getFiltersAllocationData({ startDate, endDate }));
    };

    const handleGapsByVariety = (isDeleteFilter?: any) => {
      currentPage !== 1 && dispatch(setCurrentPage(1));
      dispatch(
        getAllocation({
          startDate,
          endDate,
          missingStems: !isDeleteFilter,
          currentPage: 1,
          data: { ...selectedValues}
        })
      );
      dispatch(setFilterGapsByVariety(!isDeleteFilter));
      dispatch(setFilterHolidayGaps(false));
    }

    const handleHolidayGaps = (isDeleteFilter?: any) => {
      const data = {
        orderType: ['HO'],
      };
      currentPage !== 1 && dispatch(setCurrentPage(1));
      dispatch(
        getAllocation({
          startDate,
          endDate,
          data: isDeleteFilter ? { ...selectedValues } : { ...data, ...selectedValues },
          missingStems: !isDeleteFilter,
          currentPage: 1
        })
      );
      dispatch(setFilterHolidayGaps(!isDeleteFilter));
      dispatch(setFilterGapsByVariety(false));
    }

    const handleSetEndDate = (endDate: Date) => dispatch(setEndDate(endDate));

    const handleDefaultSort = () => {
      currentPage !== 1 && dispatch(setCurrentPage(1));
      dispatch(setSort({ column: null, newDirection: 'asc' }));
      dispatch(
        getAllocation({
          startDate,
          endDate,
          currentPage: 1
        })
      );
    };

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      let data;
      isFilterHGaps &&
        (data = {
          orderType: ['HO'],
        });
      data = { ...data, ...selectedValues };
      currentPage !== 1 && dispatch(setCurrentPage(1));
      dispatch(getAllocation({
        startDate,
        endDate,
        data: data,
        missingStems: isFilterGapsVariety || isFilterHGaps,
        currentPage: 1,
        sortBy: sortBy,
        sortDirection: sortDirection
      }))
      dispatch(setFiltersToSearch(selectedValues));
    }, [selectedValues]);

    useEffect(() => {
      setSelectedValues(filtersToSearch);
    }, []);

    return {
      filters: transformFilters(filters),
      endDate,
      startDate,
      isFilterGapsByVariety: isFilterGapsVariety,
      isFilterHGaps,
      selectedValues,
      handleSetStartDate,
      handleAllocation,
      handleSetEndDate,
      setSelectedValues,
      handleDefaultSort,
      handleHolidayGaps,
      handleGapsByVariety
    };
  };
