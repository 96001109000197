import React from 'react';
import { Link, Typography } from '@mui/material';
import { MENU } from '../../shared/constantes/MenuEnum';
import { Link as RouterLink, useLocation } from 'react-router-dom';



const Breadcrumb = () => {
  const location = useLocation();
  const pathNames = location.pathname.split('/').filter((x) => x);

  return (
    <nav aria-label="breadcrumb">
      <ol style={{
        listStyle: 'none',
        display: 'flex',
        gap: '8px',
        padding: '0',
        margin: '0',
      }}>
        <li >
          <Link
            component={RouterLink}
            to="/"
            style={{ color: 'gray', textDecoration: 'none' }}><Typography key="home">Home</Typography></Link>
        </li>
        {pathNames.map((value, index) => {
          const path = `/${pathNames.slice(0, index + 1).join('/')}`;
          return (
            <li key={path}  >
              {index === pathNames.length - 1 ? (
                <Typography key={path}><span style={{ color: 'gray', textDecoration: 'none' }}>/ {MENU[path as keyof typeof MENU]}</span></Typography>
              ) : (
                <Link
                  component={RouterLink}
                  to={path} style={{ color: 'gray', textDecoration: 'none' }}><Typography key="3">/ {MENU[path as keyof typeof MENU]} </Typography></Link>
              )}
            </li>
          )
        })
        }
      </ol>
    </nav>
  )
};

export default Breadcrumb;


