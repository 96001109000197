import SidebarSubstitutionMatrix from "@/components/AdminSettings/SubstitutionMatrix/SidebarSubstitutionMatrix/SidebarSubstitutionMatrix";
import SubstitutionMatrixTable from "@/components/AdminSettings/SubstitutionMatrix/SubstitutionMatrixTable/SubstitutionMatrixTable";
import VarietySubstitutionsComponent from "@/components/AdminSettings/SubstitutionMatrix/VarietySubstitutions/VarietySubstitutions";
import Layout from "@/containers/Layout/Layout";
import { SUBSTITUTION_MATRIX } from "@/shared/constantes/SubstitutionMatrix";
import { IProductData } from "@/shared/interfaces/substitutions.interfaces";
import { setSelectedFiltersStore } from "@/store/actions/substitutionMatrix/substitutionMatrix";
import { useAppDispatch } from "@/store/hooks/storeHook";
import { initialState } from "@/store/reducers/substitutionMatrix.slice";
import React, { useEffect, useState } from "react";

const SubstitutionMatrix = () => {

    const [productData, setProductData] = useState<IProductData | null>(null);

    const dispatch = useAppDispatch()
    useEffect(() => {
      return () => {
        dispatch(setSelectedFiltersStore({ ...initialState.selectedFiltersStore }))
      }
    }, []);
    
    return (
        <Layout
            title={SUBSTITUTION_MATRIX.TITLE}
            sidebar={() => <SidebarSubstitutionMatrix productData={productData} />}
        >
            {productData ?
                <VarietySubstitutionsComponent setProductData={setProductData} productData={productData} />
                :
                <SubstitutionMatrixTable setProductData={setProductData} />
            }
        </Layout>
    )

}
export default SubstitutionMatrix;