import { createNamedStyles } from '@/shared/interfaces/create_name_styles';
import { theme } from '@/theme';

export const headerStyles = createNamedStyles({
  headerBar: {
    background: theme.palette.primary.main,
    zIndex: '1300',
    position: 'fixed',
    top: 0,
    height: '62px',
  },
  headerToolbar: {
    display: 'flex',
    paddingLeft: '0 !important',
  },
  headerIcon: {
    display: { xs: 'none', md: 'flex' },
    mr: 1,
    width: '140px',
    height: '50px',
  },
  headerMenu: {
    top: '59px !important',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  headerBSignIn: {
    color: 'white',
    border: '1px #000040',
    marginLeft: 'auto',
  },
  headerLogos: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    cursor: 'pointer',
  },
  headerAlign: {
    textAlign: 'end',
    alignContent: 'center',
  },
});
