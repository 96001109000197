import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { UseIsActionPlanState } from './UseActionPlanState.interfaces';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { getFiltersAllocationData } from '@/store/thunks/ActionPlan/ActionPlan.thunks';
import { RootState } from '@/store/store';

export const UseActionPlanState =
  (): UseIsActionPlanState => {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch()
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const {
      startDate,
      endDate,
    } = useAppSelector((store: RootState) => store.actionPlan);
    
    useEffect(()=>{
      dispatch(getFiltersAllocationData({ startDate, endDate }));
    },[])

    return {
      openModal,
      handleCloseModal,
      handleOpenModal,
    };
  };
