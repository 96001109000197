import { FiltersATP } from '../interfaces/filtersATP.interfaces';
import { FiltersAging, IAging } from '../interfaces/filtersAging.interface';
import { FiltersAllocationData } from '../interfaces/filtersAllocation.interfaces';
import { ITransformFilters } from '../interfaces/store.interfaces';
import inventoryData from '@/shared/data/inventory_type.json';
import accumulateInventoryTypesData from '@/shared/data/inventory_type_accumulated.json';

//Allocation orders
export const transformFilters = (
  data: FiltersAllocationData
): ITransformFilters[] => {
  return [
    {
      id: 'accountName',
      filtername: 'Account Name',
      componentType: 'Checkbox',
      valueOptions: [...(data.accountName || [])].sort(),
    },
    {
      id: 'market',
      filtername: 'Market',
      componentType: 'Checkbox',
      valueOptions: [...(data.market || [])].sort(),
    },
    {
      id: 'guidance',
      filtername: 'Guidance',
      componentType: 'Checkbox',
      valueOptions: [...(data.guidance || [])].sort(),
    },
    {
      id: 'accountOwner',
      filtername: 'Account Owner',
      componentType: 'Checkbox',
      valueOptions: [...(data.accountOwner || [])].sort(),
    },
    {
      id: 'orderType',
      filtername: 'Order Type',
      componentType: 'Checkbox',
      valueOptions: [...(data.orderType || [])].sort(),
    },
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: [...(data.category || [])].sort(),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: [...(data.variety || [])].sort(),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: [...(data.color || [])].sort(),
    },
    {
      id: 'grade',
      filtername: 'Grade',
      componentType: 'Checkbox',
      valueOptions: [...(data.grade || [])].sort(),
    },
    {
      id: 'boxComposition',
      filtername: 'Assorted / Solid',
      componentType: 'Checkbox',
      valueOptions: [...(data.boxComposition || [])].sort(),
    },
  ];
};

export const transformFiltersATp = (
  data: FiltersATP,
  isAccumulate: boolean,
  groupResultValue: string[]
) => {
  const inventoryTypes = inventoryData.map((item) => item.inventorytype);
  const accumulateInventoryTypes = accumulateInventoryTypesData.map(
    (item) => item.inventorytype
  );
  return [
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: [...(data.category || [])].sort(),
      disabled:
        groupResultValue.length > 0 && !groupResultValue.includes('category'),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: [...(data.variety || [])].sort(),
      disabled:
        groupResultValue.length > 0 && !groupResultValue.includes('variety'),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: [...(data.color || [])].sort(),
      disabled:
        groupResultValue.length > 0 && !groupResultValue.includes('color'),
    },
    {
      id: 'grade',
      filtername: 'Grade',
      componentType: 'Checkbox',
      valueOptions: [...(data.grade || [])].sort(),
      disabled:
        groupResultValue.length > 0 && !groupResultValue.includes('grade'),
    },
    {
      id: 'inventoryType',
      filtername: 'Inventory',
      componentType: 'Checkbox',
      valueOptions: isAccumulate
        ? accumulateInventoryTypes.sort()
        : inventoryTypes.sort(),
    },
  ];
};

export const transformFiltersAtpSourceData = (data: FiltersATP) => {
  return [
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: [...(data.category || [])].sort(),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: [...(data.variety || [])].sort(),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: [...(data.color || [])].sort(),
    },
    {
      id: 'grade',
      filtername: 'Grade',
      componentType: 'Checkbox',
      valueOptions: [...(data.grade || [])].sort(),
    },
  ];
};

export const transformFiltersAging = (data: FiltersAging) => {
  return [
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: [...(data.category || [])].sort(),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: [...(data.variety || [])].sort(),
    },
    {
      id: 'short_color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: [...(data.short_color || [])].sort(),
    },
    {
      id: 'product_rotation_day',
      filtername: 'Rotation Days',
      componentType: 'Checkbox',
      valueOptions: [...(data.product_rotation_day || [])].sort(),
    },
  ];
};

export const filterData = (data: object) => {
  const newData = Object.fromEntries(
    Object.entries(data).filter((_, valor) => {
      return !(Array.isArray(valor) && valor.length === 0);
    })
  );
  return newData;
};

export const transformFiltersAction = (data: IAging, showGrade: boolean) => {
  const filterFields = [
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: [...(data.category || [])].sort(),
      expandedByDefault: false,
      disableExpand: showGrade,
      hideUtils: false,
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: [...(data.short_color || [])].sort(),
      expandedByDefault: false,
      disableExpand: showGrade,
      hideUtils: false,
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: [...(data.variety || [])].sort(),
      expandedByDefault: false,
      disableExpand: showGrade,
      hideUtils: false,
    },
  ];

  if (showGrade) {
    filterFields.push({
      id: 'grade',
      filtername: 'Grade',
      componentType: 'RadioButton',
      valueOptions: [...(data.grade || [])],
      expandedByDefault: true,
      disableExpand: false,
      hideUtils: true,
    });
  }
  return filterFields;
};
