import React from 'react';
import {
  Grid,
  AppBar,
  Button,
  Toolbar,
  Menu,
  MenuItem,
  Box,
  IconButton,
  Avatar,
  ListItemIcon,
  Divider,
} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { useHeaderState } from './state/useHeaderState';
import { headerStyles as styles } from './Header.styles';
import { AtlasIcon, LineIcon, RosaprimaIcon, SettingsIcon } from '@/shared/icons/Icons';
import HoverMenu from '@/components/HoverMenu/HoverMenu';
import DialogPopup from '@/components/DialogPopup/DialogPopup';
import { Link } from 'react-router-dom';

const Header = () => {
  const {
    open,
    errorMessage,
    userLoggedIn,
    handleRedirect,
    handleLogout,
    setErrorMessage,
    handleOpenMenu,
    handleNavigate
  } = useHeaderState();

  return (
    <AppBar sx={styles.headerBar}>
      {errorMessage && (
        <DialogPopup
          content={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}
      <Toolbar sx={styles.headerToolbar}>
        <Grid container spacing={2}>
          <Grid item xs={3} sx={styles.headerLogos}>
            <Link to="/" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
              <AtlasIcon />
              <LineIcon />
              <RosaprimaIcon />
            </Link>
          </Grid>
          <Grid item xs={8} sx={styles.headerAlign}>
            {userLoggedIn && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <HoverMenu />
              </Box>
            )}
          </Grid>
          <Grid item xs={1} sx={styles.headerAlign} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {userLoggedIn ? (
              <>
                <Box sx={{ marginTop: '3%' }}>
                  <IconButton
                    data-testid="SettingsIcon"
                    onClick={() => handleNavigate('/admin-settings')}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Box>

                <Box sx={{ marginLeft: '5%' }}>
                  <IconButton
                    data-testid="handleOpenMenuButton"
                    onClick={() => handleOpenMenu(true)}
                    sx={{ p: 0 }}
                  >
                    <Avatar alt="Remy Sharp" data-testid='Avatar' />
                  </IconButton>
                  <Menu
                    data-testid="account-menu"
                    open={open}
                    onClose={() => handleOpenMenu(false)}
                    onClick={() => handleOpenMenu(false)}
                    PaperProps={{
                      elevation: 0,
                      sx: styles.headerMenu,
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem data-testid="Profile-link">
                      <Avatar /> Profile
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              </>
            ) : (
              <Button
                variant="outlined"
                sx={styles.headerBSignIn}
                onClick={handleRedirect}
              >
                Sign-In
              </Button>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
