import React, { useEffect, useMemo, useRef } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import TableHeader from '@/components/Table/TableHeader';
import { UseAgingByVarietyTableState } from './state/UseAgingByVarietyTableState';
import {
  EditIcon,
  SearchBarIcon,
} from '@/shared/icons/Icons';
import { AGING_BY_VARIETY } from '@/shared/constantes/AgingByVariety';
import SearchBar from '@/components/SearchBar/SearchBar';
import { agingByVarietyStyles as styles } from './AgingByVariety.styles';
import TooltipIcon from '@/components/Tooltip/TooltipIcon';
import PopoverComponent from '@/components/PopoverComponent/PopoverComponent';
import PushNotification from '@/components/PushNotifications/PushNotifications';
import EditOptions from '../EditOptions/EditOptions';
import { AgingByVariety } from '@/store/interfaces/aging.interfaces';
import FilterChip from "@/components/FilterChip/FilterChip";

const AgingByVarietyTable = () => {
  const {
    columns,
    loading,
    filteredAging,
    showSearchBar,
    sortBy,
    selectedRows,
    closePopover,
    openPushNotification,
    pushNotificationMessage,
    autoHideDuration,
    showUndoAction,
    type,
    undoSelectedRows,
    setType,
    setClosePopover,
    setOpenPushNotification,
    handleUpdateAging,
    setSelectedRows,
    setShowSearchBar,
    handleSort,
    handleSearchValue,
    handleSearchSubmit,
    isAlertVisible
  } = UseAgingByVarietyTableState();

  const popoverRef = useRef(null);

  const visibleColumns = useMemo(() =>
    columns.reduce<{ [key: string]: boolean }>((acc, column) => {
      acc[column.field] = true;
      return acc;
    }, {}),
    [columns]
  );

  const handleSelectRow = (variety: string, product_rotation_day: number) => {
    // @ts-expect-error
    setSelectedRows((prevSelectedRows: AgingByVariety[]) => {
      const existingIndex = prevSelectedRows.findIndex(
        (row) => row.variety === variety && row.product_rotation_day === product_rotation_day
      );
      if (existingIndex !== -1) {
        return prevSelectedRows.filter((_, index) => index !== existingIndex);
      } else {
        return [...prevSelectedRows, { variety, product_rotation_day }];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedRows.length === filteredAging.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(filteredAging);
    }
  };

  const handleClickOption = async (type: string, value: number) => {
    const newAging = selectedRows.map((row) => {
      return {
        ...row,
        newRotationDays: type === '' ? value : type === 'Increase' ? row.product_rotation_day + value : row.product_rotation_day - value
      }
    });

    await handleUpdateAging(newAging, false);
  }

  const handleUndoAction = async () => {
    const undoAging = undoSelectedRows.map((row) => {
      return {
        ...row,
        newRotationDays: row.product_rotation_day
      }
    });
    await handleUpdateAging(undoAging, true);
  }

  useEffect(() => {
    if (closePopover) {
      // @ts-expect-error
      popoverRef.current.handleClose();
      setClosePopover(false);
    }
  }, [closePopover]);

  return (
    <>
      <Box sx={{
          position: "absolute",
          top: { xs: '160px', md: '120px' },
          left: '18%',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
      }}>
          <FilterChip context="aging"/>
      </Box>
      {isAlertVisible && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '3px',
          }}
        >
          <Alert sx={{ width: '100%' }} severity="success">
            Rotation Days are based on the Production date.
          </Alert>
        </Box>
      )}
      <Box sx={styles.box}>
        <Box sx={styles.text}>
          <Box>{`${filteredAging && filteredAging.length} results`} </Box>
        </Box>
        <Box sx={styles.boxIcons}>
          {showSearchBar ? (
            <Box sx={styles.search}>
              <SearchBar handleSearchValeu={handleSearchValue} handleSubmit={handleSearchSubmit} />
            </Box>
          ) : (
            <TooltipIcon
              title={AGING_BY_VARIETY.BUTTON_SEARCH}
              handleClick={() => setShowSearchBar(true)}
              icon={<SearchBarIcon />}
            />
          )}
          <PopoverComponent
            ref={popoverRef}
            children={
              <EditOptions
                type={type}
                setType={setType}
                handleClickOption={handleClickOption}
              />
            }
            title={AGING_BY_VARIETY.BUTTON_EDIT}
            icon={<EditIcon />}
          />
        </Box>
      </Box>
      {!loading ? (
        <TableContainer component={Paper} style={{ maxHeight: '75vh' }}>
          <Table stickyHeader>
            <TableHeader
              headCell={columns}
              handleSort={handleSort}
              sortBy={sortBy}
              visibleColumns={visibleColumns}
              handleSelectAll={handleSelectAll}
              isCheckbox={true}
              checkboxState={selectedRows.length == filteredAging.length}
              onChangeCheckBox={() => handleSelectAll()}
            ></TableHeader>
            <TableBody>
              {filteredAging &&
                filteredAging.length > 0 &&
                filteredAging.map((row: AgingByVariety, index) => {
                  return (
                    <>
                      <TableRow key={index} sx={{ border: '1px solid #E0E0E0 ' }}>
                        <TableCell padding="checkbox" sx={{ border: '1px solid #E0E0E0 ' }}>
                          <Checkbox
                            checked={selectedRows.some((selectedRow) => selectedRow.variety === row.variety)}
                            onChange={() => handleSelectRow(row.variety, row.product_rotation_day)}
                          />
                        </TableCell>
                        <TableCell sx={styles.tableCell}>{row.category}</TableCell>
                        <TableCell sx={styles.tableCell}>{row.short_color}</TableCell>
                        <TableCell sx={styles.tableCell}>{row.variety}</TableCell>
                        <TableCell sx={{ border: '1px solid #E0E0E0 ' }}>{row.product_rotation_day}</TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
          <CircularProgress />
        </Box>
      )}
      <PushNotification
        open={openPushNotification}
        setOpen={setOpenPushNotification}
        onClose={() => { setOpenPushNotification(false) }}
        message={pushNotificationMessage}
        autoHideDuration={autoHideDuration}
        showUndoAction={showUndoAction}
        undoAction={() => { handleUndoAction() }}
      />
    </>
  );
};
export default AgingByVarietyTable;
