import { createNamedStyles } from '../../shared/interfaces/create_name_styles';

export const hideColumnsStyle = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    fontSize: '13px',
    padding: '15px',
    fontWeight: 'bold',
  },
});
