import { createNamedStyles } from '@/shared/interfaces/create_name_styles';
import { theme } from '@/theme';

export const agingByVarietyStyles = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    marginBottom: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    fontWeight: '600',
    margin: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  boxIcons: {
    display: 'flex',
    width: '50%',
    justifyContent: 'end',
  },
  search: {
    width: '50%',
    marginBottom: '5px',
    marginTop: '5px',
  },
  tableCell: {
    maxWidth: '20px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    border: theme.custom.border,
  },
});
