import React, { useState } from "react"
import { tableStyle as styles } from "../../shared/styles/table.styles"
import { Box, Button, Checkbox, CircularProgress, colors, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from "@mui/material"
import { UseActionPlanState } from "./state/useActionPlanState"
import SearchBar from "../SearchBar/SearchBar"
import TooltipIcon from "../Tooltip/TooltipIcon"
import PopoverComponent from "../PopoverComponent/PopoverComponent"
import HideColumns from "../HideColumns/HideColumns"
import { Download, SearchBarIcon, ShowColumns } from "../../shared/icons/Icons"
import { ACTION_PLAN } from "../../shared/constantes/ActionPlan"
import TableHeader from "../Table/TableHeader"

const ActionPlanTable = () => {
  const {
    showSearchBar,
    loading,
    columns,
    handleSort,
    sortBy,
    editIndex,
    editColumn,
    handleDoubleClick,
    headerDateIds
  } = UseActionPlanState()

  const [visibleColumns, setVisibleColumns] = useState(() => {
    const initialVisibility = columns.reduce((acc, column) => {
      acc[column.field] = true;
      return acc;
    }, {});
    initialVisibility;

    return initialVisibility;
  });

  const handleToggleColumn = (columnId) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  const data = [
    { category: "ROS", color: "RED", variety: "Freedom", grade: "60" }
  ]

  return (
    <>
      <Box sx={styles.box}>
        <Box sx={styles.text}>
          <Box>{`${2} results`} </Box>
        </Box>
        <Box sx={styles.boxIcons}>
          {showSearchBar ? (
            <Box sx={styles.search}>
              <SearchBar
                handleSearchValeu={() => { }}
                handleSubmit={() => { }}
              />
            </Box>
          ) : (
            <TooltipIcon
              title={ACTION_PLAN.BUTTON_ACTIONS}
              handleClick={() => (true)}
              icon={<SearchBarIcon />}
            />
          )}
          <PopoverComponent
            children={
              <HideColumns
                columnsName={[]}
                handleToggleColumn={() => { }}
                visibleColumns={() => { }}
              />
            }
            title={ACTION_PLAN.BUTTON_HIDE}
            icon={<ShowColumns />}
          />
          <TooltipIcon
            title={ACTION_PLAN.DOWNLOAD}
            icon={<Download />}
            handleClick={() => { }}
          />
        </Box>
      </Box>
      {!loading ? (
        <TableContainer component={Paper} style={{ maxHeight: '75vh' }}>
          <Table stickyHeader>
            <TableHeader
              headCell={columns}
              visibleColumns={visibleColumns}
              isCheckbox={false}
              handleSort={handleSort}
              sortBy={sortBy}
              aditionalColumns={headerDateIds}
            ></TableHeader>
            <TableBody>
              <TableRow sx={{ position: 'sticky', top: '45px', zIndex: 999 }}>
                <TableCell colSpan={4} sx={styles.tableCell}>
                  {ACTION_PLAN.MIX}
                </TableCell>
              </TableRow>
              <TableRow sx={{ position: 'sticky', top: '45px', zIndex: 999 }}>
                <TableCell colSpan={4} sx={styles.tableCell}>
                  {ACTION_PLAN.TOTAL_AVAILABLE_MIX}
                </TableCell>
              </TableRow>
              <TableRow sx={{ position: 'sticky', top: '45px', zIndex: 999 }}>
                <TableCell colSpan={4} sx={styles.tableCell}>
                  {ACTION_PLAN.TOTAL_ATP}
                </TableCell>
              </TableRow>
              {data &&
                data.length > 0 &&
                data.map((row, index) => {
                  return (
                    <>
                      <TableRow>
                        {visibleColumns['category'] && (
                          <TableCell>{row.category}</TableCell>
                        )}
                        {visibleColumns['variety'] && (
                          <TableCell>{row.variety}</TableCell>
                        )}
                        {visibleColumns['color'] && (
                          <TableCell>{row.color}</TableCell>
                        )}
                        {visibleColumns['grade'] && (
                          <TableCell>{row.grade}</TableCell>
                        )}
                        {
                          headerDateIds && headerDateIds.map((date, index) => {
                            return (
                              <TableCell
                                key={index}
                              >
                                0
                              </TableCell>
                            )

                          })
                        }
                        {visibleColumns['notes'] && (
                          <TableCell
                            onDoubleClick={() =>
                              handleDoubleClick(index, 'notes', row)
                            }
                          >
                            {editIndex === index &&
                              editColumn === 'notes' ? (
                              <TextField
                                value={formData.rotationDays}
                                onChange={(e) =>
                                  handleChange(e, 'rotationDays')
                                }
                                onBlur={handleSave}
                                autoFocus
                                size="small"
                                variant="standard"
                                sx={{ width: '60px' }}
                              />
                            ) : (
                              row.grade
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
          <CircularProgress />
        </Box>
      )}
      <Box sx={{position:"relative"}}>
        <Button variant="contained" sx= {{float:"right", marginBottom:"0", top: '40vh'}}>Create Acction Plan By Customer</Button>
      </Box>


    </>
  )

}
export default ActionPlanTable