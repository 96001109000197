import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { MENU, MenuEnum } from '../../shared/constantes/MenuEnum';
import { useLocation } from 'react-router-dom';

const Breadcrumb = () => {
  const location = useLocation();
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      {MenuEnum.REVENUE_MANAGEMENT}
    </Link>,
    <Typography key="3">{MENU[location.pathname]}</Typography>,
  ];

  return <Breadcrumbs separator="/">{breadcrumbs}</Breadcrumbs>;
};

export default Breadcrumb;
