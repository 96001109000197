export const AGING_BY_VARIETY = {
  TITLE: 'Aging By Variety',
  DOWNLOAD: 'Export',
  BUTTON_HIDE: 'Hide',
  BUTTON_EDIT: 'Edit',
  BUTTON_SEARCH: 'Search',
  TOTAL: 'TOTAL',
  EDIT_ROTATION_DAYS: 'Rotation',
  BUTTON_ACTIONS: 'Quick Actions',
  VIEW_DISPOSAL: 'View Disposal Trends',
  VIEW_DISEASE: 'View Disease Trends',
  VIEW_LATEST_UPDATES: 'View Latest Updates',
  ROTATION_UPDATED_SUCCESS: 'Rotation days updated successfully',
  ROTATION_UPDATED_ERROR: 'Error updating rotation days',
  ROTATION_RESTORED_ERROR: 'Error restoring rotation days',
  ROTATION_CANNOT_BE_ZERO: 'Rotation days cannot be negative or zero',
  ROTATION_RESTORED_SUCCESS: 'Rotation days restored successfully',
  ROTATION_NOVARIETIES_SELECTED: 'No varieties selected',
  CHANGE_ROTATION: 'Change Rotation to',
};

export const AGING_BY_VARIETY_REDUCE_OPTIONS = [
  { label: 'Reduce rotation by 1 day', value: 1 },
  { label: 'Reduce rotation by 2 days', value: 2 },
  { label: 'Reduce rotation by 3 days', value: 3 },
]

export const AGING_BY_VARIETY_INCREASE_OPTIONS = [
  { label: 'Increase rotation by 1 day', value: 1 },
  { label: 'Increase rotation by 2 days', value: 2 },
  { label: 'Increase rotation by 3 days', value: 3 },
]

export const AGING_BY_VARIETY_DAYS_AVAIALABLE = [
  { label: '1 day', value: 1 },
  { label: '2 days', value: 2 },
  { label: '3 days', value: 3 },
  { label: '4 days', value: 4 },
  { label: '5 days', value: 5 },
  { label: '6 days', value: 6 },
  { label: '7 days', value: 7 },
  { label: '8 days', value: 8 },
  { label: '9 days', value: 9 },
  { label: '10 days', value: 10 },
]
