import { createNamedStyles } from '@/shared/interfaces/create_name_styles';

export const availabilityModalStyles = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    fontSize: '16px',
  },
  text: {
    fontWeight: '600',
    fontFamily: '-apple-system, BlinkMacSystemFont,',
    margin: '7px 0px',
  },
  headerStyle: {
    fontSize: '13px',
    fontFamily: 'Roboto, sans-serif',
    padding: '10px 0px',
    border: '1px solid #E0E0E0',
    height: '100%',
    boxSizing: 'border-box',
    backgroundColor: '#ECEFF1',
    textIndent: '10px',
    fontWeight: 'bold',
    color: '#5c5c5c',
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    borderRadius: '0px',
  },
});
