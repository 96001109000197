import React from 'react';
import { Button, Tooltip } from '@mui/material';

const TooltipIcon: React.FC<{
  title: string;
  handleClick?: () => void;
  icon: React.ReactNode;
}> = ({ title, handleClick, icon }) => (
  <Tooltip
    title={title}
    placement="top-start"
    PopperProps={{
      sx: {
        '& .MuiTooltip-tooltip': {
          backgroundColor: '#927C4E',
          fontSize: '12px',
        },
      },
    }}
  >
    <Button onClick={handleClick}>{icon}</Button>
  </Tooltip>
);

export default TooltipIcon;
