import { actionPlanSlice } from '@/store/reducers/actionPlan.slice';

export const {
  setStartDate,
  setEndDate,
  setLoading,
  setFilterGapsByVariety,
  setFilterHolidayGaps,
  setFiltersToSearch,
  setSort,
  setCurrentPage,
} = actionPlanSlice.actions;
