import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, ...rest }) => {
  const { instance } = useMsal();
  const activeAccount: any = instance.getActiveAccount();

  return activeAccount ? element : <Navigate to="/" />;
};
export default ProtectedRoute;
