import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { AVAILABLE_TO_PROMISE } from '../../../shared/constantes/AvailableToPromise';
import './AvailableToPromise.css';
import SidebarAvailableToPromise from '../../../components/SidebarAvailableToPromise/SidebarAvailableToPromise';
import Layout from '../../Layout/Layout';
import AvailableToPromiseTable from '../../../components/AvailableToPromiseTable/AvailableToPromiseTable';

const AvailableToPromise = () => {
  return (
    <>
      <Layout
        title={AVAILABLE_TO_PROMISE.TITLE}
        sidebar={SidebarAvailableToPromise}
      >
        <AvailableToPromiseTable />
      </Layout>
    </>
  );
};
export default AvailableToPromise;
