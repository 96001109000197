import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { autocompleteStyles as styles } from './Autocomplete.styles';

interface AutoCompleteProps {
    inputValue: string;
    label: string;
    options: string[];
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
    onOptionSelect: (option: string) => void;
    size?: 'small' | 'medium';
    additionalOptions?: string[];
    disable?: boolean;
}

const AutoCompleteComponent: React.FC<AutoCompleteProps> = ({ inputValue, label, options, setInputValue, onOptionSelect, size, additionalOptions, disable }) => {
    const filteredOptions = options.filter(option =>
        option.toLowerCase().includes(inputValue.toLowerCase()) && inputValue.length >= 2
    );
    const combinedOptions = [
        ...filteredOptions,
        ...(additionalOptions ?? []).filter(opt => !filteredOptions.includes(opt))
    ];
    return (
        <Autocomplete
            freeSolo
            options={additionalOptions && additionalOptions.length > 0 ? combinedOptions : filteredOptions}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
                onOptionSelect(newValue || '');
            }}
            style={{ width: "100%" }}
            disabled={disable}
            renderInput={(params) => (
                <TextField {...params} label={label}  variant={additionalOptions ? "standard" : "outlined"} sx={additionalOptions ? styles.input_action_plan : styles.input} size={size}  />
            )}
        />
    );
};

export default AutoCompleteComponent;