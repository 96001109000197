import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../../actions/salesOrdersAllocation/salesOrdersAllocation.actions';
import { AgingByVariety } from '../../interfaces/aging.interfaces';
import { API_ROUTES } from '../../../shared/api_routes';
import { filterData } from '../../utils/storeUtils';

const getAging = createAsyncThunk<
  AgingByVariety[],
  { dispatch: any; searchQuery?: string; filtersData?: object }
>('agingByVariety/getAging', async ({ dispatch, searchQuery, filtersData }) => {
  try {
    dispatch(setLoading(true));
    let newFilters;
    let url = `${API_ROUTES.API}aging`;
    if (searchQuery) {
      url += `&search_query=${searchQuery}`;
    }
    if (filtersData) {
      newFilters = filterData(filtersData);
    }
    const AgingBody = {
      filterObject: {
        ...newFilters,
      },
    };
    // const response = await axios.post<AgingByVariety[]>(url, filterData && AgingBody)
    const response = {
      data: [
        {
          category: 'Rose',
          variety: 'Akito',
          color: 'Red',
          rotationDays: 5,
        },
        {
          category: 'Dave',
          variety: 'Absolut in pink',
          color: 'White',
          rotationDays: 5,
        },
        {
          category: 'Ane',
          variety: 'Aly',
          color: 'Green',
          rotationDays: 5,
        },
      ],
    };
    dispatch(setLoading(false));
    return response.data;
  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error fetching ATP data:', error);
    throw error;
  }
});

export { getAging };
