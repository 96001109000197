import { configureStore } from '@reduxjs/toolkit';
import salesOrdersReducer from './reducers/salesOrdersAllocation.slice';
import availableToPromise from './reducers/availableToPromise.slice';
import agingByVariety from './reducers/agingByVariety.slice';
import actionPlan from './reducers/actionPlan.slice';

export const store = configureStore({
  reducer: {
    salesOrders: salesOrdersReducer,
    availableToPromise,
    agingByVariety,
    actionPlan,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
