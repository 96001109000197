import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../../actions/commonState/commonState.actions';
import { API_ROUTES } from '@/shared/api_routes';
import axios from '@/shared/axios-utils';
import {
  IGetParams,
  IProduct,
  ISubstitutions,
  IUploadImage,
} from './ISubstitutions.interface';

const getSubstitutions = createAsyncThunk<
  ISubstitutions[],
  { body: IGetParams }
>('substitutions/getSubstitutions', async ({ body }, thunkAPI) => {
  try {
    thunkAPI.dispatch(setLoading(true));
    const url = `${API_ROUTES.API}/revenue-management/substitutions/getSubstitutions`;
    const response = await axios.post<ISubstitutions[]>(url, body, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching getSubstitutions: ', error);
    throw error;
  } finally {
    thunkAPI.dispatch(setLoading(false));
  }
});

const uploadImage = createAsyncThunk<string, { body: IUploadImage }>(
  'substitutions/uploadImage',
  async ({ body }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const url = `${API_ROUTES.API}/images-repository`;
      const response = await axios.post<string>(url, body, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading image: ', error);
      throw error;
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

const getProducts = createAsyncThunk<IProduct[], { body: IGetParams }>(
  'substitutions/getProducts',
  async ({ body }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const url = `${API_ROUTES.API}/products/getProducts`;
      const response = await axios.post<IProduct[]>(url, body, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching getProducts: ', error);
      throw error;
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

const updateSubstitutions = createAsyncThunk<boolean, { body: any }>(
  'substitutions/updateSubstitutions',
  async ({ body }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));

      const url = `${API_ROUTES.API}/revenue-management/substitutions/updateSubstitutions`;
      const response = await axios.patch<{ updateProducts: number }>(
        url,
        body,
        { withCredentials: true }
      );

      return response.data.updateProducts > 0;
    } catch (error) {
      console.error('Error updating aging by variety:', error);
      throw error;
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export { getSubstitutions, updateSubstitutions, uploadImage, getProducts };
