import AllocationPrioritiesComponent from '@/components/AdminSettings/AllocationPriorities/AllocationPriorities';
import Layout from '@/containers/Layout/Layout';
import { ALLOCATION_PRIORITIES } from '@/shared/constantes/AllocationPriorities';
import React from 'react';
import { UseAllocationPrioritiesState } from './state/useAllocationPrioritiesState';

const AllocationPriorities = () => { 
    UseAllocationPrioritiesState();
    return (
        <Layout title={ALLOCATION_PRIORITIES.TITLE}>
            <AllocationPrioritiesComponent />
        </Layout>
    )
 };
export default AllocationPriorities;