import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  CircularProgress,
  Switch,
  Pagination,
  PaginationItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableHeader from '@/components/Table/TableHeader';
import { availableToPromiseTableStyle as styles } from './AvailableToPromiseTable.styles';
import { Download, SearchBarIcon, ShowColumns } from '@/shared/icons/Icons';
import TooltipIcon from '@/components/Tooltip/TooltipIcon';
import { AVAILABLE_TO_PROMISE } from '@/shared/constantes/AvailableToPromise';
import SearchBar from '@/components/SearchBar/SearchBar';
import { UseAvailableToPromiseState } from './state/useAvailableToPromiseState';
import { convertNumber } from '@/shared/utils/numbersUtils';
import HideColumns from '@/components/HideColumns/HideColumns';
import PopoverComponent from '@/components/PopoverComponent/PopoverComponent';
import Timer from '@/components/Timer/Timer';
import { ENVIRONMENT } from '@/shared/api_routes';
import { ATP, Inventory } from '@/store/interfaces/atp.interfaces';

const cellStyle = (row?: number, type?: string) => ({
  fontSize: '12px',
  padding: '10px 10px',
  border: '1px solid #E0E0E0 ',
  whiteSpace: 'nowrap',
  color: row && row < 0 ? 'red' : '',
  background: row && row < 0 ? '#FDEDEC' : type === 'atpInventory' || type === 'atpInventoryCombined' ? '#F4F2EF' : '',
  borderLeft: type && '2px solid #abb2b9',
});

const cellInventoryStyle = (isExpanded: boolean, row?: string) => ({
  border: '1px solid #E0E0E0 ',
  fontWeight: row === 'atpInventory' || row === 'atpInventoryCombined' ? 'bold' : 'normal',
  background: isExpanded ? '#FBFAF9' : row === 'atpInventory' || row === 'accumulatedAtp' || row === 'atpInventoryCombined' ? '#F4F2EF' : '',
});

const cellExpandedStyle = () => ({
  fontSize: '12px',
  padding: '10px 17px',
  backgroundColor: '#FBFAF9',
  border: '1px solid #E0E0E0',
  height: '50%',
  whiteSpace: 'nowrap',
  fontFamily: 'Roboto, sans-serif',
  textWrap: "pretty",
})
const AvailableToPromiseTable = () => {
  const {
    inventorytype,
    showSearchBar,
    headerDataTable,
    loading,
    atp,
    headerIds,
    columns,
    inventoryColumns,
    isAccumulate,
    visibleInventoryTypes,
    setShowSearchBar,
    handleDownloadATPCSV,
    handleSort,
    sortBy,
    handleSearchValue,
    handleSubmit,
    handleChangeAccumulate,
    totalPages,
    page,
    handleChangePage,
    totalAtp,
    date,
    visibleColumns,
    handleToggleColumn,
  } = UseAvailableToPromiseState();

  const [expandedRow, setExpandedRow] = useState(null);
  const [expandedRowAll, setExpandedRowAll] = useState(false);

  const handleRowClick = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, index: any) => {
    if (event.ctrlKey) {
      setExpandedRow(prevExpandedRow => prevExpandedRow === index ? null : index);
    } else {
      const validation = inventoryColumns[index]?.items?.length
      validation && setExpandedRowAll(!expandedRowAll);
    }
  };

  const countRowsToExpand = (rows: any) => {
    if (expandedRowAll) {
      let recInventoryColumns = inventorytype && inventorytype.length > 0 ? inventoryColumns.filter((item: any) => inventorytype.includes(item.inventorytype)) : inventoryColumns;
      const totalRows = recInventoryColumns.reduce((sum, rowInventory) => {
        return sum + (rowInventory.items ? rowInventory.items.length : 0);
      }, 0);
      return inventoryColumns.length + totalRows;
    } else {
      return inventoryColumns.length + rows;
    }
  }

  useEffect(() => {
    if (inventorytype) {
      setExpandedRow(null)
      setExpandedRowAll(false)
    }
  }, [inventorytype])

  const handleTableCellDate = (inventory: { [date: string]: Inventory }, inventoryType: keyof Inventory, isExpanded: boolean) => {
    return (
      headerIds.map((date, index) => (
        <TableCell
          key={index}
          sx={cellInventoryStyle(isExpanded, inventoryType)}
          style={{
            backgroundColor:
              inventory[date] && inventory[date][inventoryType] < 0
                ? '#FDEDEC'
                : '',
            color:
              inventory[date] && inventory[date][inventoryType] < 0 ? 'red' : '',
          }}
        >
          {convertNumber(inventory[date] ? inventory[date][inventoryType] : 0)}
        </TableCell>
      )))
  }

  const getRowSpan = () => {
    const validation = expandedRow !== null && expandedRow !== undefined && inventoryColumns[expandedRow]?.items?.length
    return (expandedRowAll || expandedRow !== null) && validation !== undefined ? countRowsToExpand(validation) : inventoryColumns.length;
  };

  return (
    <>
      <Box sx={styles.box}>
        <Box sx={styles.text}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}>
            <Box>{`${atp && totalAtp} results`} </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: "15px", height: "15px", visibility: !loading ? 'hidden' : 'visible' }}>
                <CircularProgress style={{ width: "15px", height: "15px" }} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: ENVIRONMENT.DISABLED_ICON_ACCUMULATED === "true" ? 'none' : 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Box>{AVAILABLE_TO_PROMISE.ACCUMULATE_INVENTORY}</Box>
            <Switch
              checked={isAccumulate}
              onChange={handleChangeAccumulate}
              size="small"
            />
          </Box>
        </Box>
        <Box sx={styles.boxIcons}>
          {showSearchBar ? (
            <Box sx={styles.search}>
              <SearchBar
                handleSearchValeu={handleSearchValue}
                handleSubmit={handleSubmit}
              />
            </Box>
          ) : (
            <TooltipIcon
              title={AVAILABLE_TO_PROMISE.BUTTON_SEARCH}
              handleClick={() => setShowSearchBar(true)}
              icon={<SearchBarIcon />}
            />
          )}
          <PopoverComponent
            children={
              <HideColumns
                columnsName={columns}
                handleToggleColumn={handleToggleColumn}
                visibleColumns={visibleColumns}
              />
            }
            title={AVAILABLE_TO_PROMISE.BUTTON_HIDE}
            icon={<ShowColumns />}
          />
          <TooltipIcon
            title={AVAILABLE_TO_PROMISE.DOWNLOAD}
            icon={<Download />}
            handleClick={handleDownloadATPCSV}
          />
        </Box>
      </Box>
      {
        <>
          <TableContainer component={Paper} style={{ maxHeight: '70vh' }}>
            <Table stickyHeader>
              <TableHeader
                headCell={columns}
                sortBy={sortBy}
                handleSort={handleSort}
                visibleColumns={visibleColumns}
                aditionalColumns={
                  !isAccumulate ? headerDataTable : headerDataTable.slice(0, -1)
                }
              ></TableHeader>
              <TableBody>
                {atp &&
                  atp.length > 0 &&
                  atp.map((row: ATP, index) => {
                    if (Object.keys(row.inventory).length > 0) {
                      return (
                        <React.Fragment key={`${index}-tableATP`}>

                          {inventoryColumns.map((rowInventory: { id: string, inventorytype: string, items?: [] }, index) => {

                            return (
                              <>
                                <TableRow
                                  key={`${index}-inventory`}
                                  sx={{ border: '1px solid red' }}
                                >
                                  {index === 0 && (
                                    <>
                                      {visibleColumns['category'] && (
                                        <TableCell
                                          rowSpan={getRowSpan()}
                                          sx={cellStyle()}
                                        >
                                          {row.category}
                                        </TableCell>
                                      )}
                                      {visibleColumns['color'] && (
                                        <TableCell
                                          rowSpan={getRowSpan()}
                                          sx={cellStyle()}
                                        >
                                          {row.color}
                                        </TableCell>
                                      )}
                                      {visibleColumns['variety'] && (
                                        <TableCell
                                          rowSpan={getRowSpan()}
                                          sx={cellStyle()}
                                        >
                                          {row.variety}
                                        </TableCell>
                                      )}
                                      {visibleColumns['grade'] && (
                                        <TableCell
                                          rowSpan={getRowSpan()}
                                          sx={cellStyle()}
                                        >
                                          {row.grade}
                                        </TableCell>
                                      )}
                                    </>
                                  )}
                                  {visibleColumns['inventory'] &&
                                    visibleInventoryTypes[rowInventory.inventorytype] && (
                                      <TableCell
                                        sx={{ ...cellInventoryStyle(false, rowInventory.id), cursor: rowInventory.items ? 'pointer' : 'default', position: 'relative' }}
                                        onClick={isAccumulate ? (event) => handleRowClick(event, index) : undefined}
                                      >
                                        {rowInventory.inventorytype}{' '}
                                        {rowInventory.items && rowInventory.items.length > 0 && (
                                          <span style={{ marginLeft: "auto", position: "absolute", right: "15px" }}> {expandedRowAll || expandedRow === index ? '-' : '+'} </span>
                                        )}
                                      </TableCell>
                                    )
                                  }
                                  {Object.keys(row.inventory).length > 0 &&
                                    visibleInventoryTypes[
                                    rowInventory.inventorytype
                                    ] &&
                                    handleTableCellDate(
                                      row.inventory,
                                      rowInventory.id as keyof Inventory,
                                      false
                                    )
                                  }
                                  {!isAccumulate &&
                                    visibleInventoryTypes[
                                    rowInventory.inventorytype
                                    ] && (
                                      <TableCell
                                        sx={cellStyle(
                                          row.totals[rowInventory.id as keyof Inventory],
                                          rowInventory.id
                                        )}
                                      >
                                        {convertNumber(
                                          row.totals[rowInventory.id as keyof Inventory]
                                        )}
                                      </TableCell>
                                    )
                                  }
                                </TableRow>
                                {
                                  (expandedRowAll || expandedRow === index) && (!inventorytype?.length || inventorytype.includes(rowInventory.inventorytype)) && rowInventory.items && rowInventory.items.map((item: { id: string, inventorytype: string }, indexRowItem) => {
                                    return (
                                      <TableRow
                                        key={`${index}-inventory-${indexRowItem}`}
                                      >
                                        {
                                          <TableCell key={`${index}-cell`} sx={cellExpandedStyle()}>
                                            {item.inventorytype}
                                          </TableCell>

                                        }
                                        {Object.keys(row.inventory).length > 0 &&
                                          visibleInventoryTypes[
                                          rowInventory.inventorytype
                                          ] &&
                                          handleTableCellDate(
                                            row.inventory,
                                            item.id as keyof Inventory,
                                            true
                                          )
                                        }
                                      </TableRow>
                                    )
                                  })
                                }
                              </>
                            );
                          })}
                        </React.Fragment>
                      );
                    }
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            alignItems: 'center'
          }}>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                size='small'
                style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    style={{
                      backgroundColor: "white",
                      fontSize: "11px",
                      padding: '0 10px',
                      textDecoration: item.selected ? 'underline' : 'none',
                      textUnderlineOffset: '4px',
                      textDecorationThickness: '2px',
                    }}
                  />
                )}
              >
              </Pagination>
            </Box>
            <Timer time={date} />
          </Box>
        </>
      }
    </>
  );
};
export default AvailableToPromiseTable;
