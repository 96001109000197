export const DefaultValuesAllocationPriorities = {
    businessRules: {
        "customerGuidance": ['SG', 'GW', 'MG', 'PO', 'R3', 'EX'],
        "orderType": ['SO', 'HO', 'FS', 'D', 'OM'],
        "allocationPriority": ["so.guidance", "sod.atlas_order_type", "sod.apslfd"],
        "price": ["apslfd"]
    },
    sizeRanges: {
        sizeSubstitutions: [
            { category: "ANE", range: "+-5" },
            { category: "RAN", range: "+-5" },
            { category: "ROS", range: "+-10" },
            { category: "GAR", range: "+-10" },
            { category: "DAV", range: "+-10" },
            { category: "SPR", range: "+-10" }
        ],
    },
    alternativeVarietyMatch: ['Search for a Alternative Variety, Same Length Match', 'Search for a Alternative Variety, Different Length Match'],
    identifyGaps: {
        column: 'color',
        conditional: 'greater_than',
        value: 500,
        groupBy: ["color", "variety", "grade", "category", "farm_ship_date"]
    }
}