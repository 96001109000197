import { createNamedStyles } from '@/shared/interfaces/create_name_styles';

export const AdminSettingsStyles = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    marginBottom: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    fontWeight: '600',
    margin: '0px 10px',
  },
  boxItem: {
    width: { xs: '100%', sm: '50%', md: '33.33%', lg: '25%', xl: '20%' },
    height: 'auto',
    padding: '10px',
    boxSizing: 'border-box',
  },
});
