import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { UseISalesOrderAllocationState } from './useSalesOrderAllocation.interfaces';

export const UseSalesOrderAllocationState =
  (): UseISalesOrderAllocationState => {
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    return {
      openModal,
      handleCloseModal,
      handleOpenModal,
    };
  };
