export const AGING_BY_VARIETY = {
  TITLE: 'Aging By Variety',
  DOWNLOAD: 'Export',
  BUTTON_HIDE: 'Hide',
  BUTTON_SEARCH: 'Search',
  TOTAL: 'TOTAL',
  EDIT_ROTATION_DAYS: 'Rotation',
  BUTTON_ACTIONS: 'Quick Actions',
  DISPOSAL_TRENDS: 'View Disposal Trends',
  DISEASE_TRENDS: 'View Disease Trends',
  LATEST_UPDATES: 'View Latest Updates',
};
