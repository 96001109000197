import { useAppSelector } from "@/store/hooks/storeHook"
import { RootState } from "@/store/interfaces/store.interfaces"
import { useEffect, useState } from "react"

export const UseStateAvailabilityModal = () => {
    const { availability } = useAppSelector((store: RootState) => store.actionPlan)
    const [searchAvailability, setSearchAvailability] = useState("")
    const [filteredAvailability, setFilterAvailability] = useState([])


    const adjustedRowCount = (filteredAvailability || []).reduce((count: number, row: any, index: number) => {
        return count + 1
    }, 0)

    const matchesSearch = (item: any, value: string) => {
        const search = value.toLowerCase().split(/\s+/);
        return search.every(word =>
            item.variety?.toLowerCase().includes(word) ||
            item.category?.toLowerCase().includes(word) ||
            item.color?.toLowerCase().includes(word) ||
            item.atp?.toString().includes(word) ||
            item.grade?.toString().includes(word)
        );
    };


    const getRowData = (index: number) => {
        return availability[index] || null;
    };


    useEffect(() => {
        if (availability && availability.length > 0) {
            setFilterAvailability(availability)
        }
    }, [availability])

    useEffect(() => {
        const filtered = availability.filter(item =>
            matchesSearch(item, searchAvailability)
        );
        setFilterAvailability(filtered)

    }, [searchAvailability])

    return {
        filteredAvailability,
        adjustedRowCount,
        searchAvailability,
        setSearchAvailability,
        getRowData
    }
}