import React, { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from "@/store/store";
import { ISubstitutionOption, IVariety, IBaseProductData, IVarietySubstitutions, IRow, ICell, IProduct } from "./useVarietySubstitutions.interfaces"
import { COLORS, GRADES, SUBSTITUTION, MAX_IMAGE_SIZE } from "@/shared/constantes/VarietySubstitutions";
import { getSubstitutions, uploadImage, getProducts, updateSubstitutions } from "@/store/thunks/Substitutions/substitutions.thunks";
import { convertToBase64 } from "@/shared/utils/convertToBase64";
import { getAging } from "@/store/thunks/AgingByVariety/agingByVariety.thunks";
import { IProductData } from "@/shared/interfaces/substitutions.interfaces";
import { UseStateSidebarSubstitutionMatrix } from "../../SidebarSubstitutionMatrix/state/useStateSidebarSubstitution";
import { getConfiguration } from "@/store/thunks/AllocationPriorities/allocationPriorities.thunks";

const useSubstitutionMatrix = (productData: IProductData, setProductData: (dat: IProductData | null) => void): IVarietySubstitutions => {

    const dispatch = useAppDispatch();
    const {
        selectedFiltersStore,
    } = useAppSelector((store: RootState) => store.substitutionMatrix);

    const {
        setSelectedValues,
    } = UseStateSidebarSubstitutionMatrix(productData);

    const [baseProductData, setbaseProductData] = useState<IBaseProductData>({} as IBaseProductData);
    const [varieties, setVarieties] = useState<IVariety[]>([]);
    const [originalVarieties, setOriginalVarieties] = useState<IVariety[]>([]);
    const [sizes, setSizes] = useState<string[]>([]);
    const [rows, setRows] = useState<IRow[]>([]);
    const [originalRows, setOriginalRows] = useState<IRow[]>([]);
    const [file, setFile] = useState<File | null>(null);
    const [varitiesList, setVarietiesList] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [currentGrade, setCurrentGrade] = useState<string[]>([]);
    const [displayBackup, setDisplayBackup] = useState<boolean>(false);
    const [gradeLimits, setGradeLimits] = useState<number[] | null>([]);
    const [warning, setWarning] = useState<string>('');
    const [changingGrade, setChangingGrade] = useState<{ oldGrade: null | Number, newGrade: null | Number } | null>(null);
    const [readyToLeave, setReadyToLeave] = useState<boolean>(true);

    //Push notification state
    const [pushNotificationMessage, setPushNotificationMessage] = useState<string>('');
    const [openPushNotification, setOpenPushNotification] = useState<boolean>(false);
    const [autoHideDuration, setAutoHideDuration] = useState<number | undefined>(undefined);
    const [showUndoAction, setShowUndoAction] = useState<boolean>(false);
    const [showSearchNewVariety, setShowSearchNewVariety] = useState<boolean>(false);

    const fetchingRef = useRef(false);


    const generateVarietyRows = (
        products: { product_id: string; variety: string; grade: string }[],
        substitutions: any[],
        grades: number[],
        varietiesList: { id: number; variety: string; short_color: string }[]
    ): IRow[] => {
        return varietiesList.map((varietyObj) => {
            const { id, variety, short_color } = varietyObj;

            let baseVarietyProducts = products.filter((product) => product.variety === variety);
            let baseVarietySubstitutions = substitutions.filter((substitution) => substitution.variety === variety);

            const rows: ICell[] = grades.map((grade) => {
                let product_id: number | null = null;
                let selected = false;

                for (const key in baseVarietyProducts) {
                    if (baseVarietyProducts[key].grade &&
                        parseInt(baseVarietyProducts[key].grade) === grade
                    ) {
                        product_id = parseInt(baseVarietyProducts[key].product_id);
                        break;
                    }
                }

                selected = baseVarietySubstitutions.some((substitution) =>
                    substitution.products.some(
                        (p: IProduct) =>
                            (p.grade === grade && product_id !== null) ||
                            p.product_id === product_id
                    )
                );

                return { product_id, grade, selected };
            });
            const newRow: IRow = {
                id,
                variety,
                short_color,
                rows
            }
            return newRow
        });
    };

    const generateUpdateData = (rows: IRow[], product_id: number): any => {
        let data = {
            product_id: product_id,
            sub2: [],
            sub3: [],
            sub4: [],
            sub5: []
        }
        rows.forEach((row, index) => {

            let products: IProduct[] = [];
            row.rows.forEach((cell) => {
                if (cell.selected) {
                    products.push({ product_id: cell.product_id, grade: cell.grade });
                }
            });

            // @ts-ignore
            data[`sub${index + 2}`] = products;
        });
        return data;
    }

    const fetchData = async (gradeToSearch: any) => {
        setPushNotificationMessage(SUBSTITUTION.FETCHING_DATA)
        setAutoHideDuration(undefined)
        setOpenPushNotification(true)
        const grades = GRADES[productData.category as keyof typeof GRADES];
        let limits: number[] = [];

        const gradeLimitsRes = await dispatch(getConfiguration({ id: 'sizeRanges' }));
        if (gradeLimitsRes && gradeLimitsRes.payload && gradeLimitsRes.payload.configuration_value.find((x: any) => x.category === productData.category)) {
            const rangeDelta = parseInt(gradeLimitsRes.payload.configuration_value.find((x: any) => x.category === productData.category).range.replace('+-', ''));
            limits = [parseInt(gradeToSearch[0]) + rangeDelta, parseInt(gradeToSearch[0]) - rangeDelta];
        }
        const resSubstitutions = await dispatch(getSubstitutions({
            body: {
                variety_name: [productData.variety],
                grade: gradeToSearch
            }
        }));
        let varietiesList: IVariety[] = []
        let rows: IRow[] = []
        let newVarietiesList: string[] = [];
        // @ts-ignore
        if (resSubstitutions && resSubstitutions.payload && resSubstitutions.payload.substitutions.length > 0) {
            // @ts-ignore
            let data = resSubstitutions.payload.substitutions[0];
            setbaseProductData({
                variety: data.variety,
                primary_color: data.primary_color,
                category: data.category,
                product_id: data.product_id
            })

            let productSubstitutions = data.productSubstitutions.slice(1, data.productSubstitutions.length).filter((substitutionOption: ISubstitutionOption) => substitutionOption != null);

            varietiesList = productSubstitutions.map((variety: ISubstitutionOption, index: number) => {
                return {
                    id: index,
                    variety: variety.variety,
                    short_color: COLORS[variety.short_color as keyof typeof COLORS] || "#9C0017",
                }
            });

            let varietiesListNames = varietiesList.map((variety: IVariety) => variety.variety);
            const resVarietiesListProducts = await dispatch(getProducts({ body: { variety_name: varietiesListNames } }));
            // @ts-ignore
            rows = generateVarietyRows(Object.values(resVarietiesListProducts.payload), productSubstitutions, grades, varietiesList);


            const resVarietiesList = await dispatch(getAging());
            if (resVarietiesList && resVarietiesList.payload) {
                // @ts-ignore
                newVarietiesList = resVarietiesList.payload.filter(x => x.variety !== productData.variety && !varietiesList.some((v: IVariety) => v.variety == x.variety)).map((variety: any) => variety.variety);
            }
            setOpenPushNotification(false)
        }
        // @ts-ignore
        else if (resSubstitutions && resSubstitutions.payload && resSubstitutions.payload.substitutions.length === 0) {
            setbaseProductData({} as IBaseProductData)
            setOpenPushNotification(true)
            setPushNotificationMessage(SUBSTITUTION.NO_DATA_FOUND)
            setAutoHideDuration(2000)
        }
        else {
            setbaseProductData({} as IBaseProductData)
            setOpenPushNotification(true)
            setPushNotificationMessage(SUBSTITUTION.ERROR_FETCHING)
            setAutoHideDuration(6000)
        }
        setGradeLimits(limits.sort((a, b) => b - a));
        setVarietiesList([...newVarietiesList])
        setVarieties([...varietiesList]);
        setOriginalVarieties(JSON.parse(JSON.stringify(varietiesList)));
        setRows([...rows]);
        setOriginalRows(JSON.parse(JSON.stringify(rows)));
        fetchingRef.current = false;

    }

    useEffect(() => {
        const grades = GRADES[productData.category as keyof typeof GRADES];

        if ((currentGrade &&
            selectedFiltersStore.grade && selectedFiltersStore.grade[0] &&
            currentGrade[0] !== selectedFiltersStore.grade[0].replace('cm', '')) &&
            !readyToLeave &&
            ((originalVarieties.length > 0 && varieties.length == 0) || (rows.length > 0 && JSON.stringify(rows) !== JSON.stringify(originalRows)) || (varieties.length > 0 && JSON.stringify(varieties) !== JSON.stringify(originalVarieties)))) {
            setWarning(SUBSTITUTION.CHANGES_NOT_SAVED);
            setChangingGrade({ oldGrade: parseInt(currentGrade[0]), newGrade: parseInt(selectedFiltersStore.grade[0]) });
            return;
        }

        setSizes(grades.map((x: number) => x + 'cm'));
        if (selectedFiltersStore.grade && selectedFiltersStore.grade.length > 0) {
            if (currentGrade[0] !== selectedFiltersStore.grade[0].replace('cm', '')) {
                setCurrentGrade(selectedFiltersStore.grade.map(x => x.replace('cm', '')))
            }
        } else {
            setCurrentGrade([String(grades[0])])
            setSelectedValues({ ...selectedFiltersStore, grade: [String(grades[0]) + 'cm'] })
        }

    }, [selectedFiltersStore])

    useEffect(() => {
        if (currentGrade.length > 0 && !fetchingRef.current) {
            fetchData(currentGrade);
            fetchingRef.current = true;
        }
    }, [currentGrade])

    const handleRemoveVariety = async (id: number) => {
        setVarieties(varieties.filter((variety) => variety.id !== id))
        setRows(rows.filter((_, index) => index !== id))

        let newVarietiesList = [...varitiesList];
        if (!newVarietiesList.some((variety) => varieties[id].variety === variety)) {
            newVarietiesList.push(varieties[id].variety);
        }
        setVarietiesList(newVarietiesList);
        setReadyToLeave(false);
    }

    const handleClickCell = (row: number, column: number) => {
        let newRows = [...rows];
        newRows[row].rows[column].selected = !newRows[row].rows[column].selected;
        setRows(newRows);

        if (gradeLimits && (rows[row].rows[column].grade < gradeLimits[1] || rows[row].rows[column].grade > gradeLimits[0])) {
            if (newRows[row].rows[column].selected) {
                setPushNotificationMessage(SUBSTITUTION.GRADE_OUT_OF_RANGE);
                setAutoHideDuration(7000);
                setOpenPushNotification(true);
            }
        }

        if (!newRows[row].rows.some(cell => cell.selected)) {
            handleRemoveVariety(varieties[row].id);
        }

        setReadyToLeave(false);
    }


    const handleApplyChanges = async () => {
        const hasUnselectedRow = rows.some(row => !row.rows.some(cell => cell.selected));
        if (hasUnselectedRow) {
            setPushNotificationMessage(SUBSTITUTION.WARNING_UNSELECTED_ROW + varieties[rows.findIndex(row => !row.rows.some(cell => cell.selected))].variety);
            setAutoHideDuration(3000);
            setOpenPushNotification(true);
            return;
        }
        const resUpdate = await dispatch(updateSubstitutions({ body: [generateUpdateData(rows, baseProductData.product_id)] }));

        if (resUpdate && resUpdate.payload) {
            setPushNotificationMessage(SUBSTITUTION.UPDATED_SUCCESSFULLY)
            setShowUndoAction(true)
            setReadyToLeave(true);
        } else {
            setPushNotificationMessage(SUBSTITUTION.ERROR_FETCHING)
        }
        setAutoHideDuration(6000)
        setOpenPushNotification(true)
    }

    const handleUndoChanges = async () => {
        const resUpdate = await dispatch(updateSubstitutions({ body: [generateUpdateData(originalRows, baseProductData.product_id)] }));
        if (resUpdate && resUpdate.payload) {
            setPushNotificationMessage(SUBSTITUTION.CHANGES_REVERTED);
            setVarieties(JSON.parse(JSON.stringify(originalVarieties)));
            setRows(JSON.parse(JSON.stringify(originalRows)));
            setReadyToLeave(true);
        } else {
            setPushNotificationMessage(SUBSTITUTION.ERROR_FETCHING);
        }

        setShowUndoAction(false)
        setAutoHideDuration(3000);
        setOpenPushNotification(true);
    }

    const handleUploadImage = async (file: File) => {
        if (file.type !== "image/png") {
            setPushNotificationMessage(SUBSTITUTION.INVALID_FILE_TYPE)
            setAutoHideDuration(3000)
            setOpenPushNotification(true)
            return;
        }

        const maxSize = MAX_IMAGE_SIZE * 1024 * 1024;
        if (file.size > maxSize) {
            setPushNotificationMessage(SUBSTITUTION.FILE_SIZE_EXCEEDS)
            setAutoHideDuration(3000)
            setOpenPushNotification(true)
            return;
        }

        try {
            const base64Image = await convertToBase64(file);
            setPushNotificationMessage(SUBSTITUTION.UPLOADING_IMAGE)
            setAutoHideDuration(undefined)
            setOpenPushNotification(true)
            const res = await dispatch(uploadImage({ body: { varietyName: productData.variety.replace(/ /g, '-'), imageBase64: base64Image.split(",")[1] } }));
            if (!res.payload) {
                setPushNotificationMessage(SUBSTITUTION.UPLOAD_FAILED)
                setAutoHideDuration(3000)
            } else {
                setPushNotificationMessage(SUBSTITUTION.UPLOAD_SUCCESS)
                setAutoHideDuration(3000)
                setDisplayBackup(false)
            }
        } catch (error) {
            setPushNotificationMessage(SUBSTITUTION.UPLOAD_FAILED)
            setAutoHideDuration(3000)
        } finally {
            setFile(null);
            setOpenPushNotification(true)
        }
    };

    useEffect(() => {
        if (file) {
            handleUploadImage(file);
        }
        setFile(null);
    }, [file]);


    const handleSubmitSearch = async (variety: string) => {
        const resOptions = await dispatch(getProducts({ body: { variety_name: [variety] } }));
        if (resOptions && resOptions.payload) {
            const firstProduct = Object.values(resOptions.payload)[0];
            const newVariety = {
                id: varieties.length,
                variety: variety,
                short_color: COLORS[firstProduct.short_color as keyof typeof COLORS] || "#9C0017",
            }
            let newVarities = [...varieties];
            newVarities.push(newVariety);
            setVarieties(newVarities);

            const grades = GRADES[baseProductData.category as keyof typeof GRADES];
            const newRow = generateVarietyRows(Object.values(resOptions.payload), [], grades, [newVariety]);

            setRows([...rows, newRow[0]]);
            setShowSearchNewVariety(false);
            setInputValue('');
            setReadyToLeave(false);
        }
    }

    const handleBackToMaster = () => {
        if (!readyToLeave &&
            ((originalVarieties.length > 0 && varieties.length == 0) ||
                (rows.length > 0 && JSON.stringify(rows) !== JSON.stringify(originalRows)) ||
                (varieties.length > 0 && JSON.stringify(varieties) !== JSON.stringify(originalVarieties)))) {
            setWarning(SUBSTITUTION.CHANGES_NOT_SAVED);
            return;
        }

        let newSelectedValues: Partial<typeof selectedFiltersStore> = { ...selectedFiltersStore };
        if ('grade' in newSelectedValues) {
            delete newSelectedValues.grade;
        }
        setSelectedValues(newSelectedValues);
        setProductData(null);
    }

    useEffect(() => {
        if (!openPushNotification) {
            setShowUndoAction(false)
        }
    }, [openPushNotification])

    const handleChangeGrade = (action: string) => {
        if (action === 'forward') {
            setSelectedValues({ ...selectedFiltersStore, grade: [String(changingGrade?.newGrade) + 'cm'] })
            setReadyToLeave(true);
        } else {
            setSelectedValues({ ...selectedFiltersStore, grade: [String(changingGrade?.oldGrade) + 'cm'] })
        }
        setChangingGrade(null);
    }

    return {
        gradeLimits,
        varitiesList,
        baseProductData,
        sizes,
        varieties,
        rows,
        openPushNotification,
        pushNotificationMessage,
        autoHideDuration,
        showUndoAction,
        showSearchNewVariety,
        inputValue,
        currentGrade,
        displayBackup,
        warning,
        changingGrade,
        handleSubmitSearch,
        setFile,
        setVarieties,
        setRows,
        handleApplyChanges,
        handleRemoveVariety,
        handleClickCell,
        setOpenPushNotification,
        setShowSearchNewVariety,
        setInputValue,
        handleUndoChanges,
        handleBackToMaster,
        setDisplayBackup,
        setWarning,
        handleChangeGrade,
        setReadyToLeave
    }

}
export default useSubstitutionMatrix