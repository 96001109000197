import { CONFIGURATIONS } from "@/shared/constantes/AdmingSettings";
import { useAppDispatch } from "@/store/hooks/storeHook";
import { getConfiguration } from "@/store/thunks/AllocationPriorities/allocationPriorities.thunks";
import { useEffect } from "react";

export const UseAllocationPrioritiesState = () => {
    const dispatch = useAppDispatch();
    useEffect(()=>{
        dispatch(getConfiguration({ id: CONFIGURATIONS.BUSINESS_RULES }));
    }, [])
}