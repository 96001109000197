import React from "react"
import { UseSidebarActionPlan } from "./state/UseSidebarActionPlan"
import Dates from "../Dates/Dates"
import { Box, FormControl, FormControlLabel, Link, Radio, RadioGroup, Typography } from "@mui/material"
import { sidebarStyles as styles } from "../../shared/styles/sidebar.styles"
import { ACTION_PLAN } from "../../shared/constantes/ActionPlan"
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FilterComponent from "../Filters/FilterComponent"

const SidebarActionPlan = () => {
    const {
        startDate,
        endDate,
        filters,
        selectedValues,
        setSelectedValues
    } = UseSidebarActionPlan()

    const quickActions = [
        {
            id: "",
            title: ACTION_PLAN.VARIETY_ACTION,
            active: false
        },
        {
            title: ACTION_PLAN.WEEK_ACTION,
            active: true
        },
        {
            title: ACTION_PLAN.PACKING_ACTION,
            active: false
        },
        {
            title: ACTION_PLAN.ALERTS_ACTION,
            active: false
        }
    ]
    return (
        <>
            <Dates
                startDate={startDate}
                endDate={endDate}
                handleSearch={() => { }}
                handleSetEndDate={() => { }}
                handleSetStartDate={() => { }}
            />

            <Box sx={styles.sidebarBox}>
                <Typography sx={styles.textActions}>
                    {ACTION_PLAN.BUTTON_ACTIONS}
                </Typography>
                {
                    quickActions.map((item) => {
                        return (
                            <Box sx={styles.box}>
                                <Link onClick={() => { }}>
                                    <Typography sx={styles.text}>
                                        {item.title}
                                    </Typography>
                                </Link>
                                {item.active &&
                                    (
                                        <ClearOutlinedIcon
                                            onClick={() => { }}
                                            sx={{ width: '15px', color: '#927C4E' }}
                                        />
                                    )
                                }
                            </Box>
                        )
                    })
                }
            </Box>
            <Box sx={{ margin: '5px', backgroundColor: 'white', padding: '2px' }} />
            <Box sx={styles.sidebarBox}>
                <FormControl>
                    <RadioGroup>
                        <FormControlLabel
                            key={"key"}
                            value=""
                            control={<Radio size="small" />}
                            label={<Typography sx={{ fontSize: '12px' }}>{ACTION_PLAN.ACCTION_PLAN_VARIETY}</Typography>}
                        />
                        <FormControlLabel
                            key={"key"}
                            value=""
                            control={<Radio size="small" />}
                            label={<Typography sx={{ fontSize: '12px' }}>{ACTION_PLAN.ACCTION_PLAN_CUSTOMER}</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <FilterComponent
                data={filters}
                showHeader={true}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
            />
        </>

    )

}
export default SidebarActionPlan