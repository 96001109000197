import { useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

const SearchBar = ({ handleSearchValeu, handleSubmit }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event) => {
    handleSearchValeu(event.target.value);
    setSearchTerm(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="search-bar"
        className="text"
        label="Search by Keyword..."
        variant="outlined"
        placeholder="Search by Keyword"
        size="small"
        value={searchTerm}
        onChange={handleChange}
        style={{ width: '100%', height: '1%', backgroundColor: 'white' }}
        InputProps={{
          endAdornment: (
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </form>
  );
};
export default SearchBar;
