import React, { useState } from 'react';
import {
  Alert,
  Box,
  Table,
  Paper,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  IconButton,
} from '@mui/material';
import TableHeader from '../Table/TableHeader';
import CircularProgress from '@mui/material/CircularProgress';
import data from '../../shared/data/sales_orders_allocation_header.json';
import { UseSalesOrdersAllocationState } from './state/useSalesOrdersAllocationState';
import SearchBar from '../SearchBar/SearchBar';
import {
  Download,
  LessIcon,
  OpenModal,
  PlusIcon,
  SearchBarIcon,
  ShowColumns,
} from '../../shared/icons/Icons';
import { salesOrderAllocationTableStyles as styles } from './SalesOrderAllocationTable.styles';
import { SALES_ORDERS_ALLOCATION } from '../../shared/constantes/SalesOrdersAllocation';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { IheadCell } from '../Table/TableHeader.interfaces';
import { convertNumber } from '../../shared/utils/numbersUtils';
import HideColumns from '../HideColumns/HideColumns';
import PopoverComponent from '../PopoverComponent/PopoverComponent';

const columns: IheadCell[] = data.data;

const cellStyle = (row) => ({
  fontSize: '12px',
  padding: '10px 10px',
  backgroundColor: row.missingStems > 0 ? '#FDEDEC' : '#FFF',
  border: '1px solid #E0E0E0 ',
  whiteSpace: 'nowrap',
});

const cellBreakdownsStyle = (row) => ({
  fontSize: '11px',
  padding: '10px 10px',
  backgroundColor: row.missingStems > 0 ? '#FDEDEC' : '#FBFAF9',
  border: '1px solid #f4f2ef ',
});

export interface ISalesOrderAllocationTable {
  handleOpenModal: () => void;
}

const SalesOrderAllocationTable: React.FC<ISalesOrderAllocationTable> = ({
  handleOpenModal,
}) => {
  const {
    handleFarmDate,
    allocations,
    loading,
    handleSort,
    sortBy,
    visible,
    handleShowSearchBar,
    showSearchBar,
    expandedRow,
    handleShowBreakdowns,
    isExpandedAll,
    handleDownloadCsv,
    handleSubmit,
    handleSearchValue,
    handleRoundOut,
    salesOrdersColumns,
  } = UseSalesOrdersAllocationState();

  const handleExpande = (value, index) =>
    value === 'Assorted' && (
      <IconButton
        data-testid="icon"
        onClick={(event) => handleShowBreakdowns(event, index)}
      >
        {!(expandedRow === index) && !isExpandedAll ? (
          <PlusIcon data-testid="plus-icon" />
        ) : (
          <LessIcon data-testid="less-icon" />
        )}
      </IconButton>
    );

  const [visibleColumns, setVisibleColumns] = useState(
    columns.reduce((acc, column) => {
      acc[column.field] = true;
      return acc;
    }, {})
  );

  const handleToggleColumn = (columnId) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  const handleTotal = (totalBoxes) => {
    if (
      totalBoxes === 'totalBoxes' ||
      totalBoxes === 'totalStems' ||
      totalBoxes === 'allocatedStems' ||
      totalBoxes === 'missingStems'
    ) {
      const totalGrade = allocations.reduce(
        (acc, row) => acc + (row[totalBoxes] || 0),
        0
      );
      return convertNumber(totalGrade);
    }
  };

  return (
    <>
      {visible && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '3px',
          }}
        >
          <Alert sx={{ width: '100%' }} severity="success">
            Viewing default sort: Guidance, Order Type and Price
          </Alert>
        </Box>
      )}
      <Box sx={styles.box}>
        <Box sx={styles.text}>
          {`${allocations && allocations.length} results`}
        </Box>
        <Box sx={styles.boxIcons}>
          {showSearchBar ? (
            <Box sx={styles.search}>
              <SearchBar
                handleSearchValeu={handleSearchValue}
                handleSubmit={handleSubmit}
              />
            </Box>
          ) : (
            <TooltipIcon
              title={SALES_ORDERS_ALLOCATION.BUTTON_SEARCH}
              handleClick={handleShowSearchBar}
              icon={<SearchBarIcon />}
            />
          )}
          <PopoverComponent
            children={
              <HideColumns
                columnsName={columns}
                handleToggleColumn={handleToggleColumn}
                visibleColumns={visibleColumns}
              />
            }
            title={SALES_ORDERS_ALLOCATION.BUTTON_HIDE}
            icon={<ShowColumns />}
          />
          <TooltipIcon
            title={SALES_ORDERS_ALLOCATION.BUTTON_EXPORT}
            icon={<Download />}
            handleClick={handleDownloadCsv}
          />
          <TooltipIcon
            title={SALES_ORDERS_ALLOCATION.BUTTON_REVENUE}
            icon={<OpenModal />}
            handleClick={handleOpenModal}
          />
        </Box>
      </Box>
      {!loading ? (
        <TableContainer component={Paper} style={{ maxHeight: '75vh' }}>
          <Table stickyHeader>
            <TableHeader
              headCell={columns}
              handleSort={handleSort}
              sortBy={sortBy}
              visibleColumns={visibleColumns}
            />
            <TableBody>
              <TableRow sx={{ position: 'sticky', top: '45px', zIndex: 999 }}>
                {salesOrdersColumns.map((column) => (
                  <>
                    {visibleColumns[column] && (
                      <TableCell
                        sx={styles.total}
                        style={
                          column === 'accountName'
                            ? {
                                position: 'sticky',
                                left: '0',
                                zIndex: 1,
                              }
                            : {}
                        }
                      >
                        {column === 'accountName'
                          ? 'TOTAL'
                          : handleTotal(column)}
                      </TableCell>
                    )}
                  </>
                ))}
              </TableRow>
              {allocations &&
                allocations.length > 0 &&
                allocations.map((row, index) => {
                  return (
                    <React.Fragment key={`${index}-table`}>
                      <TableRow key={index}>
                        {visibleColumns['accountName'] && (
                          <TableCell
                            style={{
                              position: 'sticky',
                              left: '0',
                              zIndex: 1,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '90px',
                            }}
                            sx={cellStyle(row)}
                          >
                            {row.accountName}
                          </TableCell>
                        )}
                        {visibleColumns['market'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.market}
                          </TableCell>
                        )}
                        {visibleColumns['guidance'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.guidance}
                          </TableCell>
                        )}
                        {visibleColumns['accountOwner'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.accountOwner}
                          </TableCell>
                        )}
                        {visibleColumns['locationCode'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.locationCode}
                          </TableCell>
                        )}
                        {visibleColumns['weekNumber'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.weekNumber}
                          </TableCell>
                        )}
                        {visibleColumns['farmDate'] && (
                          <TableCell sx={cellStyle(row)}>
                            {handleFarmDate(row.farmDate)}
                          </TableCell>
                        )}
                        {visibleColumns['weekDay'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.weekDay}
                          </TableCell>
                        )}
                        {visibleColumns['truckDate'] && (
                          <TableCell sx={cellStyle(row)}>
                            {handleFarmDate(row.truckDate)}
                          </TableCell>
                        )}
                        {visibleColumns['orderType'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.orderType}
                          </TableCell>
                        )}
                        {visibleColumns['prebookId'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.prebookId}
                          </TableCell>
                        )}
                        {visibleColumns['purchaseOrderNumber'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.purchaseOrderNumber}
                          </TableCell>
                        )}
                        {visibleColumns['category'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.category}
                          </TableCell>
                        )}
                        {visibleColumns['color'] && (
                          <TableCell sx={cellStyle(row)}>{row.color}</TableCell>
                        )}
                        {visibleColumns['variety'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.variety}
                          </TableCell>
                        )}
                        {visibleColumns['grade'] && (
                          <TableCell sx={cellStyle(row)}>{row.grade}</TableCell>
                        )}
                        {visibleColumns['apsLfd'] && (
                          <TableCell sx={cellStyle(row)}>
                            {handleRoundOut(row.apsLfd)}
                          </TableCell>
                        )}
                        {visibleColumns['unitPrice'] && (
                          <TableCell sx={cellStyle(row)}>
                            {convertNumber(row.unitPrice)}
                          </TableCell>
                        )}
                        {visibleColumns['boxComposition'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.boxComposition}
                            {handleExpande(row.boxComposition, index)}
                          </TableCell>
                        )}
                        {visibleColumns['boxType'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.boxType}
                          </TableCell>
                        )}
                        {visibleColumns['totalBoxes'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.totalBoxes}
                          </TableCell>
                        )}
                        {visibleColumns['markCode'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.markCode}
                          </TableCell>
                        )}
                        {visibleColumns['lineItemStatus'] && (
                          <TableCell sx={cellStyle(row)}>
                            {row.lineItemStatus}
                          </TableCell>
                        )}
                        {visibleColumns['totalStems'] && (
                          <TableCell sx={cellStyle(row)}>
                            {convertNumber(row.totalStems)}
                          </TableCell>
                        )}
                        {visibleColumns['allocatedStems'] && (
                          <TableCell sx={cellStyle(row)}>
                            {convertNumber(row.allocatedStems)}
                          </TableCell>
                        )}
                        {visibleColumns['missingStems'] && (
                          <TableCell
                            sx={cellStyle(row)}
                            style={{ color: row.missingStems > 0 ? 'red' : '' }}
                          >
                            {convertNumber(row.missingStems)}
                          </TableCell>
                        )}
                        {/*<TableCell sx={cellStyle(row)}>{row.detailHash}</TableCell>*/}
                      </TableRow>
                      {row.breakdowns &&
                        (expandedRow === index || isExpandedAll) &&
                        row.breakdowns.map((item, indexBreak) => {
                          return (
                            <TableRow
                              key={`${index}-${indexBreak}`}
                              sx={{ borderBottom: '1px solid red' }}
                            >
                              {visibleColumns['accountName'] && (
                                <TableCell
                                  style={{
                                    position: 'sticky',
                                    left: '0',
                                    zIndex: 1,
                                  }}
                                  sx={cellBreakdownsStyle(item)}
                                >
                                  {row.accountName}
                                </TableCell>
                              )}
                              {visibleColumns['market'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {row.market}
                                </TableCell>
                              )}
                              {visibleColumns['guidance'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {row.guidance}
                                </TableCell>
                              )}
                              {visibleColumns['accountOwner'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {row.accountOwner}
                                </TableCell>
                              )}
                              {visibleColumns['locationCode'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {row.locationCode}
                                </TableCell>
                              )}
                              {visibleColumns['weekNumber'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {row.weekNumber}
                                </TableCell>
                              )}
                              {visibleColumns['farmDate'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {handleFarmDate(row.farmDate)}
                                </TableCell>
                              )}
                              {visibleColumns['weekDay'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {row.weekDay}
                                </TableCell>
                              )}
                              {visibleColumns['truckDate'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {handleFarmDate(row.truckDate)}
                                </TableCell>
                              )}
                              {visibleColumns['orderType'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {row.orderType}
                                </TableCell>
                              )}
                              {visibleColumns['prebookId'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {row.prebookId}
                                </TableCell>
                              )}
                              {visibleColumns['purchaseOrderNumber'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {item.purchaseOrderNumber}
                                </TableCell>
                              )}
                              {visibleColumns['category'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {item.category}
                                </TableCell>
                              )}
                              {visibleColumns['color'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {item.color}
                                </TableCell>
                              )}
                              {visibleColumns['variety'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {item.varietyBreakdown}
                                </TableCell>
                              )}
                              {visibleColumns['grade'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {item.grade}
                                </TableCell>
                              )}
                              {visibleColumns['apsLfd'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {handleRoundOut(item.apsLfd)}
                                </TableCell>
                              )}
                              {visibleColumns['unitPrice'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {item.priceByStem}
                                </TableCell>
                              )}
                              {visibleColumns['boxComposition'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {item.boxCompositionBreakdown}
                                </TableCell>
                              )}
                              {visibleColumns['boxType'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {row.boxType}
                                </TableCell>
                              )}
                              {visibleColumns['totalBoxes'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  -
                                </TableCell>
                              )}
                              {visibleColumns['markCode'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {row.markCode}
                                </TableCell>
                              )}
                              {visibleColumns['lineItemStatus'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {item.lineItemStatus}
                                </TableCell>
                              )}
                              {visibleColumns['totalStems'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {item.totalStems}
                                </TableCell>
                              )}
                              {visibleColumns['allocatedStems'] && (
                                <TableCell sx={cellBreakdownsStyle(item)}>
                                  {item.allocatedStems}
                                </TableCell>
                              )}
                              {visibleColumns['missingStems'] && (
                                <TableCell
                                  sx={cellBreakdownsStyle(item)}
                                  style={{
                                    color: item.missingStems > 0 ? 'red' : '',
                                  }}
                                >
                                  {item.missingStems}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
export default React.memo(SalesOrderAllocationTable);
