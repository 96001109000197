import { setSelectedFiltersStore } from "@/store/actions/substitutionMatrix/substitutionMatrix";
import { useAppDispatch, useAppSelector } from "@/store/hooks/storeHook";
import { IAging } from "@/store/interfaces/filtersAging.interface";
import { RootState } from "@/store/store";
import { transformFiltersAction } from "@/store/utils/storeUtils";
import { useMemo, useState } from "react";
import { IUseStateSidebarSubstitutionMatrix } from "./useStateSidebarSubstitution.interfaces";
import { IProductData } from "@/shared/interfaces/substitutions.interfaces";
import { GRADES } from "@/shared/constantes/VarietySubstitutions";

export const UseStateSidebarSubstitutionMatrix = (productData: IProductData | null): IUseStateSidebarSubstitutionMatrix => {
    const dispatch = useAppDispatch();
    const selectedValues = useAppSelector((store: RootState) => store.substitutionMatrix.selectedFiltersStore);
    const setSelectedValues = (values: any) => {
        dispatch(setSelectedFiltersStore(values));
    };

    const { aging } = useAppSelector((store: RootState) => store.agingByVariety);
    const [filterData, setFilterData] = useState<IAging>({
        category: [],
        variety: [],
        short_color: [],
        grade: [],
    });

    const generateFilterData = (aging: any): IAging => {
        const data: IAging = {
            category: Array.from(new Set(aging.map((item: { category: string }) => item.category))),
            variety: Array.from(new Set(aging.map((item: { variety: string }) => item.variety))),
            short_color: Array.from(new Set(aging.map((item: { short_color: string }) => item.short_color))),
        };
        if (productData) {
            data.grade = Array.from(new Set(GRADES[productData.category as keyof typeof GRADES].map((item) => item + "cm")));
        }
        return data;
    };

    useMemo(() => {
        if (!filterData.variety.length && aging.length) {
            setFilterData(generateFilterData(aging));
        }
    }, [aging]);

    return {
        filters: transformFiltersAction(filterData, productData != null),
        selectedValues,
        setSelectedValues,
    };
};
