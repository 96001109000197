import { FiltersATP } from '../interfaces/filtersATP.interfaces';
import { FiltersAllocationData } from '../interfaces/filtersAllocation.interfaces';

export const transformFilters = (data: FiltersAllocationData) => {
  return [
    {
      id: 'accountName',
      filtername: 'Account Name',
      componentType: 'Checkbox',
      valueOptions: [...(data.accountName || [])].sort(),
    },
    {
      id: 'market',
      filtername: 'Market',
      componentType: 'Checkbox',
      valueOptions: [...(data.market || [])].sort(),
    },
    {
      id: 'guidance',
      filtername: 'Guidance',
      componentType: 'Checkbox',
      valueOptions: [...(data.guidance || [])].sort(),
    },
    {
      id: 'accountOwner',
      filtername: 'Account Owner',
      componentType: 'Checkbox',
      valueOptions: [...(data.accountOwner || [])].sort(),
    },
    {
      id: 'orderType',
      filtername: 'Order Type',
      componentType: 'Checkbox',
      valueOptions: [...(data.orderType || [])].sort(),
    },
    {
      id: 'flowerSpecies',
      filtername: 'Flower Species',
      componentType: 'Checkbox',
      valueOptions: [...(data.flowerSpecies || [])].sort(),
    },
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: [...(data.category || [])].sort(),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: [...(data.variety || [])].sort(),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: [...(data.color || [])].sort(),
    },
    {
      id: 'grade',
      filtername: 'Grade',
      componentType: 'Checkbox',
      valueOptions: [...(data.grade || [])].sort(),
    },
    {
      id: 'boxComposition',
      filtername: 'Assorted / Solid',
      componentType: 'Checkbox',
      valueOptions: [...(data.boxComposition || [])].sort(),
    },
  ];
};

export const transformFiltersATp = (data: FiltersATP, isAccumulate) => {
  return [
    /* {
            id: "datesType",
            filtername: "View Dates",
            componentType: "Checkbox",
            valueOptions: [...(data.datesType || [])].sort()
        }, */

    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: [...(data.category || [])].sort(),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: [...(data.variety || [])].sort(),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: [...(data.color || [])].sort(),
    },
    {
      id: 'grade',
      filtername: 'Grade',
      componentType: 'Checkbox',
      valueOptions: [...(data.grade || [])].sort(),
    },
    {
      id: 'inventoryType',
      filtername: 'Inventory',
      componentType: 'Checkbox',
      valueOptions: isAccumulate
        ? [
            'On-Hand Inventory',
            'Forecasted Exp. Production',
            'Expected Inventory',
            'Inventory Hold',
            'Expiring Inventory',
            'Final On-Hand Inventory',
            'Final Forecasted Exp. Production',
            'ATP Inventory',
          ].sort()
        : [
            'On-Hand Inventory',
            'Forecasted Exp. Production',
            'Expected Inventory',
            'Inventory Hold',
            'ATP Inventory',
          ].sort(),
    },
    {
      id: 'group',
      filtername: 'Group Results',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
  ];
};

export const transformFiltersAging = () => {
  return [
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'rotation',
      filtername: 'Rotation Days',
      componentType: 'Checkbox',
      valueOptions: ['1', '2', '3', '4'].sort(),
    },
  ];
};

export const filterData = (data) => {
  const newData = Object.fromEntries(
    Object.entries(data).filter((_, valor) => {
      return !(Array.isArray(valor) && valor.length === 0);
    })
  );
  return newData;
};

export const transformFiltersAction = () => {
  return [
    {
      id: 'category',
      filtername: 'Category',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'variety',
      filtername: 'Variety',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'color',
      filtername: 'Color',
      componentType: 'Checkbox',
      valueOptions: ['Category', 'Color', 'Variety', 'Grade'].sort(),
    },
    {
      id: 'grade',
      filtername: 'Grade',
      componentType: 'Checkbox',
      valueOptions: ['1', '2', '3', '4'].sort(),
    },
  ];
};
