import { createSlice } from '@reduxjs/toolkit';
import { ActionPlanInterface } from '../interfaces/actionPlan.interfaces';

export const initialState: ActionPlanInterface = {
  startDate: new Date(),
  endDate: new Date(),
};

export const actionPlanSlice = createSlice({
  name: 'Action Plan',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
  },
});

export default actionPlanSlice.reducer;
