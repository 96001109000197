import { createSlice } from '@reduxjs/toolkit';
import { AgingByVarietyLocalState } from '../interfaces/agingByVariety.interfaces';
import { getAging } from '../thunks/AgingByVariety/agingByVariety.thunks';

export const initialState: AgingByVarietyLocalState = {
  aging: [],
};

export const agingByVarietySlice = createSlice({
  name: 'AgingByVariety',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAging.fulfilled, (state, { payload }) => {
      state.aging = payload;
    });
  },
});

export default agingByVarietySlice.reducer;
