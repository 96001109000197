import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/storeHook';
import { RootState } from '../../../store/store';
import { useAvailableToPromiseInterface } from './useAvailableToPromiseState.interfaces';
import data from '../../../shared/data/available_to_promise_header.json';
import { IheadCell } from '../../Table/TableHeader.interfaces';
import {
  downloadCsv,
  getATP,
} from '../../../store/thunks/AvailableToPromise/availableToPromise.thunks';
import { formatDate, handleFarmDay } from '../../../shared/utils/dateUtils';
import { setSort } from '../../../store/actions/salesOrdersAllocation/salesOrdersAllocation.actions';
import { columnsDate, sortData } from '../../../shared/utils/numbersUtils';
import inventoryTypes from '../../../shared/data/inventory_type.json';
import inventryTypesAccumulated from '../../../shared/data/inventory_type_accumulated.json';
import { setIsAccumulate } from '../../../store/actions/availableToPromise/availableToPromise.actions';
export const UseAvailableToPromiseState =
  (): useAvailableToPromiseInterface => {
    const columns: IheadCell[] = data.data;
    const dispatch = useAppDispatch();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [headerDataTable, setheaderData] = useState<IheadCell[]>(columns);
    const {
      startDate,
      endDate,
      atp,
      filtersInventoryTypes,
      isAccumulate,
      isFilterGlobalData,
    } = useAppSelector((store: RootState) => store.availableToPromise);
    const {
      sortBy,
      sortDirection,
      filtersToSearch,
      isFilterGapsVariety,
      loading,
    } = useAppSelector((store: RootState) => store.salesOrders);
    const headerIds = useRef<string[]>([]);
    let searchValueATP = useRef('');
    const [visibleInventoryTypes, setVisibleInventoryTypes] = useState({});
    const [totals, seTotals] = useState<number[]>([]);

    const handleTotalByDate = () => {
      const inventories = atp.map((atp) => atp.inventory);
      const newTotals = headerIds.current.map((date) => {
        return inventories.reduce((total, item) => {
          return (
            total +
            (isAccumulate
              ? item[date]?.accumulatedAtp || 0
              : item[date]?.atp || 0)
          );
        }, 0);
      });
      seTotals(newTotals);
      return newTotals;
    };

    useEffect(() => {
      dispatch(getATP({ startDate, endDate, dispatch }));
      headerIds.current = columnsDate(startDate, endDate);
    }, []);

    useEffect(() => {
      handleTotalByDate();
    }, [isAccumulate]);

    useEffect(() => {
      const formattedDates = columnsDate(startDate, endDate);
      const dateObject = formattedDates.map((date) => {
        const dateObject = date.split('-');
        return {
          field: date,
          headerName: `${handleFarmDay(date)} ${dateObject[1]}/${dateObject[2]}`,
          hoverName: `${dateObject[1]}/${dateObject[2]}`,
        };
      });
      const total = {
        field: 'total',
        headerName: 'TOT',
        hoverName: 'Total',
      };
      const newHederData = [...data.data, ...dateObject, total];
      setheaderData(newHederData);
      headerIds.current = formattedDates;
      handleTotalByDate();
    }, [atp]);

    const handleDownloadATPCSV = () => {
      let newFilters = { ...filtersToSearch };
      if (filtersInventoryTypes.inventoryType.length > 0) {
        newFilters = {
          ...newFilters,
          inventoryType: filtersInventoryTypes.inventoryType,
        };
      }
      dispatch(
        downloadCsv({
          startDate,
          endDate,
          data: newFilters,
          accumulate: isAccumulate,
          missingStems: isFilterGapsVariety,
          runGlobalData: isFilterGlobalData,
        })
      );
    };

    const handleSort = (column) => {
      const newDirection =
        sortBy === column && sortDirection === 'asc' ? 'desc' : 'asc';
      dispatch(setSort({ column, newDirection }));
    };

    const sortedATP = useMemo(() => {
      const inventory: boolean = Boolean(
        sortBy && headerIds.current.includes(sortBy)
      );
      return sortData(atp, sortBy, sortDirection, inventory, headerIds.current);
    }, [atp, sortBy, sortDirection]);

    const handleSearchValue = (data) => (searchValueATP.current = data);

    const handleSubmit = (event) => {
      event.preventDefault();
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          query: searchValueATP.current,
          missingStems: isFilterGapsVariety,
          data: filtersToSearch,
        })
      );
    };

    const handleTotal = (inventory, inventoryType) => {
      let total = 0;
      headerIds.current.map(
        (date) =>
          (total += inventory[date] ? +inventory[date][inventoryType] : 0)
      );
      return total;
    };

    useEffect(() => {
      let newVisibility = {};
      if (
        (filtersInventoryTypes.inventoryType &&
          filtersInventoryTypes.inventoryType.length === 0) ||
        filtersInventoryTypes.inventoryType === undefined
      ) {
        if (isAccumulate) {
          inventryTypesAccumulated.forEach(
            (item) => (newVisibility[item.inventorytype] = true)
          );
        } else {
          inventoryTypes.forEach(
            (item) => (newVisibility[item.inventorytype] = true)
          );
        }
      } else {
        filtersInventoryTypes.inventoryType &&
          filtersInventoryTypes.inventoryType.forEach(
            (item) => (newVisibility[item] = true)
          );
      }
      setVisibleInventoryTypes(newVisibility);
    }, [filtersInventoryTypes, isAccumulate]);

    const handleGetPropAccumulate = (inventoryType: string) => {
      const accumulatedInventoryType =
        inventoryType.charAt(0).toUpperCase() + inventoryType.slice(1);
      return isAccumulate
        ? `accumulated${accumulatedInventoryType}`
        : inventoryType;
    };

    const handleChangeAccumulate = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => dispatch(setIsAccumulate(event.target.checked));

    return {
      loading,
      showSearchBar,
      setShowSearchBar,
      headerDataTable,
      atp: sortedATP,
      headerIds: headerIds.current,
      totals: totals,
      sortByATP: sortBy,
      columns,
      inventoryColumns: isAccumulate
        ? inventryTypesAccumulated
        : inventoryTypes,
      isAccumulate,
      visibleInventoryTypes,
      handleDownloadATPCSV,
      handleSort,
      handleSearchValue,
      handleSubmit,
      handleTotal,
      handleGetPropAccumulate,
      handleChangeAccumulate,
    };
  };
