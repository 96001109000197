import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { headboardStyle as styles } from './Headboard.styles.ts';

const Headboard = ({ title }) => (
  <Box sx={{ margin: '80px 8px 10px 20px' }}>
    <Grid container>
      <Grid item xs={12} md={12}>
        <Breadcrumb />
        <Typography sx={styles.Title}>{title}</Typography>
      </Grid>
    </Grid>
  </Box>
);
export default Headboard;
