import { ENABLE_OPTIONS_SIZE_SUBSTITUTION, SUBSTITUTION_SIZE } from "@/shared/constantes/AllocationPriorities";
import { Box, Button, FormControl, MenuItem, Select, Typography, Grid, Switch, Radio } from "@mui/material";
import React from "react";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import UseStateSubstitutionSize from "./state/useStateSizeSubstitution";
import categories from "@/shared/data/categories.json";
import Footer from "../Footer/Footer";
import { ISubstitutionRules } from "../../AllocationPriorities/state/useAllocationPriorities.interfaces";

const SizeSubstitution: React.FC<ISubstitutionRules> = ({ card, handleBackClick, handleContinuesClick, setOpenAlert }) => {
    const {
        handleAddSubstitutions,
        substitutionsOptions,
        handleCategoryChange,
        handleSubmit,
        handleRemoveSubstitutions,
        getAvailableCategories,
        saveAndContinues,
        getAvailableSize
    } = UseStateSubstitutionSize({ handleContinuesClick, setOpenAlert });

    const enableoptions = ENABLE_OPTIONS_SIZE_SUBSTITUTION
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <Typography>{SUBSTITUTION_SIZE.TITLE}</Typography>
            <Typography>{SUBSTITUTION_SIZE.SUB_TITLE}</Typography>
            <Box sx={{ paddingTop: '20px' }}>
                {substitutionsOptions.map((item, index) => (
                    <Grid key={`size-${index}`} container spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item sm={3} xs={12}>
                            <FormControl sx={{ m: 1, minWidth: 120, flex: 1 }} size="small" fullWidth>
                                <Select
                                    data-testid={"category"}
                                    id={`category-${index}`}
                                    value={item.category}
                                    onChange={(e: any) => handleCategoryChange(index, e, 'category')}
                                    sx={{ borderRadius: '1px' }}
                                >
                                    {getAvailableCategories(index).map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.content}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Typography sx={{ flex: 1, textAlign: "center" }}>{SUBSTITUTION_SIZE.TEXT}</Typography>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <FormControl sx={{ m: 1, minWidth: 120, flex: 1 }} size="small" fullWidth>
                                <Select
                                    data-testid={"size"}
                                    id={`size-${index}`}
                                    value={item.range}
                                    onChange={(e: any) => handleCategoryChange(index, e, 'range')}
                                    sx={{ borderRadius: '1px' }}
                                >
                                    {getAvailableSize(item).map((rangeItem: { content: string, id: string }) => (
                                        <MenuItem key={rangeItem.id} value={rangeItem.id}>
                                            {rangeItem.content}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            {substitutionsOptions.length > 1 && (
                                <Button
                                    data-testid={"removeSizeSubstitutionButton"}
                                    onClick={() => handleRemoveSubstitutions(index)}>
                                    <DoNotDisturbOnIcon />
                                </Button>
                            )}
                            {substitutionsOptions.length < categories.length && (
                                <Button
                                    data-testid={"addSizeSubstitutionButton"}
                                    onClick={handleAddSubstitutions}>
                                    <AddCircleOutlinedIcon />
                                </Button>
                            )
                            }
                        </Grid >
                    </Grid >
                ))
                }
            </Box>
            <Box sx={{ paddingTop: '20px' }}>
                {
                    enableoptions.map((option, key) => {
                        const [beforeComma, afterComma] = option.content.split(",");
                        return (
                            <Box
                                key={`option-${key}`}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '80%'
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>
                                        {beforeComma}
                                        <span style={{ fontWeight: '600' }}>{afterComma}</span>
                                    </Typography>
                                </Box>
                                {option.type === "Radio" ? (<Radio disabled />) : <Switch disabled defaultChecked />}
                            </Box>
                        )
                    })
                }
            </Box>
            <Box sx={{ marginTop: 3 }}>
                <Typography>
                    {SUBSTITUTION_SIZE.IF}
                    <span style={{ fontWeight: 700 }}>{SUBSTITUTION_SIZE.THEN}</span>
                </Typography>
            </Box>
            <Footer
                card={card}
                handleBackClick={handleBackClick}
                handleContinuesClick={saveAndContinues}
                handleSaveSettings={handleSubmit}
            />
        </Box >
    );
};
export default SizeSubstitution;