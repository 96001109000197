import React from 'react';
import FilterComponent from '../Filters/FilterComponent';
import { UseSidebarSalesOrdersAllocationState } from './state/useSidebarSalesOrdersAllocationState';
import Dates from '../Dates/Dates';
import { Box, Typography, Link } from '@mui/material';
import { sidebardSalesOrdersAllocationStyles as styles } from './SidebarSalesOrdersAllocation.styles';
import { AVAILABLE_TO_PROMISE } from '../../shared/constantes/AvailableToPromise';
import { SALES_ORDERS_ALLOCATION } from '../../shared/constantes/SalesOrdersAllocation';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const SidebarSalesOrdersAllocation = () => {
  const {
    filters,
    startDate,
    endDate,
    isFilterGapsByVariety,
    isFilterHGaps,
    selectedValues,
    setSelectedValues,
    handleSetStartDate,
    handleAllocation,
    handleSetEndDate,
    handleFilterGapsVariety,
    handleDefaultSort,
  } = UseSidebarSalesOrdersAllocationState();

  return (
    <>
      <Dates
        startDate={startDate}
        endDate={endDate}
        handleSearch={handleAllocation}
        handleSetStartDate={handleSetStartDate}
        handleSetEndDate={handleSetEndDate}
      />
      <Box sx={styles.sidebarBox}>
        <Typography sx={styles.textActions}>
          {AVAILABLE_TO_PROMISE.BUTTON_ACTIONS}
        </Typography>
        <Box sx={styles.box}>
          <Link onClick={() => handleDefaultSort()}>
            <Typography sx={styles.text}>
              {SALES_ORDERS_ALLOCATION.DEFAULT_SORT}
            </Typography>
          </Link>
        </Box>
        <Box sx={styles.box}>
          <Link onClick={() => handleFilterGapsVariety('variety')}>
            <Typography sx={styles.text}>
              {SALES_ORDERS_ALLOCATION.VARIETY_ACTION}
            </Typography>
          </Link>
          {isFilterGapsByVariety && (
            <ClearOutlinedIcon
              onClick={() =>
                handleFilterGapsVariety('variety', isFilterGapsByVariety)
              }
              sx={{ width: '15px', color: '#927C4E' }}
            />
          )}
        </Box>
        <Box sx={styles.box}>
          <Link onClick={() => handleFilterGapsVariety('holiday')}>
            <Typography sx={styles.text}>
              {SALES_ORDERS_ALLOCATION.GAPS_ACTION}
            </Typography>
          </Link>
          {isFilterHGaps && (
            <ClearOutlinedIcon
              onClick={() => handleFilterGapsVariety('holiday', isFilterHGaps)}
              sx={{ width: '15px', color: '#927C4E' }}
            />
          )}
        </Box>
      </Box>
      {
        <FilterComponent
          data={filters}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          showHeader={true}
        />
      }
    </>
  );
};
export default SidebarSalesOrdersAllocation;
