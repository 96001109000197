import { LogLevel } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';
import { API_ROUTES } from './shared/api_routes';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export const loginRequest = {
  scopes: ['User.Read'],
};

export const graphConfig = {
  graphMeEndpoint: API_ROUTES.GRAPHMEENDPOINT,
};

const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: API_ROUTES.AUTHORITY_URL,
    redirectUri: API_ROUTES.REDIRECT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.log(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
