import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@mui/material";
import React from "react";
import { dragAndDropItemStyles as styles } from "./drangAndDropItem.styles";
import { DragIcon } from "@/shared/icons/Icons";

const DragAndDropItem = ({ id, content }: any) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Box ref={setNodeRef} {...attributes} {...listeners} sx={{ ...styles.box, ...style }}>
            {content} <DragIcon />
        </Box>
    );
};

export default DragAndDropItem;