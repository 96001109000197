import React from "react"
import Layout from "../../Layout/Layout"
import { ACTION_PLAN } from "../../../shared/constantes/ActionPlan"
import SidebarActionPlan from "../../../components/SidebarActionPlan/SidebarActionPlan"
import ActionPlanTable from "../../../components/ActionPlanTable/ActionPlanTable"
import { handleWeekNumber } from "../../../shared/utils/dateUtils"
import ActionPlanTabNavigator from "../../../components/ActionPlanNavigator/ActionPlanTabNavigator"


const ActionPlan = () => {
    const date = new Date();
    const dateString = date.toLocaleDateString();

    return (
        <Layout title={`${ACTION_PLAN.TITLE} - Week ${handleWeekNumber(dateString)}`} sidebar={SidebarActionPlan}>
            <ActionPlanTabNavigator />
        </Layout>

    )
}

export default ActionPlan