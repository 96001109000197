import { Box, Button, } from "@mui/material";
import React, { useState } from "react"
import ActionPlanTable from '../ActionPlanTable/ActionPlanTable';

const cellStyle = (isActive, index) => ({
    borderRadius: '0 15px 15px 0',
    border: '1px solid black',
    zIndex: index,
    marginLeft: '-10px',
    background: isActive ? "" : "#F4F2EF",
    color: isActive ? "" : "black",
    textTransform: "none",
    fontSize: "13px"
});

const ActionPlanTabNavigator = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Box
            sx={{ position: 'relative' }}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '20px',
                position: 'relative',
            }}>
                <Button variant="contained" onClick={() => setActiveTab(0)} sx={cellStyle(activeTab === 0, 3)}>Allocation/Planning</Button>
                <Button variant="contained" onClick={() => setActiveTab(1)} sx={cellStyle(activeTab === 1, 2)}>Client Approval</Button>
                <Button variant="contained" onClick={() => setActiveTab(2)} sx={cellStyle(activeTab === 2, 1)}>Work Order</Button>
            </Box>
            <Box className="tab-content">
                {activeTab === 0 && <ActionPlanTable />}
                {activeTab === 1 && <>Client Approval Content</>}
                {activeTab === 2 && <>Work Order Content</>}
            </Box>
        </Box>
    )
}
export default ActionPlanTabNavigator