import { createSlice } from '@reduxjs/toolkit';
import { AgingByVarietyLocalState } from '../interfaces/agingByVariety.interfaces';
import { getAging } from '../thunks/AgingByVariety/agingByVariety.thunks';

export const initialState: AgingByVarietyLocalState = {
  aging: [],
  selectedFiltersStore: {
    category: [],
    variety: [],
    color: [],
    product_rotation_day: [],
  },
};

export const agingByVarietySlice = createSlice({
  name: 'AgingByVariety',
  initialState,
  reducers: {
    setSelectedFiltersStore: (state, action) => {
      state.selectedFiltersStore = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAging.fulfilled, (state, { payload }) => {
      state.aging = payload;
    });
  },
});

export default agingByVarietySlice.reducer;
