import { useEffect, useRef, useState } from "react";
import { IUseAllocationPrioritiesState } from "./useAllocationPriorities.interfaces"
import { useAppDispatch, useAppSelector } from "@/store/hooks/storeHook";
import { getConfiguration, saveSettingsConfiguration, getAppVersion } from "@/store/thunks/AllocationPriorities/allocationPriorities.thunks";
import { RootState } from "@/store/store";
import { CUSTOME_GUIDANCE, PRICE, ALLOCATION_PRIORITIES, ORDER_TYPE } from "@/shared/constantes/CustomeGuidance";
import { rulesSettings } from '../../SubstitutionRules/SubstitutionRuleComponents';
import { CONFIGURATIONS } from "@/shared/constantes/AdmingSettings";
import { DefaultValuesAllocationPriorities } from "@/shared/constantes/DefaultValuesAllocationPriorities";
import Cookies from "js-cookie";

export interface selectedCardInterface {
    id: string,
    title: string,
    description: string,
    component?: React.FC<any>
}

const UseAllocationPrioritiesState = (): IUseAllocationPrioritiesState => {
    const businessRuleSettings = useAppSelector((store: RootState) => store.allocationPriorities.settingsById[CONFIGURATIONS.BUSINESS_RULES]);
    const allocatioPrioritySettings = useAppSelector((store: RootState) => store.allocationPriorities.controlVersionSettings[CONFIGURATIONS.BUSINESS_RULES]);

    const [customeGuidanceItems, setCustomeGuidanceItems] = useState<{ id: string; content: string }[]>([]);
    const [priceItems, setPriceItems] = useState<{ id: string; content: string }[]>([]);
    const [orderTypeItems, setOrderTypeItems] = useState<{ id: string; content: string }[]>([]);
    const [masterAllocationPriorityItems, setMasterAllocationPriorityItems] = useState<{ id: string; content: string }[]>([]);
    const userName: string = Cookies.get('session') || '';
    const dispatch = useAppDispatch();
    const isFirstRender = useRef(true);
    const [selectedCard, setSelectedCard] = useState<selectedCardInterface>(rulesSettings[0] as selectedCardInterface);
    const [accCard, setAccCard] = useState<number>(0);
    const [openAlert, setOpenAlert] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [appVersion, setAppVersion] = useState<string>('Unknown');

    const handleCardClick = (item: any) => {
        setSelectedCard(item);
        setAccCard(rulesSettings.findIndex((rule) => rule.id === item.id));
    };

    const handleContinuesClick = () => {
        const nextCard = accCard + 1;
        if (nextCard < rulesSettings.length) {
            setAccCard(nextCard);
            setSelectedCard(rulesSettings[nextCard] as selectedCardInterface);
        }
    }

    const handleBackClick = () => {
        const backCard = accCard - 1;
        if (backCard >= 0) {
            setAccCard(backCard);
            setSelectedCard(rulesSettings[backCard] as selectedCardInterface);
        }
    }

    useEffect(() => {
        if (businessRuleSettings) {
            setCustomeGuidanceItems(businessRuleSettings.customerGuidance?.map((item: string) => ({
                id: item,
                content: CUSTOME_GUIDANCE[item]
            })) || []);

            setPriceItems(businessRuleSettings.price?.map((item: string) => ({
                id: item,
                content: PRICE[item]
            })) || []);

            setOrderTypeItems(businessRuleSettings.orderType?.map((item: string) => ({
                id: item,
                content: ORDER_TYPE[item]
            })) || []);

            setMasterAllocationPriorityItems(businessRuleSettings.allocationPriority?.map((item: string) => ({
                id: item,
                content: ALLOCATION_PRIORITIES[item]
            })) || []);
        }
    }, [businessRuleSettings]);

    useEffect(() => {
        const fetchData = async () => {
            if (isFirstRender.current || !businessRuleSettings || !businessRuleSettings.customerGuidance) {
                isFirstRender.current = false;
                return;
            }
            const orderByConfiguration = {
                "customerGuidance": customeGuidanceItems.map((item) => item.id),
                "orderType": orderTypeItems.map((item) => item.id),
                "allocationPriority": masterAllocationPriorityItems.map((item) => item.id),
                "price": priceItems.map((item) => item.id)
            };

            if (
                orderByConfiguration.customerGuidance.length === 0 &&
                orderByConfiguration.orderType.length === 0 &&
                orderByConfiguration.allocationPriority.length === 0 &&
                orderByConfiguration.price.length === 0
            ) {
                return;
            }

            if (
                JSON.stringify(orderByConfiguration.customerGuidance) !== JSON.stringify(businessRuleSettings?.customerGuidance) ||
                JSON.stringify(orderByConfiguration.orderType) !== JSON.stringify(businessRuleSettings?.orderType) ||
                JSON.stringify(orderByConfiguration.allocationPriority) !== JSON.stringify(businessRuleSettings?.allocationPriority) ||
                JSON.stringify(orderByConfiguration.price) !== JSON.stringify(businessRuleSettings?.price) && isFirstRender.current === false
            ) {
                const status = await dispatch(saveSettingsConfiguration({
                    configurationId: CONFIGURATIONS.BUSINESS_RULES,
                    configuration: orderByConfiguration
                })).unwrap();
                if (status) {
                    setOpenAlert(true);
                    setNotificationMessage(status === 'Configuration updated' ? ALLOCATION_PRIORITIES.SUBSTITUTION_SAVE : status);
                }
                dispatch(getConfiguration({ id: CONFIGURATIONS.BUSINESS_RULES }));
            }
        };
        fetchData();
    }, [customeGuidanceItems, priceItems, orderTypeItems, masterAllocationPriorityItems, userName, dispatch, businessRuleSettings]);

    const retrieveAppVersion = async () => {
        try {
            const res = await dispatch(getAppVersion()).unwrap();
            setAppVersion(res.version); 
        }
        catch (e) {
            setNotificationMessage('Error fetching app version');
            setOpenAlert(true);
        }
    }

    useEffect(() => {
        retrieveAppVersion();
    }, [])

    const handleReEstablish = async () => {
        await dispatch(saveSettingsConfiguration({
            configurationId: CONFIGURATIONS.BUSINESS_RULES,
            configuration: DefaultValuesAllocationPriorities.businessRules
        }));
        await dispatch(saveSettingsConfiguration({
            configurationId: CONFIGURATIONS.ALTERNATIVE_VARIETY,
            configuration: DefaultValuesAllocationPriorities.alternativeVarietyMatch
        }));
        await dispatch(saveSettingsConfiguration({
            configurationId: CONFIGURATIONS.SIZE_RANGE,
            configuration: DefaultValuesAllocationPriorities.sizeRanges.sizeSubstitutions
        }));
        await dispatch(saveSettingsConfiguration({
            configurationId: CONFIGURATIONS.IDENTIFY_GAPS,
            configuration: DefaultValuesAllocationPriorities.identifyGaps
        }))
        await dispatch(getConfiguration({ id: CONFIGURATIONS.BUSINESS_RULES }));
        setOpenAlert(true);
    }

    return {
        customeGuidanceItems,
        setCustomeGuidanceItems,
        priceItems,
        setPriceItems,
        orderTypeItems,
        setOrderTypeItems,
        masterAllocationPriorityItems,
        setMasterAllocationPriorityItems,
        rulesSettings,
        handleCardClick,
        handleBackClick,
        handleContinuesClick,
        selectedCard,
        accCard,
        allocatioPrioritySettings,
        handleReEstablish,
        openAlert,
        setOpenAlert,
        notificationMessage,
        appVersion
    }

}
export default UseAllocationPrioritiesState