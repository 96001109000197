import { createNamedStyles } from '../../shared/interfaces/create_name_styles';
import { theme } from '../../theme';

export const salesOrderAllocationTableStyles = createNamedStyles({
  box: {
    backgroundColor: '#F4F2EF',
    marginBottom: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  search: {
    width: '50%',
    marginBottom: '5px',
    marginTop: '5px',
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.primary.main, // Cambia el color de fondo aquí
    },
  },
  text: {
    fontWeight: '600',
    margin: '0px 10px',
  },
  boxIcons: {
    display: 'flex',
    width: '50%',
    justifyContent: 'end',
  },
  total: {
    fontWeight: 'bold',
    border: '1px solid #E0E0E0 ',
    backgroundColor: '#F4F2EF',
  },
});
