import { ATLAS_POSTPONEMENT_OPTIONS, POSTPONE_TRUCK_DATE } from "@/shared/constantes/AllocationPriorities";
import { Box, Switch, Typography } from "@mui/material";
import React from "react";
import { ISubstitutionRules } from "../../AllocationPriorities/state/useAllocationPriorities.interfaces";
import Footer from "../Footer/Footer";

export const TruckDate: React.FC<ISubstitutionRules> = ({ card, handleBackClick, handleContinuesClick }) => {
    const postponementOptions = ATLAS_POSTPONEMENT_OPTIONS
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '55vh' }}>
            <Typography sx={{ margin: "10px 0px" }}>{POSTPONE_TRUCK_DATE.TITLE}</Typography>
            <Box sx={{ paddingTop: '20px' }}>
                {
                    postponementOptions.map((option, key) => {
                        const [beforeComma, afterComma] = option.content.split(",");
                        return (
                            <Box
                                key={`truck-date-${key}`}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '80%'
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>
                                        {beforeComma}
                                        <span style={{ fontWeight: '600' }}>{afterComma}</span>
                                    </Typography>
                                </Box>
                                <Switch disabled defaultChecked />
                            </Box>
                        )
                    })
                }
            </Box>
            <Box sx={{ marginTop: 8 }}>
                <Typography>{POSTPONE_TRUCK_DATE.SUBTITLE}<span style={{ fontWeight: 700 }}>{POSTPONE_TRUCK_DATE.SUBTITLE2}</span></Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Footer
                card={card}
                handleBackClick={handleBackClick}
                handleContinuesClick={handleContinuesClick}
                handleSaveSettings={() => { }}
            />
        </Box >
    )

};
export default TruckDate;