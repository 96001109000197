import { createSlice } from '@reduxjs/toolkit';
import { AllocationPrioritiesLocalState } from '../interfaces/allocationPriorities.interfaces';
import { getConfiguration } from '../thunks/AllocationPriorities/allocationPriorities.thunks';

export const initialState: AllocationPrioritiesLocalState = {
  settingsById: {},
  controlVersionSettings: {},
  openAlert: true,
};

export const allocationPrioritiesSlice = createSlice({
  name: 'allocationPriorities',
  initialState,
  reducers: {
    setOpenAlert: (state, action) => {
      state.openAlert = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConfiguration.fulfilled, (state, { payload, meta }) => {
      const id = meta.arg.id;
      state.settingsById[id] = payload.configuration_value;
      state.controlVersionSettings[id] = payload;
    });
  },
});

export default allocationPrioritiesSlice.reducer;
