import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import data from '@/shared/data/sales_orders_allocation_header.json';
import { useActionPlanState } from './state/useActionPlanState';
import SearchBar from '@/components/SearchBar/SearchBar';
import {
  Download,
  LessIcon,
  OpenModal,
  OrderAZ,
  OrderZA,
  PlusIcon,
  SearchBarIcon,
  ShowColumns,
} from '@/shared/icons/Icons';
import { salesOrderAllocationTableStyles as styles } from './ActionPlanTable.styles';
import { SALES_ORDERS_ALLOCATION } from '@/shared/constantes/SalesOrdersAllocation';
import TooltipIcon from '@/components/Tooltip/TooltipIcon';
import { IheadCell } from '@/components/Table/TableHeader.interfaces';
import HideColumns from '@/components/HideColumns/HideColumns';
import PopoverComponent from '@/components/PopoverComponent/PopoverComponent';
import 'react-virtualized/styles.css'
import { AutoSizer, ScrollSync, MultiGrid } from 'react-virtualized';
import './ActionPlan.css'
import 'react-virtualized/styles.css';
import { PaginationItem } from '@mui/material';
import Timer from '@/components/Timer/Timer';
import { convertNumber } from '@/shared/utils/numbersUtils';
import { ENVIRONMENT } from '@/shared/api_routes';
import { SalesOrdersAllocation } from '@/store/interfaces/salesOrdersAllocation.interfaces';
import { ALLOCATION_PRIORITIES } from '@/shared/constantes/CustomeGuidance';
import AvailabilityModal from '../AvailabilityModal/AvailabilityModal';
import AutoCompleteComponent from '@/components/AutoComplete/Autocomplete';

const columns: IheadCell[] = data.data;

interface CellStyleProps {
  row: any;
  dataKey: string;
}

interface IHeaderRenderer {
  columnIndex: number;
  key: string;
  style: React.CSSProperties;
}

const cellStyle = ({ row, dataKey }: CellStyleProps): React.CSSProperties => ({
  fontSize: '12px',
  padding: dataKey === 'confirmed' || dataKey === 'openAbailability' ? '3px 10px' : dataKey === 'productNameSubstitution' ? "0px 8px" : '14px 10px',
  backgroundColor: row.missingStems > 0
    ? (dataKey === "missingStems" ? '#FDEDEC' : (row.category === "TOTAL" ? '#F4F2EF' : '#FDEDEC'))
    : (row.category === "TOTAL" ? '#F4F2EF' : '#FFF'),
  fontFamily: 'Roboto, sans-serif',
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: row.category === "TOTAL" ? 700 : "",
  color: dataKey === "missingStems" && row.missingStems > 0 ? 'red' : '#5C5C5C',
  height: dataKey === 'confirmed' || dataKey === 'openAbailability' ? '81%' : dataKey === 'productNameSubstitution' ? "95%" : '31%',
  border: '1px solid #E0E0E0',
  whiteSpace: 'nowrap',
});

interface CellBreakdownsStyleProps {
  row: {
    missingStems: number;
  };
  dataKey: string;
}

const cellBreakdownsStyle = ({ row, dataKey }: CellBreakdownsStyleProps): React.CSSProperties => ({
  fontSize: '11px',
  padding: dataKey === 'confirmed' || dataKey === 'openAbailability' ? '3px 10px' : dataKey === 'productNameSubstitution' ? "0px 8px" : '10px 10px',
  backgroundColor: row.missingStems > 0 ? '#FDEDEC' : '#FBFAF9',
  border: '1px solid #E0E0E0',
  height: dataKey === 'confirmed' || dataKey === 'openAbailability' ? '81%' : dataKey === 'productNameSubstitution' ? "95%" : '50%',
  whiteSpace: 'nowrap',
  fontFamily: 'Roboto, sans-serif',
  textWrap: "pretty",
  color: dataKey === "missingStems" && row.missingStems > 0 ? 'red !important' : '',
});

export interface ISalesOrderAllocationTable {
  handleOpenModal: () => void;
}

interface CellRendererProps {
  columnIndex: number;
  key: string;
  rowIndex: number;
  style: React.CSSProperties;
}

interface IVisibleColumns {
  [key: string]: boolean;
}

const initializeVisibleColumns = (columns: IheadCell[]): IVisibleColumns => {
  return columns.reduce((acc: IVisibleColumns, column: IheadCell) => {
    acc[column.field] = !(column.hiddenByDefault);
    return acc;
  }, {});
}

const ActionPlanTable: React.FC<ISalesOrderAllocationTable> = ({
  handleOpenModal,
}) => {
  const {
    sortBy,
    sortDirection,
    visible,
    loading,
    currentPage,
    handleShowSearchBar,
    showSearchBar,
    expandedRow,
    handleShowBreakdowns,
    isExpandedAll,
    handleDownloadCsv,
    handleSubmit,
    handleSearchValue,
    adjustedRowGetter,
    adjustedRowCount,
    handleSort,
    totalAllocations,
    totalPages,
    handleChangePage,
    date,
    allocationPriority,
    isVisibleAvailabilityModal,
    setIsVisibleAvailabilityModal,
    itemAllocation,
    handleOpenAvailability,
    inputValue,
    handleInputChange,
    availability,
  } = useActionPlanState();

  const handleExpande = (value: string, index: number) =>
    value === 'Assorted' && (
      <IconButton
        sx={{ paddingTop: "0px", paddingBottom: "1px" }}
        data-testid="icon"
        onClick={(event) => handleShowBreakdowns(event, index)}
      >
        {!(expandedRow === index) && !isExpandedAll ? (
          <PlusIcon data-testid="plus-icon" />
        ) : (
          <LessIcon data-testid="less-icon" />
        )}
      </IconButton>
    );
  const [visibleColumns, setVisibleColumns] = useState(() => initializeVisibleColumns(columns));
  const [tableKey, setTableKey] = useState<any>(null);

  const handleToggleColumn = (columnId: string) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
    setTableKey(new Date());
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: CellRendererProps) => {
    const column = visibleColumnsArray[columnIndex];
    const rowData: SalesOrdersAllocation = adjustedRowGetter({ index: rowIndex });
    const cellData = rowData ? rowData[column.field as keyof SalesOrdersAllocation] : null;
    const dataKey = column.field;
    const renderCellData = () => {
      if (rowIndex !== 0 && dataKey === 'productNameSubstitution') {
        return (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <AutoCompleteComponent
              key={"autocompleteKey"}
              inputValue={inputValue[rowIndex] || ''}
              setInputValue={(event) => handleInputChange(rowIndex, event, rowData)}
              label={""}
              options={availability.filter((item: { atp: number }) => item.atp >= rowData.missingStems).map((item: { variety: string; grade: string }) => `${item.variety} ${item.grade}`)}
              onOptionSelect={() => { }}
              size='small'
              additionalOptions={['Cancel', 'Postpone']}
              disable={rowData.boxComposition === "Assorted"}
            />
          </Box >
        )
      }
      if (rowIndex !== 0 && dataKey === 'openAbailability') {
        return (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#999999" }}>
            <IconButton onClick={() => handleOpenAvailability(rowData)} disabled={rowData.boxComposition === "Assorted"}>
              <OpenModal color={rowData.boxComposition === "Assorted" ? "#999" : "#5C5C5C"} />
            </IconButton>
          </Box>
        )

      }
      if (rowIndex !== 0 && dataKey === 'confirmed') {
        return (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Checkbox
              checked={!!cellData}
              sx={{ color: "#999999" }}
            />
          </Box>
        )
      }
      if (cellData === "Assorted") {
        return (
          <>
            {cellData}
            {handleExpande(cellData, rowIndex)}
          </>
        );
      }
      if ((dataKey === 'farmDate' || dataKey === 'truckDate') && cellData != null && cellData !== "") {
        const [month, day] = typeof cellData === 'string' ? cellData.split('/') : ['', ''];
        const result = `${month}/${day}`;
        return (
          <>
            {result}
          </>
        )
      }
      if (rowData.boxComposition === 'Solid' && (dataKey === 'comment')) {
        return (<>{cellData === null || cellData === undefined ? '' : String(cellData)}</>);
      }
      if (
        dataKey === 'totalStems' ||
        dataKey === 'totalBoxes' ||
        dataKey === 'allocatedStems' ||
        dataKey === 'missingStems' ||
        dataKey === 'substitutedStems'
      ) {
        return (
          <>
            {convertNumber(cellData as number)}
          </>
        )
      }
      return (<>{cellData === null || cellData === undefined ? '' : String(cellData)}</>);
    };

    if (!rowData.isBreakdown) {
      return (
        <div key={key} style={style}>
          <Box
            sx={cellStyle({ row: rowData, dataKey })}
          >
            {renderCellData()}
          </Box>
        </div>
      );
    }

    return (
      <div key={key} style={style}>
        <Box style={{ color: 'gray' }} sx={cellBreakdownsStyle({ row: rowData, dataKey })}>
          {renderCellData()}
        </Box>
      </div>
    );
  };

  const handleOrderIcon = (field: string) => {
    if (sortBy === field) {
      return (
        <Box>
          {sortDirection === 'asc' ? <OrderAZ /> : <OrderZA />}
        </Box>
      );
    }
    return null;
  };

  const visibleColumnsArray = columns.filter(col => visibleColumns[col.field]);

  const headerRenderer = ({ columnIndex, key, style }: IHeaderRenderer) => {
    const column = visibleColumnsArray[columnIndex];
    if (column.field === 'confirmed') {
      return (
        <div key={key} style={style}>
          <Box sx={{ ...styles.headerStyle, justifyContent: "center" }}>
            <Checkbox
              checked={false}
              onChange={() => { }} />
          </Box>
        </div>
      )
    } else {

      return (
        <div key={key} style={style}>
          <Box sx={styles.headerStyle} onClick={() => {
            handleSort && handleSort(column.field);
          }}>
            <Tooltip
              title={
                column.hoverName
              }
              placement="top-start"
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: '#927C4E',
                    fontSize: '12px',
                  },
                },
              }}
            >
              <span>{column.headerName}</span>
            </Tooltip>
            {handleOrderIcon(column.field)}
          </Box>
        </div>
      );
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}></Box>
        {visible && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '3px',
            }}
          >
            <Alert sx={{ width: '100%' }} severity="success">
              Viewing default sort: {allocationPriority && ALLOCATION_PRIORITIES[allocationPriority[0]] + ',' + ' '}
              {allocationPriority && ALLOCATION_PRIORITIES[allocationPriority[1]]} and {' '}
              {allocationPriority && ALLOCATION_PRIORITIES[allocationPriority[2]]}
            </Alert>
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Box sx={{ flex: '1 1 70%' }}>
            <Box sx={styles.box}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={styles.text}>
                  {`${totalAllocations && totalAllocations} results`}
                </Box>
                <Box >
                  {!loading ? "" : (
                    <Box sx={{ display: 'flex', justifyContent: 'start', }}>
                      <CircularProgress style={{ width: "15px", height: "15px" }} />
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={styles.boxIcons}>
                {showSearchBar ? (
                  <Box sx={styles.search}>
                    <SearchBar
                      handleSearchValeu={handleSearchValue}
                      handleSubmit={handleSubmit}
                    />
                  </Box>
                ) : (
                  <Box style={{
                    display: ENVIRONMENT.DISABLED_ICON === "true" ? 'none' : 'block'
                  }}>
                    <TooltipIcon
                      title={SALES_ORDERS_ALLOCATION.BUTTON_SEARCH}
                      handleClick={handleShowSearchBar}
                      icon={<SearchBarIcon />}
                    />
                  </Box>
                )}
                <PopoverComponent
                  children={
                    <HideColumns
                      columnsName={columns}
                      handleToggleColumn={handleToggleColumn}
                      visibleColumns={visibleColumns}
                    />
                  }
                  title={SALES_ORDERS_ALLOCATION.BUTTON_HIDE}
                  icon={<ShowColumns />}
                />
                <TooltipIcon
                  title={SALES_ORDERS_ALLOCATION.BUTTON_EXPORT}
                  icon={<Download />}
                  handleClick={handleDownloadCsv}
                />
                <TooltipIcon
                  title={SALES_ORDERS_ALLOCATION.BUTTON_REVENUE}
                  icon={<OpenModal />}
                  handleClick={handleOpenModal}
                />
              </Box>
            </Box>
            <Box style={{ height: '65vh' }}>
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => {
                  return (
                    <ScrollSync>
                      {({ onScroll, scrollLeft }) => (
                        <div style={{ height, width, position: 'relative' }}>
                          <MultiGrid
                            key={tableKey}
                            fixedRowCount={1}
                            fixedColumnCount={4}
                            columnCount={visibleColumnsArray.length}
                            columnWidth={({ index }: { index: number }) => visibleColumnsArray[index].width || 100}
                            height={height}
                            rowCount={adjustedRowCount + 1}
                            rowHeight={({ index }: { index: number }) => (index === 0 ? 44 : 44)}
                            width={Math.min(width)}
                            cellRenderer={({ columnIndex, key, rowIndex, style }: { columnIndex: number; key: string; rowIndex: number; style: React.CSSProperties }) => {
                              if (rowIndex === 0) {
                                return headerRenderer({ columnIndex, key, style });
                              }
                              return cellRenderer({ columnIndex, key, rowIndex: rowIndex - 1, style });
                            }}
                            style={{ overflowX: 'auto', position: 'relative' }}
                            scrollLeft={scrollLeft}
                            onScroll={onScroll}
                          />
                        </div>
                      )}
                    </ScrollSync>
                  )
                }
                }
              </AutoSizer>
            </Box>
          </Box>
          {isVisibleAvailabilityModal && (
            <Box sx={{
              flex: '1 1 30%',
              position: 'relative',
              zIndex: 2,
              radius: '0px',
              '@media (max-width: 600px)': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
              },
            }}>
              <AvailabilityModal item={itemAllocation} onClose={setIsVisibleAvailabilityModal} />
            </Box>
          )}
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          alignItems: 'center'
        }}>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={() => { }}>
              <Typography variant="caption">Accept Selected</Typography>
            </Button>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          alignItems: 'center'
        }}>
          <Box sx={{
            flex: 1, display: 'flex', justifyContent: 'center'
          }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handleChangePage}
              size='small'
              style={{ display: 'flex', justifyContent: 'center', paddingLeft: '15%' }}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  style={{
                    backgroundColor: "white",
                    fontSize: "11px",
                    padding: '0 10px',
                    textDecoration: item.selected ? 'underline' : 'none',
                    textUnderlineOffset: '4px',
                    textDecorationThickness: '2px',
                  }}
                />
              )}
            >
            </Pagination>
          </Box>
          <Timer time={date} />

        </Box>
      </Box>
    </>
  );
};
export default React.memo(ActionPlanTable);
