import { ALTERNATIVE_VARIETY, VARIETY_SUBSTITUTION_OPTIONS, SUBSTITUTION_SIZE } from "@/shared/constantes/AllocationPriorities"
import { Box, Typography, Switch, Radio, } from "@mui/material"
import React from "react"
import UseStateAlternativeVariety from "./state/useStateAlternativeVariety"
import DragAndDrop from "@/components/DragAndDrop/DragAndDrop"
import Footer from "../Footer/Footer"
import { ISubstitutionRules } from "../../AllocationPriorities/state/useAllocationPriorities.interfaces"

const AlternativeVariety: React.FC<ISubstitutionRules> = ({card, handleBackClick, handleContinuesClick, setOpenAlert}) => {
    const {
        step,
        setStep,
        handleSubmit,
        saveAndContinues
    } = UseStateAlternativeVariety({handleContinuesClick, setOpenAlert});

    const varietySubstitutionOptions = VARIETY_SUBSTITUTION_OPTIONS

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '55vh' }}>
            <Typography sx={{ margin: "10px 0px" }}>{ALTERNATIVE_VARIETY.TITLE}</Typography>
            <Typography sx={{ margin: "10px 0px" }}>{ALTERNATIVE_VARIETY.DESCRIPTION}</Typography>
            <Box sx={{ margin: "40px 10px" }} >
                <DragAndDrop title={ALTERNATIVE_VARIETY.STEP} items={step} setItems={setStep} />
            </Box>
            <Box sx={{ paddingTop: '20px' }}>
                {
                    varietySubstitutionOptions.map((option, key) => {
                        const [beforeComma, afterComma] = option.content.split(",");
                        return (
                            <Box
                                key = {`alternative-variety-${key}`}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '80%'
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>
                                        {beforeComma}
                                        <span style={{ fontWeight: '600' }}>{afterComma}</span>
                                    </Typography>
                                </Box>
                                {option.type === "Radio" ? (<Radio disabled />) : <Switch disabled defaultChecked />}
                            </Box>
                        )
                    })
                }
            </Box>
            <Box sx={{ marginTop: 3 }}>
                <Typography>{ALTERNATIVE_VARIETY.SUB_TEXT}<span style={{ fontWeight: 700 }}>{ALTERNATIVE_VARIETY.POSTPONE}</span></Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Footer
                card={card}
                handleBackClick={handleBackClick}
                handleContinuesClick={saveAndContinues}
                handleSaveSettings={handleSubmit}
            />
        </Box >
    )

}
export default AlternativeVariety