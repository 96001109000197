import { availableToPromiseSlice } from '../../reducers/availableToPromise.slice';

export const {
  setStartDate,
  setEndDate,
  setFiltersInventoryTypes,
  setIsAccumulate,
  setFilterGlobalData,
  setGroupResultValues,
  setGapsByVarietyATP,
  setInitialState,
  setFiltersToSearchATP,
  setCurrentPageATP,
} = availableToPromiseSlice.actions;
