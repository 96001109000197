// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactVirtualized__Table__rowColumn {
  margin: 0;
  /* Elimina márgenes */
  padding: 0;
  /* Elimina rellenos */
 /* border: 1px solid #E0E0E0; */
  /* Borde para todas las celdas */
  background-color: white;
  /* Fondo para celdas vacías */
  box-sizing: border-box;
  /* Asegura que el borde esté incluido en el tamaño */
}

.ReactVirtualized__Table {
  width: 100%;
  border-spacing: 0;
  /* Elimina el espacio entre celdas */
}

.ReactVirtualized__Table__rowColumn,
.ReactVirtualized__Table__headerColumn {
  padding: 0;
  margin: 0;
}

.ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
   min-width: -1px !important; 
}

`, "",{"version":3,"sources":["webpack://./src/components/revenueManagement/ActionPlan/ActionPlanTable/ActionPlan.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,qBAAqB;EACrB,UAAU;EACV,qBAAqB;CACtB,+BAA+B;EAC9B,gCAAgC;EAChC,uBAAuB;EACvB,6BAA6B;EAC7B,sBAAsB;EACtB,oDAAoD;AACtD;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,oCAAoC;AACtC;;AAEA;;EAEE,UAAU;EACV,SAAS;AACX;;AAEA;GACG,0BAA0B;AAC7B","sourcesContent":[".ReactVirtualized__Table__rowColumn {\n  margin: 0;\n  /* Elimina márgenes */\n  padding: 0;\n  /* Elimina rellenos */\n /* border: 1px solid #E0E0E0; */\n  /* Borde para todas las celdas */\n  background-color: white;\n  /* Fondo para celdas vacías */\n  box-sizing: border-box;\n  /* Asegura que el borde esté incluido en el tamaño */\n}\n\n.ReactVirtualized__Table {\n  width: 100%;\n  border-spacing: 0;\n  /* Elimina el espacio entre celdas */\n}\n\n.ReactVirtualized__Table__rowColumn,\n.ReactVirtualized__Table__headerColumn {\n  padding: 0;\n  margin: 0;\n}\n\n.ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {\n   min-width: -1px !important; \n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
