import { useEffect, useRef, useState } from "react";
import { UseIActionPlanState } from "./useIActionPlanState.interface";
import { RootState } from "../../../store/store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import data from '../../../shared/data/action_plan.json';
import { setSort } from "../../../store/actions/salesOrdersAllocation/salesOrdersAllocation.actions";
import { columnsDate } from "../../../shared/utils/numbersUtils";
import { IheadCell } from "../../Table/TableHeader.interfaces";

export const UseActionPlanState = (): UseIActionPlanState => {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const { loading, sortBy, sortDirection } = useAppSelector(
    (store: RootState) => store.salesOrders
  );
  const { startDate, endDate } = useAppSelector((store : RootState) => store.actionPlan)
  const columns = data.data
  const dispatch = useAppDispatch();
  const [editIndex, setEditIndex] = useState(null);
  const [editColumn, setEditColumn] = useState(null);
  const [formData, setFormData] = useState({});
  const headerDateIds = useRef<IheadCell[]>([])

  const handleSort = (column) => {
    const newDirection =
      sortBy === column && sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, newDirection }));
  };
  const handleChange = (e, columnName) => {
    setFormData({
      ...formData,
      [columnName]: e.target.value,
    });
  };

  const handleDoubleClick = (rowIndex, columnName, row) => {
    setFormData(row);
    setEditIndex(rowIndex);
    setEditColumn(columnName);
  };

  useEffect(() =>{
    headerDateIds.current= columnsDate(startDate, endDate)
  }, [])

  return {
    showSearchBar,
    loading,
    columns,
    sortBy,
    editIndex,
    editColumn,
    handleSort,
    handleDoubleClick,
    handleChange,
    handleSave: ()=>{},
    headerDateIds : headerDateIds.current
  }

}