import { createSlice } from '@reduxjs/toolkit';
import { ISubstitutionMatrix } from '../interfaces/substitutionMatrix.interfaces';

export const initialState: ISubstitutionMatrix = {
  selectedFiltersStore: {
    category: [],
    variety: [],
    color: [],
    grade: [],
  },
};

export const substitutionMatrixSlice = createSlice({
  name: 'substitutionMatrix',
  initialState,
  reducers: {
    setSelectedFiltersStore: (state, action) => {
      state.selectedFiltersStore = action.payload;
    },
  },
});

export default substitutionMatrixSlice.reducer;
