import React from 'react';
import FilterComponent from '../Filters/FilterComponent';
import { AVAILABLE_TO_PROMISE } from '../../shared/constantes/AvailableToPromise';
import { UseSidebarAvailableToPromiseState } from './state/useSidebarAvailableToPromiseState';
import Dates from '../Dates/Dates';
import { Box, Typography, Link } from '@mui/material';
import { sidebardAvailableToPromiseStyles as styles } from './SidebarAvailableToPromise.styles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const SidebarAvailableToPromise = () => {
  const {
    filters,
    startDate,
    endDate,
    isFilterGapsVariety,
    isFilterGlobalData,
    selectedValues,
    setSelectedValues,
    handleSetStartDate,
    handleSetEndDate,
    handleFilterGapsVariety,
    handleATP,
    handleRunGlobalData,
  } = UseSidebarAvailableToPromiseState();
  return (
    <>
      <Dates
        startDate={startDate}
        endDate={endDate}
        handleSetStartDate={handleSetStartDate}
        handleSetEndDate={handleSetEndDate}
        handleSearch={handleATP}
      />
      <Box sx={styles.sidebarBox}>
        <Typography sx={styles.textActions}>
          {AVAILABLE_TO_PROMISE.BUTTON_ACTIONS}
        </Typography>
        <Box sx={styles.box}>
          <Link onClick={handleRunGlobalData}>
            <Typography sx={styles.text}>
              {' '}
              {AVAILABLE_TO_PROMISE.GLOBAL_DATA_ACTION}
            </Typography>
          </Link>
          {isFilterGlobalData && (
            <ClearOutlinedIcon
              onClick={handleRunGlobalData}
              sx={{ width: '15px', color: '#927C4E' }}
            />
          )}
        </Box>
        <Box sx={styles.box}>
          <Link onClick={handleFilterGapsVariety}>
            <Typography sx={styles.text}>
              {AVAILABLE_TO_PROMISE.VARIETY_ACTION}
            </Typography>
          </Link>
          {isFilterGapsVariety && (
            <ClearOutlinedIcon
              onClick={handleFilterGapsVariety}
              sx={{ width: '15px', color: '#927C4E' }}
            />
          )}
        </Box>
      </Box>
      <FilterComponent
        data={filters}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        showHeader={true}
      />
    </>
  );
};
export default SidebarAvailableToPromise;
