import { CANCELLATION_RULE, ATLAS_CANCELLATION_OPTIONS } from "@/shared/constantes/AllocationPriorities";
import { Box, Switch, Typography } from "@mui/material";
import React from "react"
import Footer from "../Footer/Footer";
import { ISubstitutionRules } from "../../AllocationPriorities/state/useAllocationPriorities.interfaces";

const CancellationRule: React.FC<ISubstitutionRules> = ({ card, handleBackClick, handleContinuesClick }) => {
    const cancellationOptions = ATLAS_CANCELLATION_OPTIONS
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '55vh' }}>
            <Typography sx={{ margin: "10px 0px" }}>{CANCELLATION_RULE.TITLE}</Typography>
            <Box sx={{ paddingTop: '20px' }}>
                {
                    cancellationOptions.map((option, key) => {
                        const [beforeComma, afterComma] = option.content.split(",");
                        return (
                            <Box
                                key={`cancelation-rule-${key}`}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '80%'
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>
                                        {beforeComma}
                                        <span style={{ fontWeight: '600' }}>{afterComma}</span>
                                    </Typography>
                                </Box>
                                <Switch disabled defaultChecked />
                            </Box>
                        )
                    })
                }
            </Box>
            <Box sx={{ marginTop: 8 }}>
                <Typography><span style={{ fontWeight: 700 }}>{CANCELLATION_RULE.PROCESS}</span>{CANCELLATION_RULE.SUBTITLE2}</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Footer
                card={card}
                handleBackClick={handleBackClick}
                handleContinuesClick={handleContinuesClick}
                handleSaveSettings={() => { }}
            />
        </Box >
    )
}
export default CancellationRule