import { Theme, createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      border: string;
      strongBorder: string;
      disabledCell: string;
    };
  }
  interface ThemeOptions {
    custom?: {
      border: string;
      strongBorder: string;
      disabledCell: string;
    };
  }
}

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#927C4E',
    },
    secondary: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#5c5c5c',
    },
    background: {
      default: '#F4F2EF',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 12,
  },
  shape: {
    borderRadius: 8,
  },
  custom: {
    border: '1px solid #e0e0e0',
    strongBorder: '1.5px solid #927C4E',
    disabledCell: '#f4f2ef8c',
  } as any,
});
