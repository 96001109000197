import React from 'react';
import { Box, Grid, Typography, IconButton, Button } from '@mui/material';
import { datesStyles as styles } from './Dates.styles';
import DatePicker from 'react-datepicker';
import { Search } from '../../shared/icons/Icons';
import { handleWeekNumber } from '../../shared/utils/dateUtils';

const Dates = ({
  startDate,
  endDate,
  handleSetStartDate,
  handleSetEndDate,
  handleSearch,
}) => {
  const maxDate = React.useMemo(() => {
    const date = new Date().setDate(new Date().getDate() + 14);
    return date;
  }, []);

  const renderHeader = ({ date, decreaseMonth, increaseMonth }) => {
    const weeks: any[] = [];
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let currentWeek = new Date(startOfMonth);

    while (currentWeek <= endOfMonth) {
      weeks.push(handleWeekNumber(currentWeek.toISOString()));
      currentWeek.setDate(currentWeek.getDate() + 7);
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '81px',
            zIndex: 1000,
            backgroundColor: 'white',
            left: '5px',
            borderRight: '1px solid #000',
          }}
        >
          {weeks.map((week, index) => (
            <Box key={index} sx={{ padding: '8px 10px' }}>
              {week}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            marginTop: 0,
            color: '#000',
            fontWeight: 'bold',
            fontSize: '0.944rem',
          }}
        >
          <Button onClick={decreaseMonth} sx={{}}>
            {'<'}
          </Button>
          <span>
            {date.toLocaleString('default', { month: 'long' })}{' '}
            {date.getFullYear()}
          </span>
          <Button onClick={increaseMonth}>{'>'}</Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={styles.sidebarBox}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography sx={{ ...styles.textActions, paddingBottom: '10px' }}>
            Farm Date
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <DatePicker
            selected={startDate}
            onChange={(date) => handleSetStartDate(date)}
            className="custom-datepicker"
            maxDate={maxDate}
            showWeekNumbers
            weekLabel="W"
            renderCustomHeader={renderHeader}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <DatePicker
            selected={endDate}
            onChange={(date) => handleSetEndDate(date)}
            className="custom-datepicker"
            maxDate={maxDate}
            showWeekNumbers
            weekLabel="W"
            renderCustomHeader={renderHeader}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          sx={{ display: 'flex', justifyContent: 'end' }}
        >
          <IconButton onClick={handleSearch} sx={styles.search}>
            <Search />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Dates;
