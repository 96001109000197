import { createSlice } from '@reduxjs/toolkit';
import { ActionPlanLocalState } from '../interfaces/salesOrdes.interfaces';
import {
  getFiltersAllocationData,
  getAllocation,
  getAvailability,
} from '../thunks/ActionPlan/ActionPlan.thunks';

export const initialState: ActionPlanLocalState = {
  allocation: {
    allocations: [],
    totalAllocations: 0,
    totalEarnings: '',
    totalFulfilledAllocations: 0,
    totalMaxEarnings: '',
    currentPage: 1,
    totalPages: 1,
    lastAllocationDate: '',
  },
  currentPage: 1,
  totalPages: 0,
  startDate: (() => {
    const date = new Date();
    return date;
  })(),
  endDate: (() => {
    const date = new Date();
    // date.setDate(date.getDate() + 1)
    return date;
  })(),
  loading: false,
  isFilterGapsVariety: false,
  isFilterHGaps: false,
  filters: {
    accountName: [],
    accountOwner: [],
    boxType: [],
    color: [],
    flowerSpecies: [],
    grade: [],
    guidance: [],
    market: [],
    orderType: [],
    variety: [],
    category: [],
    boxComposition: [],
  },
  filtersToSearch: {
    inventoryType: [],
  },
  sortBy: null,
  sortDirection: 'asc',
  availability: [],
};

export const actionPlanSlice = createSlice({
  name: 'actionPlan',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFilterGapsByVariety: (state, action) => {
      state.isFilterGapsVariety = action.payload;
    },
    setFilterHolidayGaps: (state, action) => {
      state.isFilterHGaps = action.payload;
    },
    setFiltersToSearch: (state, action) => {
      state.filtersToSearch = action.payload;
    },
    setSort: (state, action) => {
      state.sortBy = action.payload.column;
      state.sortDirection = action.payload.newDirection;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllocation.fulfilled, (state, { payload }) => {
        state.allocation = {
          ...state.allocation,
          allocations: payload.allocations,
          totalAllocations: payload.totalAllocations,
          lastAllocationDate: payload.lastAllocationDate,
        };
        state.totalPages = payload.totalPages;
      })
      .addCase(getFiltersAllocationData.fulfilled, (state, { payload }) => {
        state.filters = payload;
      })
      .addCase(getAvailability.fulfilled, (state, { payload }) => {
        state.availability = payload;
      });
  },
});

export default actionPlanSlice.reducer;
