export const ADMIN_SETTINGS = {
    TITLE: 'Admin Settings',
}

export const CONFIGURATIONS = {
    ALLOCATION_PRIORITIES: 'allocationPriority',
    BUSINESS_RULES: "businessRules",
    SIZE_RANGE: "sizeRanges",
    ALTERNATIVE_VARIETY: "alternativeVarietyMatch",
    IDENTIFY_GAPS: "identifyGaps",
}

export const CONFIGURATION_REFERENCE = {
    ALLOCATION: 'Allocation Priorities',
}