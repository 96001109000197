export const SUBSTITUTION = {
    TITLE: 'Substitutions Matrix',
    BACK_TO_MASTER: 'Back to Master List',
    SUITABLE_REPLACEMENTS: 'Suitable Replacements Matched: ',
    CATEGORY: 'Category',
    COLOR: 'Color',
    VARIETY: 'Variety',
    GRADE: 'Grade',
    PRIORITY_ORDER: 'In order of priority:',
    REPLACED_WITH: 'Can be replaced with',
    MATRIX_LEGEND: 'If a product is selected, these are the approved different length replacements based on Substitution Rule Settings.',
    APPLY_CHANGES: 'Apply Changes',
    INVALID_FILE_TYPE: 'Invalid file type. Please upload a .png file.',
    FILE_SIZE_EXCEEDS: 'File size exceeds 1.5 MB. Please upload a smaller image.',
    UPLOADING_IMAGE: 'Uploading image...',
    UPLOAD_FAILED: 'Upload failed. Contact support.',
    UPLOAD_SUCCESS: 'Image uploaded successfully.',
    SEARCH_NEW_VARIETY: 'Search New Variety',
    UPDATED_SUCCESSFULLY: 'Substitutions updated successfully.',
    ERROR_FETCHING: 'Error fetching substitutions. Please try again.',
    NO_DATA_FOUND: 'No data found.',
    NO_CHANGES_DETECTED: 'No changes detected.',
    CHANGES_REVERTED: 'Changes reverted.',
    WARNING_UNSELECTED_ROW: 'No grades has been selected for: ',
    GRADE_OUT_OF_RANGE: 'The selected grade exceeds the allowed range defined by the business rules.',
    CHANGES_NOT_SAVED: 'Changes have not been saved. Leaving this page will discard them.',
    CLOSE: 'Cancel',
    AGREE: 'Leave',
    FETCHING_DATA: 'Fetching data...',
};

export const MAX_SUBSTITUTIONS_VARIETIES = 4;

export const MAX_IMAGE_SIZE = 1.5;

export const GRADES = {
    ANE: [30, 35, 40, 45, 50, 55, 60, 70],
    DAV: [40, 50, 60, 70, 80, 90, 100, 110],
    EUC: [60],
    FIL: [60],
    GAR: [30, 40, 60, 70, 80, 90, 100, 110],
    GYP: [60, 80],
    LIL: [60],
    RAN: [30, 35, 40, 45, 50, 55, 60, 70, 80, 90, 100, 110],
    ROS: [40, 50, 60, 70, 80, 90, 100, 110],
    SPR: [40, 50, 60, 70, 80, 90, 100, 110],
    LIS: [40, 45, 50, 55, 60, 70, 80, 90, 100, 110]
};

export const COLORS = {
    "BSH": "#F2E0DE",
    "COR": "#F88379",
    "CRM": "#FEFBEA",
    "ETN": "#A48364",
    "GRN": "#A6BB59",
    "IVO": "#E4DBC7",
    "LAV": "#B779A0",
    "ORG": "#FB814F",
    "PCH": "#E7A78D",
    "PKH": "#E9336A",
    "PKL": "#F5C5D7",
    "PKM": "#EA7D97",
    "PNK": "#F18DB3",
    "PUR": "#A04F7E",
    "RED": "#BF0018",
    "SAN": "#E6D0BC",
    "WHT": "#FFFFFF",
    "YLW": "#F4B601",
    "YLW - BIC": "#F4B602, #BF0018",
    "ORG - BIC": "#FB814F, #BF0018",
    "PKB - BIC": "#F18DB3, #FEFBEA",
    "LAV - BIC": "#B779A0, #E9336A",
    "NOV": "#D9D9D9",
    "AST": "#D9D9D9",
    "BIC": "#D9D9D9",
    "BLU": "#1C069B"
};