import React from 'react';
import SalesOrderAllocationTable from '../../../components/SalesOrdersAllocationTable/SalesOrderAllocationTable';
import RevenueModal from '../../../components/RevenueModal/RevenueModal';
import 'react-datepicker/dist/react-datepicker.css';
import { SALES_ORDERS_ALLOCATION } from '../../../shared/constantes/SalesOrdersAllocation';
import { UseSalesOrderAllocationState } from './state/useSalesOrderAllocationState';
import SidebarSalesOrdersAllocation from '../../../components/SidebarSalesOrdersAllocation/SidebarSalesOrdersAllocation';
import Layout from '../../Layout/Layout';

const SalesOrderAllocation = () => {
  const { openModal, handleCloseModal, handleOpenModal } =
    UseSalesOrderAllocationState();

  return (
    <>
      <Layout
        title={SALES_ORDERS_ALLOCATION.TITLE}
        sidebar={SidebarSalesOrdersAllocation}
      >
        <SalesOrderAllocationTable handleOpenModal={handleOpenModal} />
      </Layout>
      <RevenueModal open={openModal} onClose={handleCloseModal} />
    </>
  );
};
export default SalesOrderAllocation;
