import { configureStore } from '@reduxjs/toolkit';
import commonState from './reducers/commonState.slice';
import availableToPromise from './reducers/availableToPromise.slice';
import agingByVariety from './reducers/agingByVariety.slice';
import actionPlan from './reducers/actionPlan.slice';
import allocationPriorities from './reducers/allocationPriorities.slice';
import substitutionMatrix from './reducers/substitutionMatrix.slice';

export const store = configureStore({
  reducer: {
    commonState,
    availableToPromise,
    agingByVariety,
    actionPlan,
    allocationPriorities,
    substitutionMatrix,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
