import { createNamedStyles } from '@/shared/interfaces/create_name_styles';
import { theme } from '@/theme';

export const filterChipStyles = createNamedStyles({
  button: {
    backgroundColor: theme.palette.primary.main,
    width: '30px',
    height: '30px',
    minWidth: '30px',
    padding: 0,
    fontSize: '26px',
    color: 'white',
  },
  chip: {
    display: 'flex',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    maxWidth: '77vw',
    margin: '0 auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});
