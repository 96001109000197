import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  CircularProgress,
  colors,
  Typography,
  Switch,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableHeader from '../Table/TableHeader';
import { availableToPromiseTableStyle as styles } from './AvailableToPromiseTable.styles';
import { Download, SearchBarIcon, ShowColumns } from '../../shared/icons/Icons';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { AVAILABLE_TO_PROMISE } from '../../shared/constantes/AvailableToPromise';
import SearchBar from '../SearchBar/SearchBar';
import { UseAvailableToPromiseState } from './state/useAvailableToPromiseState';
import { convertNumber } from '../../shared/utils/numbersUtils';
import HideColumns from '../HideColumns/HideColumns';
import PopoverComponent from '../PopoverComponent/PopoverComponent';

const cellStyle = (row?: number, type?: string) => ({
  fontSize: '12px',
  padding: '10px 10px',
  border: '1px solid #E0E0E0 ',
  whiteSpace: 'nowrap',
  color: row && row < 0 ? 'red' : '',
  background: row && row < 0 ? '#FDEDEC' : type === 'atp' ? '#F4F2EF' : '',
});

const cellTotalStyle = (row) => ({
  border: '1px solid #E0E0E0 ',
  background: row < 0 ? '#FDEDEC' : '#F4F2EF',
  fontWeight: 600,
  color: row < 0 ? 'red' : '',
});

const cellInventoryStyle = (row?: string) => ({
  border: '1px solid #E0E0E0 ',
  fontWeight: row === 'atp' ? 'bold' : 'normal',
  background: row === 'atp' || row === 'accumulatedAtp' ? '#F4F2EF' : '',
});
const AvailableToPromiseTable = () => {
  const {
    totals,
    showSearchBar,
    headerDataTable,
    loading,
    atp,
    headerIds,
    columns,
    inventoryColumns,
    isAccumulate,
    visibleInventoryTypes,
    setShowSearchBar,
    handleDownloadATPCSV,
    handleSort,
    sortByATP,
    handleSearchValue,
    handleSubmit,
    handleTotal,
    handleGetPropAccumulate,
    handleChangeAccumulate,
  } = UseAvailableToPromiseState();
  const handleTableCellDate = (inventory, inventoryType) =>
    headerIds.map((date, index) => (
      <TableCell
        key={index}
        sx={cellInventoryStyle(inventoryType)}
        style={{
          backgroundColor:
            inventory[date] && inventory[date][inventoryType] < 0
              ? '#FDEDEC'
              : '',
          color:
            inventory[date] && inventory[date][inventoryType] < 0 ? 'red' : '',
        }}
      >
        {convertNumber(inventory[date] ? inventory[date][inventoryType] : 0)}
      </TableCell>
    ));

  const handleTotalsOfDates = () =>
    totals.reduce((acc, current) => acc + current, 0);

  const [visibleColumns, setVisibleColumns] = useState(() => {
    const initialVisibility = columns.reduce((acc, column) => {
      acc[column.field] = true;
      return acc;
    }, {});
    initialVisibility;

    return initialVisibility;
  });

  const handleToggleColumn = (columnId) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  const countTrueValues = (): number => {
    const values = Object.values(visibleColumns);
    const trueValues = values.filter((value) => value === true);
    return trueValues.length;
  };

  return (
    <>
      <Box sx={styles.box}>
        <Box sx={styles.text}>
          <Box>{`${atp && atp.length} results`} </Box>
          <Box
            sx={{ display: 'flex', margin: '0px 100px', alignItems: 'stretch' }}
          >
            {AVAILABLE_TO_PROMISE.ACCUMULATE_INVENTORY}{' '}
            <Switch
              checked={isAccumulate}
              onChange={handleChangeAccumulate}
              size="small"
            />
          </Box>
        </Box>
        <Box sx={styles.boxIcons}>
          {showSearchBar ? (
            <Box sx={styles.search}>
              <SearchBar
                handleSearchValeu={handleSearchValue}
                handleSubmit={handleSubmit}
              />
            </Box>
          ) : (
            <TooltipIcon
              title={AVAILABLE_TO_PROMISE.BUTTON_SEARCH}
              handleClick={() => setShowSearchBar(true)}
              icon={<SearchBarIcon />}
            />
          )}
          <PopoverComponent
            children={
              <HideColumns
                columnsName={columns}
                handleToggleColumn={handleToggleColumn}
                visibleColumns={visibleColumns}
              />
            }
            title={AVAILABLE_TO_PROMISE.BUTTON_HIDE}
            icon={<ShowColumns />}
          />
          <TooltipIcon
            title={AVAILABLE_TO_PROMISE.DOWNLOAD}
            icon={<Download />}
            handleClick={handleDownloadATPCSV}
          />
        </Box>
      </Box>
      {!loading ? (
        <TableContainer component={Paper} style={{ maxHeight: '75vh' }}>
          <Table stickyHeader>
            <TableHeader
              headCell={columns}
              sortBy={sortByATP}
              handleSort={handleSort}
              visibleColumns={visibleColumns}
              aditionalColumns={
                !isAccumulate ? headerDataTable : headerDataTable.slice(0, -1)
              }
            ></TableHeader>
            <TableBody>
              <TableRow sx={{ position: 'sticky', top: '45px', zIndex: 999 }}>
                <TableCell colSpan={countTrueValues()} sx={styles.tableCell}>
                  {AVAILABLE_TO_PROMISE.TOTAL}
                </TableCell>
                {totals.map((total, index) => {
                  return (
                    <TableCell key={index} sx={cellTotalStyle(total)}>
                      {convertNumber(total)}
                    </TableCell>
                  );
                })}
                {!isAccumulate && (
                  <TableCell sx={cellTotalStyle(handleTotalsOfDates())}>
                    {convertNumber(handleTotalsOfDates())}
                  </TableCell>
                )}
              </TableRow>
              {atp &&
                atp.length > 0 &&
                atp.map((row, index) => {
                  if (Object.keys(row.inventory).length > 0) {
                    return (
                      <React.Fragment key={`${index}-tableATP`}>
                        {inventoryColumns.map((rowInventory, index) => {
                          return (
                            <TableRow
                              key={`${index}-inventory`}
                              sx={{ border: '1px solid red' }}
                            >
                              {index === 0 && (
                                <>
                                  {visibleColumns['category'] && (
                                    <TableCell
                                      rowSpan={inventoryColumns.length}
                                      sx={cellStyle()}
                                    >
                                      {row.category}
                                    </TableCell>
                                  )}
                                  {visibleColumns['color'] && (
                                    <TableCell
                                      rowSpan={inventoryColumns.length}
                                      sx={cellStyle()}
                                    >
                                      {row.color}
                                    </TableCell>
                                  )}
                                  {visibleColumns['variety'] && (
                                    <TableCell
                                      rowSpan={inventoryColumns.length}
                                      sx={cellStyle()}
                                    >
                                      {row.variety}
                                    </TableCell>
                                  )}
                                  {visibleColumns['grade'] && (
                                    <TableCell
                                      rowSpan={inventoryColumns.length}
                                      sx={cellStyle()}
                                    >
                                      {row.grade}
                                    </TableCell>
                                  )}
                                </>
                              )}
                              {visibleColumns['inventory'] &&
                                visibleInventoryTypes[
                                  rowInventory.inventorytype
                                ] && (
                                  <TableCell
                                    sx={cellInventoryStyle(rowInventory.id)}
                                  >
                                    {rowInventory.inventorytype}{' '}
                                  </TableCell>
                                )}
                              {Object.keys(row.inventory).length > 0 &&
                                visibleInventoryTypes[
                                  rowInventory.inventorytype
                                ] &&
                                handleTableCellDate(
                                  row.inventory,
                                  rowInventory.id
                                )}
                              {!isAccumulate &&
                                visibleInventoryTypes[
                                  rowInventory.inventorytype
                                ] && (
                                  <TableCell
                                    sx={cellStyle(
                                      handleTotal(
                                        row.inventory,
                                        rowInventory.id
                                      ),
                                      rowInventory.id
                                    )}
                                  >
                                    {convertNumber(
                                      handleTotal(
                                        row.inventory,
                                        rowInventory.id
                                      )
                                    )}
                                  </TableCell>
                                )}
                            </TableRow>
                          );
                        })}
                      </React.Fragment>
                    );
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
export default AvailableToPromiseTable;
