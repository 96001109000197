import 'react-datepicker/dist/react-datepicker.css';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/storeHook';
import { RootState } from '../../../store/interfaces/store.interfaces';
import { UseIAvailableToPromiseState } from './useSidebarAvailableToPromise.interfaces';
import {
  setEndDate,
  setFilterGlobalData,
  setFiltersInventoryTypes,
  setIsAccumulate,
  setStartDate,
} from '../../../store/actions/availableToPromise/availableToPromise.actions';
import {
  getATP,
  getFiltersATP,
} from '../../../store/thunks/AvailableToPromise/availableToPromise.thunks';
import { useEffect, useState } from 'react';
import { transformFiltersATp } from '../../../store/utils/storeUtils';
import {
  setFilterGapsByVariety,
  setFiltersToSearch,
} from '../../../store/actions/salesOrdersAllocation/salesOrdersAllocation.actions';
interface SelectedValues {
  inventoryType: string[];
  [key: string]: any;
}

export const UseSidebarAvailableToPromiseState =
  (): UseIAvailableToPromiseState => {
    const dispatch = useAppDispatch();
    const [selectedValues, setSelectedValues] = useState<SelectedValues>({
      inventoryType: [],
    });
    const [valuesToInventoryType, setValuesToInventoryType] = useState({
      inventoryType: [],
    });
    const {
      startDate,
      endDate,
      filtersAtp,
      filtersInventoryTypes,
      isAccumulate,
      isFilterGlobalData,
    } = useAppSelector((store: RootState) => store.availableToPromise);
    const { isFilterGapsVariety, filtersToSearch } = useAppSelector(
      (store: RootState) => store.salesOrders
    );
    const handleSetStartDate = (startDate) => dispatch(setStartDate(startDate));
    const handleSetEndDate = (endDate) => dispatch(setEndDate(endDate));
    const handleMaxDate = () => {
      const date = new Date();
      date.setDate(date.getDate() + 14);
      return date;
    };
    const handleATP = () => {
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          missingStems: isFilterGapsVariety,
          data: { ...filtersToSearch },
          runGlobalData: isFilterGlobalData,
        })
      );
      dispatch(getFiltersATP());
    };
    useEffect(() => {
      dispatch(getFiltersATP());
      dispatch(setFiltersInventoryTypes({ inventoryType: [] }));
    }, []);

    useEffect(() => {
      let data;
      data = { ...selectedValues };
      const { inventoryType, ...rest } = selectedValues;
      if (inventoryType === undefined) {
        setSelectedValues({ inventoryType: [] });
      }
      if (
        !(
          selectedValues.inventoryType &&
          selectedValues.inventoryType.length !==
            (filtersInventoryTypes.inventoryType &&
              filtersInventoryTypes.inventoryType.length)
        )
      ) {
        dispatch(
          getATP({
            startDate,
            endDate,
            dispatch,
            data: rest,
            missingStems: isFilterGapsVariety,
          })
        );
      }
      dispatch(setFiltersToSearch(rest));
      dispatch(setFiltersInventoryTypes({ inventoryType: inventoryType }));
    }, [selectedValues]);

    const handleFilterGapsVariety = () => {
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          missingStems: !isFilterGapsVariety,
          data: { ...filtersToSearch },
        })
      );
      dispatch(setFilterGapsByVariety(!isFilterGapsVariety));
      dispatch(setFilterGlobalData(false));
    };

    const handleRunGlobalData = () => {
      dispatch(
        getATP({
          startDate,
          endDate,
          dispatch,
          runGlobalData: !isFilterGlobalData,
        })
      );
      dispatch(setFilterGlobalData(!isFilterGlobalData));
      dispatch(setFilterGapsByVariety(false));
    };

    return {
      isFilterGapsVariety,
      isFilterGlobalData,
      filters: transformFiltersATp(filtersAtp, isAccumulate),
      endDate,
      startDate,
      valuesToInventoryType,
      handleSetStartDate,
      handleSetEndDate,
      handleMaxDate,
      handleATP,
      selectedValues,
      setSelectedValues,
      setValuesToInventoryType,
      handleFilterGapsVariety,
      handleRunGlobalData,
    };
  };
