import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
const authConfig: any = require('../../../authConfig');
const { loginRequest } = authConfig;
import { UseIHeaderState } from './useHeaderState.interfaces';
import Cookies from 'js-cookie'
import { useNavigate, useLocation } from 'react-router-dom';
import { apiLogout, acquireToken, validateJWT } from '@/auth/authAPI';

export const useHeaderState = (): UseIHeaderState => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(Cookies.get('session') ? true : false);

  const { instance } = useMsal();
  const activeAccount: any = instance.getActiveAccount();
  const navigate = useNavigate();
  const location = useLocation();

  const handleRedirect = () =>
    instance.loginRedirect({
      ...loginRequest,
      prompt: 'create',
    });

  const msalLogout = () => {
    if (instance) {
      instance.logoutRedirect({
        onRedirectNavigate: (url) => {
          return false;
        }
      });
    }
    navigate('/');
  }

  const handleLogout = async () => {
    //JWT token is delete from the backend
    await apiLogout(setErrorMessage, setUserLoggedIn);
    msalLogout();
  };

  const handleOpenMenu = (value: boolean) => setOpen(value);

  window.addEventListener('unload', (event) => {
    localStorage.clear();
  });

  const handleNavigate = (url: string) => {
    navigate(url);
  }

  //JWT token creation
  useEffect(() => {
    if (activeAccount && !Cookies.get('session') && !userLoggedIn) {
      const fetch = async () => {
        // Send the essential user information to the backend to create a JWT token
        await acquireToken(activeAccount.username, setErrorMessage, msalLogout, setUserLoggedIn);
      }
      fetch();
    }
  }, [activeAccount]);

  //JWT token validation and refresh
  useEffect(() => {
    const fetch = async () => {
      await validateJWT(setErrorMessage, msalLogout, navigate, setUserLoggedIn);
    };
    (Cookies.get('session')) ? fetch() : setUserLoggedIn(false);

  }, [location.pathname]);

  // Check if the user is logged in when the page is refreshed
  useEffect(() => {
    setUserLoggedIn(!!Cookies.get('session'));
  }, [Cookies, Cookies.get('session')]);

  return {
    open,
    userLoggedIn,
    errorMessage,
    activeAccount,
    setErrorMessage,
    handleRedirect,
    handleLogout,
    handleOpenMenu,
    handleNavigate,
  };
};
