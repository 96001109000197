import { API_ROUTES } from '@/shared/api_routes';
import axios from '@/shared/axios-utils';
import { ILogout, IAcquireToken, IValidateJWT } from './authAPI.interface';

export const apiLogout: ILogout = async (setErrorMessage, setUserLoggedIn) => {
    try {
        const res: any = await axios.get(API_ROUTES.API + '/auth/logout', { withCredentials: true });
        if (res.status != 200) {
            setErrorMessage('Error revoking token: Bad request');
            console.error('Error revoking token.');
        }
        setUserLoggedIn(false); 
        return res;

    } catch (error) {
        setErrorMessage('Error revoking token: No response from server');
        console.error('Error revoking token.');
    }

}

export const acquireToken: IAcquireToken = async (username, setErrorMessage, msalLogout, setUserLoggedIn) => {
    try {
        const res = await axios.post(API_ROUTES.API + '/auth/token', { username: username }, { withCredentials: true });
        if(res.status === 200) {
            setUserLoggedIn(true);
        }
    } catch (error) {
        msalLogout();
        setErrorMessage('Error acquiring token: No response from server');
        console.error('Error acquiring token silently:', error);
        throw error;
    }

}

export const validateJWT: IValidateJWT = async (setErrorMessage, msalLogout, navigate, setUserLoggedIn) => {
    try {
        const response = await axios.get(API_ROUTES.API + '/auth/token', { withCredentials: true });
        if (response.status === 200) {
            setUserLoggedIn(true);
        } else {
            setUserLoggedIn(false); 
            msalLogout();
            setErrorMessage('Error validating JWT token: Bad request');
            navigate('/');
        }
    } catch (error: any) {
        msalLogout();
        setUserLoggedIn(false); 
        if (error.response && error.response.status === 401) {
            setErrorMessage('Session expired. Please log in again.');
        } else {
            setErrorMessage('Error validating JWT token: No response from server');
            console.error('Error validating JWT:', error);
        }
        navigate('/');

    }
}