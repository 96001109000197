/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../../actions/commonState/commonState.actions';
import { API_ROUTES } from '../../../shared/api_routes';
import axios from '../../../shared/axios-utils';
import { AvailableToPromise } from '../../interfaces/atp.interfaces';
import { FiltersATP } from '../../interfaces/filtersATP.interfaces';

const getATP = createAsyncThunk<
  AvailableToPromise,
  {
    startDate: Date;
    endDate: Date;
    dispatch: any;
    data?: object;
    missingStems?: boolean;
    query?: string;
    runGlobalData?: boolean;
    currentPage: number;
    sortBy?: string | null;
    sortDirection?: string;
    groupResult?: string[];
  }
>(
  'availableToPromise/getATP',
  async ({
    startDate,
    endDate,
    dispatch,
    data,
    missingStems,
    query,
    runGlobalData,
    currentPage,
    sortBy,
    sortDirection,
    groupResult,
  }) => {
    try {
      dispatch(setLoading(true));
      let newData;
      const start_date = startDate.toLocaleDateString('es-ES');
      const end_date = endDate.toLocaleDateString('es-ES');
      let url = `${API_ROUTES.API}/revenue-management/atp?start_date=${start_date}&end_date=${end_date}&page=${currentPage}`;
      if (missingStems) {
        url += '&missing_stems=true';
      }
      if (data) {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        newData = Object.fromEntries(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          Object.entries(data).filter(([_, valor]) => {
            return !(Array.isArray(valor) && valor.length === 0);
          })
        );
        /* eslint-enable @typescript-eslint/no-unused-vars */
      }
      const AtpBodyRequest = {
        filterObject: {
          ...newData,
        },
        searchQuery: query,
        groupResultsBy: groupResult,
      };
      if (sortBy && sortDirection) {
        url += `&sort_by=${sortBy}&sort_direction=${sortDirection}`;
      }
      if (runGlobalData) {
        url = `${API_ROUTES.API}/revenue-management/atp?start_date=${start_date}&end_date=${end_date}&run_global_data=true`;
      }
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post<AvailableToPromise>(
        url,
        AtpBodyRequest
      );
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      dispatch(setLoading(false));
      console.error('Error fetching ATP data:', error);
      throw error;
    }
  }
);

const getFiltersATP = createAsyncThunk<
  FiltersATP,
  { startDate: Date; endDate: Date }
>('availableToPromise/getFiltersATP', async ({ startDate, endDate }) => {
  try {
    const start_date = startDate.toLocaleDateString('es-ES');
    const end_date = endDate.toLocaleDateString('es-ES');
    const response = await axios.get<FiltersATP>(
      `${API_ROUTES.API}/revenue-management/atp/filters?start_date=${start_date}&end_date=${end_date}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching filters', error);
    throw error;
  }
});

const downloadCsv = createAsyncThunk<
  any,
  {
    startDate: Date;
    endDate: Date;
    data?: object;
    query?: string;
    missingStems?: boolean;
    accumulate?: boolean;
    runGlobalData?: boolean;
    sortBy?: string | null;
    sortDirection?: string;
    groupResult?: string[];
  }
>(
  'availableToPromise/downloadCsv',
  async ({
    startDate,
    endDate,
    query,
    data,
    accumulate,
    missingStems,
    runGlobalData,
    sortBy,
    sortDirection,
    groupResult,
  }) => {
    try {
      let newData;
      const date = new Date().toLocaleDateString();
      const start_date = startDate.toLocaleDateString('es-ES');
      const end_date = endDate.toLocaleDateString('es-ES');
      const csv = '&csv=true';
      if (data) {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        newData = Object.fromEntries(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          Object.entries(data).filter(([_, valor]) => {
            return !(Array.isArray(valor) && valor.length === 0);
          })
        );
        /* eslint-enable @typescript-eslint/no-unused-vars */
      }
      const AtpBodyRequest = {
        filterObject: {
          ...newData,
        },
        searchQuery: query,
        modifiers: {
          accumulate,
        },
        groupResultsBy: groupResult,
      };
      let url = `${API_ROUTES.API}/revenue-management/atp?start_date=${start_date}&end_date=${end_date}${csv}`;
      if (missingStems) {
        url += '&missing_stems=true';
      }
      if (sortBy && sortDirection) {
        url += `&sort_by=${sortBy}&sort_direction=${sortDirection}`;
      }
      if (runGlobalData) {
        url += '&run_global_data=true';
      }
      const response = await axios.post<any>(url, AtpBodyRequest);
      const csvContent = response.data.csvContent;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url2 = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url2;
      a.download = `available_to_promise_${date}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error download sales orders allocation file:', error);
      throw error;
    }
  }
);

export { getATP, downloadCsv, getFiltersATP };
