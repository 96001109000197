import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../../actions/salesOrdersAllocation/salesOrdersAllocation.actions';
import { API_ROUTES } from '../../../shared/api_routes';
import axios from '../../../shared/axios-utils';
import { AvailableToPromise } from '../../interfaces/atp.interfaces';
import { FiltersATP } from '../../interfaces/filtersATP.interfaces';

const getATP = createAsyncThunk<
  AvailableToPromise[],
  {
    startDate: Date;
    endDate: Date;
    dispatch: any;
    data?: object;
    missingStems?: boolean;
    query?: string;
    runGlobalData?: boolean;
  }
>(
  'availableToPromise/getATP',
  async ({
    startDate,
    endDate,
    dispatch,
    data,
    missingStems,
    query,
    runGlobalData,
  }) => {
    try {
      dispatch(setLoading(true));
      let newData;
      const start_date = startDate.toLocaleDateString('es-ES');
      const end_date = endDate.toLocaleDateString('es-ES');
      let url = `${API_ROUTES.API}atp?start_date=${start_date}&end_date=${end_date}`;
      if (query) {
        url += `&search_query=${query}`;
      }
      if (missingStems) {
        url += '&missing_stems=true';
      }
      if (data) {
        newData = Object.fromEntries(
          // eslint-disable-next-line no-unused-vars
          Object.entries(data).filter(([_, valor]) => {
            return !(Array.isArray(valor) && valor.length === 0);
          })
        );
      }
      const AtpBodyRequest = {
        filterObject: {
          ...newData,
        },
      };
      if (runGlobalData) {
        url = `${API_ROUTES.API}atp?start_date=${start_date}&end_date=${end_date}&run_global_data=true`;
      }
      const response = await axios.post<AvailableToPromise[]>(
        url,
        data && AtpBodyRequest
      );
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      dispatch(setLoading(false));
      console.error('Error fetching ATP data:', error);
      throw error;
    }
  }
);

const getFiltersATP = createAsyncThunk<FiltersATP>(
  'availableToPromise/getFiltersATP',
  async () => {
    try {
      const response = await axios.get<FiltersATP>(
        `${API_ROUTES.API}atp/filters`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching filters', error);
      throw error;
    }
  }
);

const downloadCsv = createAsyncThunk<
  any,
  {
    startDate: Date;
    endDate: Date;
    data?: object;
    missingStems?: boolean;
    accumulate?: boolean;
    runGlobalData?: boolean;
  }
>(
  'availableToPromise/downloadCsv',
  async ({
    startDate,
    endDate,
    data,
    accumulate,
    missingStems,
    runGlobalData,
  }) => {
    try {
      let newData;
      const date = new Date().toLocaleDateString();
      const start_date = startDate.toLocaleDateString('es-ES');
      const end_date = endDate.toLocaleDateString('es-ES');
      const csv = '&csv=true';
      if (data) {
        newData = Object.fromEntries(
          // eslint-disable-next-line no-unused-vars
          Object.entries(data).filter(([_, valor]) => {
            return !(Array.isArray(valor) && valor.length === 0);
          })
        );
      }
      const AtpBodyRequest = {
        filterObject: {
          ...newData,
        },
        modifiers: {
          accumulate,
        },
      };
      let url = `${API_ROUTES.API}atp?start_date=${start_date}&end_date=${end_date}${csv}`;
      if (missingStems) {
        url += '&missing_stems=true';
      }
      if (runGlobalData) {
        url += '&run_global_data=true';
      }
      const response = await axios.post<any>(url, AtpBodyRequest);
      const url2 = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url2;
      a.download = `available_to_promise_${date}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error download sales orders allocation file:', error);
      throw error;
    }
  }
);

export { getATP, downloadCsv, getFiltersATP };
