import { CONFIGURATIONS } from "@/shared/constantes/AdmingSettings";
import { useAppDispatch, useAppSelector } from "@/store/hooks/storeHook";
import { RootState } from "@/store/store";
import { getConfiguration, saveSettingsConfiguration } from "@/store/thunks/AllocationPriorities/allocationPriorities.thunks";
import { useEffect, useState } from "react"
import categories from "@/shared/data/categories.json";
import { UseStateSizeSubstitutionInterface } from "./useStateSizeSubstitution.interface";

import range from "@/shared/data/range.json";
import rangeGeneral from "@/shared/data/range_general.json";
interface IUseStateSizeSubstitutionProps {
    handleContinuesClick: () => void;
    setOpenAlert: (open: boolean) => void
}

const UseStateSizeSubstitution = ({ handleContinuesClick, setOpenAlert }: IUseStateSizeSubstitutionProps): UseStateSizeSubstitutionInterface => {
    const dispatch = useAppDispatch();
    const substitutionsOptionsStore: {
        category: string;
        range: string;
    }[] = useAppSelector((store: RootState) => store.allocationPriorities.settingsById[CONFIGURATIONS.SIZE_RANGE]);

    const [substitutionsOptions, setSubstitutionsOptions] = useState<{ category: string; range: string; }[]>([]);

    const handleAddSubstitutions = () => {
        setSubstitutionsOptions([...substitutionsOptions, { category: '', range: '' }]);
    }

    const handleRemoveSubstitutions = (index: number) => {
        const newSizeRanges = [...substitutionsOptions];
        newSizeRanges.splice(index, 1);
        setSubstitutionsOptions(newSizeRanges);
    }

    const handleCategoryChange = (index: number, event: React.ChangeEvent<HTMLInputElement>, field: 'category' | 'range') => {
        const newSizeRanges = [...substitutionsOptions];
        newSizeRanges[index][field] = event.target.value;
        setSubstitutionsOptions(newSizeRanges);
    };

    const handleSubmit = async () => {
        dispatch(saveSettingsConfiguration({
            configurationId: CONFIGURATIONS.SIZE_RANGE,
            configuration: substitutionsOptions
        }));
        await dispatch(getConfiguration({ id: CONFIGURATIONS.BUSINESS_RULES }));
        setOpenAlert(true);
    }

    const getAvailableCategories = (index: number) => {
        const selectedCategories = substitutionsOptions.map(item => item.category);
        return categories.filter(category => !selectedCategories.includes(category.id) || substitutionsOptions[index].category === category.id);
    };

    const getAvailableSize = (item: { category: string, range: string }): { content: string; id: string }[] => {
        if (item.category === 'ANE' || item.category === 'RAN') {
            return range;
        }
        return rangeGeneral;
    }

    const saveAndContinues = () => {
        handleSubmit();
        handleContinuesClick();
    }

    useEffect(() => {
        dispatch(getConfiguration({ id: CONFIGURATIONS.SIZE_RANGE }));
    }, [dispatch])

    useEffect(() => {
        if (substitutionsOptionsStore) {
            const initialSizeRanges = substitutionsOptionsStore.map((item: {
                category: string;
                range: string;
            }) => ({
                category: item.category,
                range: item.range
            }));
            setSubstitutionsOptions(initialSizeRanges);
        } else {
            setSubstitutionsOptions([{ category: '', range: '' }]);
        }
    }, [substitutionsOptionsStore])

    return {
        substitutionsOptions,
        handleAddSubstitutions,
        handleRemoveSubstitutions,
        handleCategoryChange,
        handleSubmit,
        getAvailableCategories,
        saveAndContinues,
        getAvailableSize
    }
}

export default UseStateSizeSubstitution