import 'react-datepicker/dist/react-datepicker.css';
import { useAppDispatch, useAppSelector } from '@/store/hooks/storeHook';
import { RootState } from '@/store/interfaces/store.interfaces';
import { UseIAvailableToPromiseState } from './useSidebarAvailableToPromise.interfaces';
import {
  setEndDate,
  setFilterGlobalData,
  setFiltersInventoryTypes,
  setStartDate,
  setGroupResultValues,
  setGapsByVarietyATP,
  setInitialState,
  setFiltersToSearchATP,
  setCurrentPageATP
} from '@/store/actions/availableToPromise/availableToPromise.actions';
import {
  getATP,
  getFiltersATP,
} from '@/store/thunks/AvailableToPromise/availableToPromise.thunks';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { transformFiltersATp } from '@/store/utils/storeUtils';
import { setSort } from '@/store/actions/commonState/commonState.actions';

interface SelectedValues {
  inventoryType: string[];
  [key: string]: string[];
}

export const UseSidebarAvailableToPromiseState = (): UseIAvailableToPromiseState => {
  const dispatch = useAppDispatch();
  const {
    startDate,
    endDate,
    filtersAtp,
    filtersInventoryTypes,
    isAccumulate,
    isFilterGlobalData,
    groupResultValue,
    isGapsByVarietyATP,
    filtersToSearchATP,
    currentPageATP
  } = useAppSelector((store: RootState) => store.availableToPromise);

  const [selectedValues, setSelectedValues] = useState<SelectedValues>(() => ({
    ...filtersToSearchATP,
    inventoryType: filtersInventoryTypes?.inventoryType || []
  }));

  const [valuesToInventoryType] = useState({ inventoryType: [] });
  const isFirstRender = useRef(true);

  const handleSetStartDate = useCallback((startDate: Date) => {
    dispatch(setStartDate(startDate));
  }, [dispatch]);

  const handleSetEndDate = useCallback((endDate: Date) => {
    dispatch(setEndDate(endDate));
  }, [dispatch]);

  const handleMaxDate = useCallback(() => {
    const date = new Date();
    date.setDate(date.getDate() + 14);
    return date;
  }, []);

  const handleATP = useCallback(() => {
    dispatch(setCurrentPageATP(1));
    dispatch(getATP({
      startDate,
      endDate,
      dispatch,
      missingStems: isGapsByVarietyATP,
      data: filtersToSearchATP,
      runGlobalData: isFilterGlobalData,
      currentPage: 1,
      groupResult: groupResultValue
    }));
    dispatch(getFiltersATP({ startDate, endDate }));
    dispatch(setSort({ column: "total", newDirection: "asc"}));
  }, [dispatch, startDate, endDate, isGapsByVarietyATP, isFilterGlobalData, groupResultValue, filtersToSearchATP]);

  const handleFilterGapsVariety = useCallback(() => {
    if (currentPageATP !== 1) dispatch(setCurrentPageATP(1));
    dispatch(getATP({
      startDate,
      endDate,
      dispatch,
      missingStems: !isGapsByVarietyATP,
      data: filtersToSearchATP,
      currentPage: 1,
      groupResult: groupResultValue
    }));
    dispatch(setGapsByVarietyATP(!isGapsByVarietyATP));
    dispatch(setFilterGlobalData(false));
  }, [dispatch, currentPageATP, startDate, endDate, isGapsByVarietyATP, filtersToSearchATP, groupResultValue]);

  const handleRunGlobalData = useCallback(() => {
    if (currentPageATP !== 1) dispatch(setCurrentPageATP(1));
    const newGlobalDataState = !isFilterGlobalData;
    
    // Limpiar todos los filtros
    setSelectedValues({ inventoryType: [] });
    dispatch(setFiltersToSearchATP({}));
    dispatch(setFiltersInventoryTypes({ inventoryType: [] }));
    dispatch(setGroupResultValues([]));
    dispatch(setFilterGlobalData(newGlobalDataState));
    dispatch(setGapsByVarietyATP(false));
    
    dispatch(getATP({
      startDate,
      endDate,
      dispatch,
      runGlobalData: newGlobalDataState,
      currentPage: 1,
    }));
  }, [dispatch, currentPageATP, startDate, endDate, isFilterGlobalData]);

  const handleSetGroupResult = useCallback((event: React.SyntheticEvent<Element, Event>, item: string) => {
    if (currentPageATP !== 1) dispatch(setCurrentPageATP(1));
    const target = event.target as HTMLInputElement;
    const newGroupResultValue = target.checked ? [...groupResultValue, item] : groupResultValue.filter(value => value !== item);
    dispatch(setSort({ column: 'total', newDirection: 'asc' }));
    dispatch(setGroupResultValues(newGroupResultValue));
    dispatch(getATP({
      startDate,
      endDate,
      dispatch,
      currentPage: 1,
      data: filtersToSearchATP,
      groupResult: newGroupResultValue,
      missingStems: isGapsByVarietyATP,
    }));
    dispatch(setFilterGlobalData(false));
  }, [dispatch, currentPageATP, groupResultValue, startDate, endDate, filtersToSearchATP, isGapsByVarietyATP]);

  const handleReset = useCallback(() => {
    dispatch(setGroupResultValues([]));
    dispatch(getATP({
      startDate,
      endDate,
      dispatch,
      currentPage: 1,
      data: filtersToSearchATP,
      groupResult: [],
      runGlobalData: isFilterGlobalData
    }));
  }, [dispatch, startDate, endDate, filtersToSearchATP, isFilterGlobalData]);

  const handleDeleteChip = useCallback((item: string, key: keyof SelectedValues) => {
    setSelectedValues(prev => ({
      ...prev,
      [key]: (prev[key] || []).filter(value => value !== item)
    }));
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      const { inventoryType, ...rest } = selectedValues;
      dispatch(setFiltersToSearchATP(rest));
      dispatch(setFiltersInventoryTypes({ inventoryType: inventoryType || [] }));

      if (Object.keys(rest).length > 0) {
        dispatch(setFilterGlobalData(false));
        dispatch(getATP({
          startDate,
          endDate,
          dispatch,
          data: rest,
          missingStems: isGapsByVarietyATP,
          currentPage: 1,
          groupResult: groupResultValue
        }));
      }
    } else {
      isFirstRender.current = false;
      dispatch(getATP({
        startDate,
        endDate,
        dispatch,
        currentPage: 1,
        runGlobalData: isFilterGlobalData,
      }));
    }
  }, [selectedValues, dispatch, startDate, endDate, isGapsByVarietyATP, groupResultValue, isFilterGlobalData]);

  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, [dispatch]);

  const filters = useMemo(() => transformFiltersATp(filtersAtp, isAccumulate, groupResultValue), 
    [filtersAtp, isAccumulate, groupResultValue]
  );

  return {
    filters,
    startDate,
    endDate,
    isFilterGapsVariety: isGapsByVarietyATP,
    isFilterGlobalData,
    groupResultValue,
    selectedValues,
    setSelectedValues,
    handleSetStartDate,
    handleSetEndDate,
    handleMaxDate,
    handleFilterGapsVariety,
    handleRunGlobalData,
    handleATP,
    valuesToInventoryType,
    setValuesToInventoryType: () => {},
    handleSetGroupResult,
    handleReset,
    handleDeleteChip,
  };
};
