import { Box } from '@mui/material';
import React from 'react';
import { AdminSettingsStyles as styles } from './AdminSettings.styles';
import AdminSettingItem from './AdminSettingItem/AdminSettingItem';
import { useNavigate } from 'react-router-dom';
import adminSettingsData from '@/shared/data/settings.json';

const AdminSettingsDashboard = () => {
    const navigate = useNavigate();
    const redirect = (id: string) => {
        navigate(id);
    }

    return (
        <>
            <Box sx={styles.box}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={styles.text}>
                        {`${adminSettingsData.settings.length} results`}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>
                {
                    adminSettingsData.settings.map((item, index) => (
                        <Box key={`adming-settings${index}`} sx={styles.boxItem} data-testid="admin-setting-item">
                            <AdminSettingItem
                                itemId={item.id}
                                title={item.title}
                                subTitle={item.subTitle}
                                icon={item.icon}
                                path={item.url}
                                onClick={redirect} />
                        </Box>
                    ))
                }
            </Box>
        </>
    );

}
export default AdminSettingsDashboard