import React from 'react';
import { editOptionsStyle as styles } from './EditOptions.styles';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormGroup,
    Divider,
    List,
    Typography,
    MenuItem,
} from '@mui/material';
import { IEditOptions } from './EditOptions.interface';
import { AGING_BY_VARIETY_REDUCE_OPTIONS, AGING_BY_VARIETY_INCREASE_OPTIONS, AGING_BY_VARIETY_DAYS_AVAIALABLE, AGING_BY_VARIETY } from '@/shared/constantes/AgingByVariety';
import Select from '@mui/material/Select';

const EditOptions: React.FC<IEditOptions> = ({ type, setType, handleClickOption }) => {

    return (
        <Box sx={{ width: '235px' }}>
            <Box sx={styles.box}>Change Rotation Days</Box>
            <List>
                <FormGroup>
                    {AGING_BY_VARIETY_REDUCE_OPTIONS.map((item) => {
                        return (
                            <FormControlLabel
                                key={item.label}
                                value={item.value}
                                control={<Typography sx={styles.option} variant='body1' />}
                                name={item.label}
                                label={<Typography>{item.label}</Typography>}
                                onClick={() => { handleClickOption('Reduce', item.value) }}
                                sx={{ marginLeft: '0px' }}
                            />
                        );
                    })}
                </FormGroup>
            </List>
            <Divider sx={styles.divider} />
            <List>
                <FormGroup>
                    {AGING_BY_VARIETY_INCREASE_OPTIONS.map((item) => {
                        return (
                            <FormControlLabel
                                key={item.label}
                                value={item.label}
                                control={<Typography sx={styles.option} variant='body1' />}
                                name={item.label}
                                label={<Typography>{item.label}</Typography>}
                                onClick={() => { handleClickOption('Increase', item.value) }}
                                sx={{ marginLeft: '0px' }}
                            />
                        );
                    })}
                </FormGroup>
            </List>
            <Divider sx={styles.divider} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={styles.option}>{AGING_BY_VARIETY.CHANGE_ROTATION}</Typography>
                <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                    <Select
                        id="select-rotation-days"
                        value={undefined}
                        onChange={(event) => handleClickOption('', event.target.value as unknown as number)}
                    >
                        {AGING_BY_VARIETY_DAYS_AVAIALABLE.map((item) => {
                            return (
                                <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};
export default EditOptions;
